import React, {useState,useEffect} from 'react';
import './index.css';
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { FaBookReader } from "react-icons/fa";
import { MdFreeCancellation } from "react-icons/md";
import { FaUsers } from "react-icons/fa6";
import { TbCirclesRelation } from "react-icons/tb";
import { FaHotel } from "react-icons/fa";

const firebaseConfig = {
    apiKey: "AIzaSyB4BPMHKw0OXM2PhQX9NcT25lDBWpRpIPQ",
    authDomain: "phone-auth-d0a8d.firebaseapp.com",
    projectId: "phone-auth-d0a8d",
    storageBucket: "phone-auth-d0a8d.appspot.com",
    messagingSenderId: "309252587015",
    appId: "1:309252587015:web:3f9dc2505965e66269b4a2"
};

if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
}


const DashboardHome = () => {
    const [bookingsCount, setBookingsCount] = useState(0);
    const [cancellationCount, setCancellationCount] = useState(0);
    const [users, setUsers] = useState(0);
    const [vendors, setVendors] = useState(0);

    useEffect(() => {
        const fetchBookingsCount = async () => {
          const bookingsSnapshot = await firebase.firestore().collection('bookings').get()
          setBookingsCount(bookingsSnapshot.size);
        };
        fetchBookingsCount();
      }, []);

      useEffect(() => {
        const fetchCancellationCount = async () => {
          const cancellationSnapshot = await firebase.firestore().collection('cancellations').get()
          setCancellationCount(cancellationSnapshot.size);
        };
        fetchCancellationCount();
      }, []);

      useEffect(() => {
        const fetchUsersCount = async () => {
          const usersSnapshot = await firebase.firestore().collection('users').get()
          setUsers(usersSnapshot.size);
        };
        fetchUsersCount();
      }, []);

      useEffect(() => {
        const fetchVendorUsersCount = async () => {
          const vendorusersSnapshot = await firebase.firestore().collection('vendorUsers').get()
          setVendors(vendorusersSnapshot.size);
        };
        fetchVendorUsersCount();
      }, []);

  return (
    <div>
      <h1 style={{color:'brown',paddingLeft:'20px'}} className='mt-5'>Welcome to Admin Dashboard!</h1>
      <div className="card-container-dashboard mt-3">
        <div className="card-dashboard" style={{color:'black',background:'lightblue'}}><FaBookReader size={50}/> Bookings: {bookingsCount}</div>
        <div className="card-dashboard" style={{color:'black',background:'lightgray'}}><MdFreeCancellation size={50}/> Cancellations: {cancellationCount}</div>
        <div className="card-dashboard" style={{color:'black',background:'lightgreen'}}><FaUsers size={50}/> Users: {users}</div>
        <div className="card-dashboard" style={{color:'black',background:'lightpink'}}><TbCirclesRelation size={50}/> Vendors: {vendors}</div>
        <div className="card-dashboard" style={{color:'black',background:'#e7bc6c'}}><FaHotel size={35}/> All Hotels: {vendors}</div>
      </div>
    </div>
  );
};

export default DashboardHome;
