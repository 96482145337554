import React, { useState } from 'react';
import './index.css';
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { toast, Toaster } from "react-hot-toast";

const firebaseConfig = {
    apiKey: "AIzaSyB4BPMHKw0OXM2PhQX9NcT25lDBWpRpIPQ",
    authDomain: "phone-auth-d0a8d.firebaseapp.com",
    projectId: "phone-auth-d0a8d",
    storageBucket: "phone-auth-d0a8d.appspot.com",
    messagingSenderId: "309252587015",
    appId: "1:309252587015:web:3f9dc2505965e66269b4a2"
};

if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
}

const AddPackageForm = () => {
  const [bannerImage, setBannerImage] = useState(null);
  const [formData, setFormData] = useState({
    destinationName: '',
    title:'',
    description:'',
    country:'',
    state:'',
    destinationHead:'',
    aboutDestination: '',
    cost:'',
    bestTimeToTravel: '',
    nearestAirport: '',
    nearestRailwayStation: '',
    duration: '',
    shortItinerary: Array(10).fill(''),
    inclusions: Array(8).fill(''),
    exclusions: Array(8).fill(''),
    paymentTerms: Array(5).fill(''),
    cancellationTerms: Array(5).fill('')
  });

  const handleImageChange = (e) => {
    if (e.target.files[0]) {
      setBannerImage(e.target.files[0]);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleArrayChange = (e, index, arrayName) => {
    const newArray = [...formData[arrayName]];
    newArray[index] = e.target.value;
    setFormData({ ...formData, [arrayName]: newArray });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const nonEmptyItinerary = formData.shortItinerary.filter(item => item.trim() !== '');
    const nonEmptyInclusions = formData.inclusions.filter(item => item.trim() !== '');
    const nonEmptyExclusions = formData.exclusions.filter(item => item.trim() !== '');
    const nonEmptyPaymentTerms = formData.paymentTerms.filter(item => item.trim() !== '');
    const nonEmptyCancellationTerms = formData.cancellationTerms.filter(item => item.trim() !== '');

    if (bannerImage) {
      const storageRef = firebase.storage().ref();
      const bannerImageRef = storageRef.child(`packages/${bannerImage.name}`);
      await bannerImageRef.put(bannerImage);
      const bannerImageUrl = await bannerImageRef.getDownloadURL();

      const db = firebase.firestore();
      await db.collection('Packages').doc(formData.country).collection(formData.country).doc(formData.state).collection(formData.state).doc(formData.destinationName).set({
        ...formData,
        bannerImageUrl,
        shortItinerary: nonEmptyItinerary,
        inclusions:nonEmptyInclusions,
        exclusions:nonEmptyExclusions,
        paymentTerms: nonEmptyPaymentTerms,
        cancellationTerms: nonEmptyCancellationTerms
      });

      toast.success('Package added successfully!');
      setBannerImage(null);
      setFormData({
        destinationName: '',
        title:'',
        description:'',
        country:'',
        state:'',
        destinationHead:'',
        aboutDestination: '',
        cost:'',
        bestTimeToTravel: '',
        nearestAirport: '',
        nearestRailwayStation: '',
        duration: '',
        shortItinerary: Array(10).fill(''),
        inclusions: Array(8).fill(''),
        exclusions: Array(8).fill(''),
        paymentTerms: Array(5).fill(''),
        cancellationTerms: Array(5).fill('')
      });
    } else {
      toast.error('Please select a banner image');
    }
  };

  return (
    <>
     <Toaster toastOptions={{ duration: 5000 }} style={{ fontStyle: 'Canva Sans', color: 'black' }} />
    <h1 className='mt-3 font text-center' style={{color:'brown'}}>Add a Package</h1>
    <form className="container mt-3" onSubmit={handleSubmit}>
      <div className="form-group">
        <label>Banner Image:</label>
        <input type="file" className="form-control-file" onChange={handleImageChange} />
      </div>
      <div className="form-group">
        <label>Country:</label>
        <input type="text" name="country" className="form-control" value={formData.country} onChange={handleChange} />
        <p style={{color:'green'}}>*First letter should be Capital</p>
      </div>
      <div className="form-group">
        <label>State:</label>
        <input type="text" name="state" className="form-control" value={formData.state} onChange={handleChange} />
        <p style={{color:'green'}}>*First letter should be Capital</p>
      </div>
      <div className="form-group">
        <label>Destination Name:</label>
        <input type="text" name="destinationName" className="form-control" value={formData.destinationName} onChange={handleChange} />
        <p style={{color:'green'}}>*Not giving extra spaces between words, should be in this form [ex: mara-nakuru-ambosali]</p>
      </div>
      <div className="form-group">
        <label>Meta Title:</label>
        <input type="text" name="title" className="form-control" value={formData.title} onChange={handleChange} />
        <p style={{color:'green'}}>*The title is displayed on google</p>
      </div>
      <div className="form-group">
        <label>Meta Description:</label>
        <input type="text" name="description" className="form-control" value={formData.description} onChange={handleChange} />
        <p style={{color:'green'}}>*The description is displayed on google</p>
      </div>
      <div className="form-group">
        <label>Destination Heading:</label>
        <input type="text" name="destinationHead" className="form-control" value={formData.destinationHead} onChange={handleChange} />
      
      </div>
      <div className="form-group">
        <label>About Destination:</label>
        <textarea name="aboutDestination" className="form-control" value={formData.aboutDestination} onChange={handleChange}></textarea>
      </div>
      <div className="form-group">
        <label>Cost:</label>
        <input type="text" name="cost" className="form-control" value={formData.cost} onChange={handleChange} />
      </div>
      <div className="form-group">
        <label>Best Time to Travel:</label>
        <input type="text" name="bestTimeToTravel" className="form-control" value={formData.bestTimeToTravel} onChange={handleChange} />
      </div>
      <div className="form-group">
        <label>Nearest Airport:</label>
        <input type="text" name="nearestAirport" className="form-control" value={formData.nearestAirport} onChange={handleChange} />
      </div>
      <div className="form-group">
        <label>Nearest Railway Station:</label>
        <input type="text" name="nearestRailwayStation" className="form-control" value={formData.nearestRailwayStation} onChange={handleChange} />
      </div>
      <div className="form-group">
        <label>Duration:</label>
        <input type="text" name="duration" className="form-control" value={formData.duration} onChange={handleChange} />
      </div>
      <div className="form-group">
        <label>Short Itinerary:</label>
        {formData.shortItinerary.map((item, index) => (
          <textarea
            key={index}
            className="form-control mb-2"
            placeholder={`Day ${index + 1}`}
            rows="3"
            value={item}
            onChange={(e) => handleArrayChange(e, index, 'shortItinerary')}
          />
        ))}
      </div>
      <div className="form-group">
        <label>Inclusions:</label>
        {formData.inclusions.map((item, index) => (
          <input
            type='text'
            key={index}
            className="form-control mb-2"
            placeholder={`Inclusion ${index + 1}`}
            value={item}
            onChange={(e) => handleArrayChange(e, index, 'inclusions')}
          />
        ))}
      </div>
      <div className="form-group">
        <label>Exclusions:</label>
        {formData.exclusions.map((item, index) => (
          <input
            type='text'
            key={index}
            className="form-control mb-2"
            placeholder={`Exclusion ${index + 1}`}
            value={item}
            onChange={(e) => handleArrayChange(e, index, 'exclusions')}
          />
        ))}
      </div>
      <div className="form-group">
        <label>Payment Terms:</label>
        {formData.paymentTerms.map((item, index) => (
          <input
            type='text'
            key={index}
            className="form-control mb-2"
            placeholder={`Payment Term ${index + 1}`}
            value={item}
            onChange={(e) => handleArrayChange(e, index, 'paymentTerms')}
          />
        ))}
      </div>
      <div className="form-group">
        <label>Cancellation Terms:</label>
        {formData.cancellationTerms.map((item, index) => (
          <input
            type='text'
            key={index}
            className="form-control mb-2"
            placeholder={`Cancellation Term ${index + 1}`}
            value={item}
            onChange={(e) => handleArrayChange(e, index, 'cancellationTerms')}
          />
        ))}
      </div>
      <button type="submit" className="btn btn-primary mb-5 mt-3" style={{width:'100%'}}>Add Package</button>
    </form>
    </>
  );
};

export default AddPackageForm;
