import React, { useState, useRef } from 'react';
import './index.css';
import { Link } from 'react-router-dom';
import { collection, addDoc } from 'firebase/firestore';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import Select from 'react-select';
import { countries, getEmojiFlag } from 'countries-list';
import { toast, Toaster } from "react-hot-toast";
import { TbHandClick } from "react-icons/tb";
import {IoMdHome} from 'react-icons/io';


const firebaseConfig = {
  apiKey: "AIzaSyB4BPMHKw0OXM2PhQX9NcT25lDBWpRpIPQ",
  authDomain: "phone-auth-d0a8d.firebaseapp.com",
  projectId: "phone-auth-d0a8d",
  storageBucket: "phone-auth-d0a8d.appspot.com",
  messagingSenderId: "309252587015",
  appId: "1:309252587015:web:3f9dc2505965e66269b4a2"
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

const db = firebase.firestore();

const membershipData = [
  {
    fee: "10,000",
    freeBedNights: 4,
    shareOnRealEstate: 5,
    roi: "18%",
    launchOffer: "1200 Sqft of farm land"
  },
  {
    fee: "20,000",
    freeBedNights: 6,
    shareOnRealEstate: 10,
    roi: "18%",
    launchOffer: "1200 Sqft of farm land with 500 Sqft villa"
  },
  {
    fee: "60,000",
    freeBedNights: 8,
    shareOnRealEstate: 15,
    roi: "18%",
    launchOffer: "2400 Sqft of farm land with 750 Sqft villa"
  },
  {
    fee: "1,00,000",
    freeBedNights: 10,
    shareOnRealEstate: 20,
    roi: "18%",
    launchOffer: "5000 Sqft of farm land with 1000 Sqft villa"
  }
];

const plans = [
  { fee: "10,000", nights: 4, share: 5, roi: "18%", offer: "1200 Sqft of farm land" },
  { fee: "20,000", nights: 6, share: 10, roi: "18%", offer: "1200 Sqft of farm land with 500 Sqft villa" },
  { fee: "60,000", nights: 8, share: 15, roi: "18%", offer: "2400 Sqft of farm land with 750 Sqft villa" },
  { fee: "1,00,000", nights: 10, share: 20, roi: "18%", offer: "5000 Sqft of farm land with 1000 Sqft villa" }
];

const TUClub = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [formData, setFormData] = useState({
    title: '',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    country: '',
    message: '',
    termsAccepted: false,
  });
  const [error, setError] = useState('');
  const [selectedCountry, setSelectedCountry] = useState(null);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleSelectChange = (selectedOption) => {
    setFormData((prev) => ({ ...prev, country: selectedOption.label }));
    setSelectedCountry(selectedOption);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { title, firstName, lastName, email, phone, country, termsAccepted } = formData;

    if (
      !title ||
      !firstName.trim() ||
      !lastName.trim() ||
      !email.trim() ||
      !phone.trim() ||
      !country.trim() ||
      !termsAccepted
    ) {
      toast.error('Please fill all required feilds');
      return;
    }

    try {
      await addDoc(collection(db, 'tu-club-submissions'), formData);
      setError('');
      setFormData({
        title: '',
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        country: '',
        message: '',
        termsAccepted: false,
      });
      setSelectedCountry(null);
      toast.success("Our team would get in touch with you and explain how TU Club works")
    } catch (error) {
      setError('Error submitting form, please try again.');
      toast.error("Please fill all required feilds")
    }
  };

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const sec1 = useRef();
  const sec10 = useRef();
  const sec3 = useRef();
  const sec4 = useRef();
  const sec5 = useRef();
  const sec6 = useRef();
  const sec7 = useRef();
  const sec8 = useRef();
  const sec9 = useRef();
  const sec11 = useRef();

  const scrollHandler = (elemRef) => {
    window.scrollTo({ top: elemRef.current.offsetTop, behavior: 'smooth' });
  };

  const countryOptions = Object.keys(countries).map((code) => ({
    value: code,
    label: `${countries[code].name} (${code})`,
    emoji: getEmojiFlag(code),
  }));

  const customStyles = {
    control: (base) => ({
      ...base,
      minHeight: '30px',
      borderRadius: 0,
      border:'none',
      boxShadow: 'none',
      background:'#FFDE81',
      color:'#000000',
      fontWeight:'bold',
      fontSize:'12px'
    }),
    option: (provided) => ({
      ...provided,
      padding: 0,
    }),
  };

  const tableContainerStyle = {
    height: '40vh',
    width:'100%',
    display: 'block',
  };

  const cellStyle = {
    minWidth: '60px',
    textAlign: 'flex-start',
    border: '1px solid #black',
    background:'white',
    fontSize:'6px',
    fontFamily:'Lora, serif'
  };

  return (
    <>
     <Toaster toastOptions={{ duration: 5000 }} style={{fontStyle:'Canva Sans',color:'black'}} />
     <div className='d-none d-md-block'>
      <div className='tu-navbar'>
        <div className="logo">
          <Link to='/'><img src="/logo.png" alt="Logo" style={{ filter: 'brightness(100%)' }} /></Link>
        </div>
        <button className="hamburger d-none" onClick={toggleMenu}>
          <div />
          <div />
          <div />
        </button>
        <div className={`tu-list-style ${isOpen ? 'show' : ''}`}>
          <p className='nav-items' onClick={() => scrollHandler(sec1)}>Home</p>
          <p className='nav-items' onClick={() => scrollHandler(sec10)}>TU-Club</p>
          <p className='nav-items' onClick={() => scrollHandler(sec3)}>About TU-Club</p>
          <p className='nav-items' onClick={() => scrollHandler(sec6)}>Testimonials</p>
          <p className='nav-items' onClick={() => scrollHandler(sec7)}>Enquire</p>
          <p className='nav-items' onClick={() => scrollHandler(sec8)}>Plans</p>
          <p className='nav-items' onClick={() => scrollHandler(sec9)}>Contact</p>
        </div>
      </div>
      <div className='tu-app' ref={sec1}>
        <div>
          <img src='https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/tu-club%2FHome%20page%20image.jpg?alt=media&token=32b0660a-3c2c-4228-9256-64b7d82e056b' alt='' className='tu-home-img' />
        </div>
        <div className='tu-home-head'>
          <hr style={{ width: '80%' }} />
          <h5>Your Keys to own your holiday homes</h5>
          <h5 className='bold'>Travel Unbounded "Club"</h5>
        </div>
        <div>
          <img src='https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/tu-club%2Fkeys%20copy.jpg?alt=media&token=9499318e-739d-472e-ae9e-f0f616033dfc' alt='' className='tu-home-img-1' />
        </div>
      </div>
      <div className='tu-app' ref={sec10}>
        <div>
          <img src='https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/tu-club%2FTU%20CLUB%20Home%20image.jpg?alt=media&token=cec27631-a623-48c5-bbe6-2eaf7bf3a5cb' alt='' className='tu-home-img-2' />
        </div>
        <div className='tu-app-text'>
          <h1 className='align-head' style={{ fontWeight: '54px' }}>Own your<br /> Dream <br /> Holiday<br />  Homes</h1>
          <div className='tu-footer'>
            <div>
              <hr style={{ width: '100%' }} />
              <h5>Introducing the<br /> Travel Unbounded "Club"</h5>
            </div>
          </div>
        </div>
      </div>
      <div className='tu-app-club' ref={sec3}>
        <h1>The TU Club</h1>
        <p>You are not just a traveller but also an Co - Owner of our holiday homes around the World!!! </p>
        <p>For just a fraction of the total cost, this is your chance to become a Co - Owner of our fully maintained exotic resorts, cottages & boutique homestays </p>
        <p>Travel to our exotic destinations every year with your friends and families at a fractional cost*</p>
      </div>
      <div className='tu-app' ref={sec4}>
        <div className='flex' style={{ background: '#ABA08F' }}>
          <h1>TU Club</h1>
          <hr style={{ width: '80%' }} />
          <ul>
            <li>This exclusive TU Club is open for High Net Individuals only.</li>
            <li>We offer Co - Ownership to you at just a fractional cost of the total cost of our well maintained resorts, cottages & boutique homestays, located at exotic locations.</li>
            <li>With a one-time joining fee and a minimal annual fee, you can access our growing portfolio of offbeat real estates.</li>
            <li>With a fractional cost, you can enjoy yearly holidays at our exotic, select resorts / destinations with your friends and family</li>
          </ul>
        </div>
        <div className='flex-2' style={{ background: '#466A8B' }}>
          <img src='https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/tu-club%2FTU%20CLUB%202nd%20page%20image.jpg?alt=media&token=8ed9f570-5758-4d4b-9b9b-d4a069acc2dc' alt='' className='tu-card-img' />
          <div className='button-container-mobile'>
              <h1 className='' style={{color:'white'}}>Become a Co - Owner 'NOW'</h1>
              <TbHandClick className='click-button-right-desktop' onClick={() => scrollHandler(sec7)} size={30}/>
            </div>
        </div>
      </div>
      <div className='tu-app' ref={sec5}>
        <div className='flex' style={{ background: '#8B949D' }}>
          <h1>Just for you..</h1>
          <hr style={{ width: '80%' }} />
          <ul>
            <li>Travel with your loved ones to our exotic destinations every year with just a fractional cost.</li>
            <li>If you can’t travel, transfer your shares to your friends.</li>
            <li>Make big profits through your shares when we trade these properties!</li>
          </ul>
        </div>
        <div className='flex-2' style={{ background: '#EDE2D7' }}>
          <img src='https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/tu-club%2FTU%20CLUB%203rd%20page%20image.jpg?alt=media&token=ea3dfee3-6b07-4bc8-97a2-5a6be440bc00' alt='' className='tu-card-img' />
          <div className='button-container-mobile'>
          <h1 style={{ color: '#B58259' }}>Become a Co - Owner 'NOW'</h1>
          <TbHandClick className='click-button-right-desktop' onClick={() => scrollHandler(sec7)} size={30}/>
        </div>
        </div>
      </div>
      <div className='tu-app-feedback' style={{ background: '#FFFCF7' }} ref={sec6}>
        <h1 style={{ marginBottom: '30px', color: '#493725', marginTop: '20px' }}>Client Feedback</h1>
        <div className='tu-app-feedback-flex'>
          <div className='feedback-flex'>
            <img src='https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/tu-club%2Fpexels-edmond-dantes-4342352.jpg?alt=media&token=cbdbeba0-271f-48ec-b707-a7ec8d19c1dc' alt='' className='client' />
            <h4>Name</h4>
            <p>Feedback</p>
          </div>
          <div className='feedback-flex'>
            <img src='https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/tu-club%2Fpexels-gabby-k-6999225.jpg?alt=media&token=32e9ecce-06c4-4877-9fff-fff4e2005268' alt='' className='client' />
            <h4>Name</h4>
            <p>Feedback</p>
          </div>
          <div>
            <img src='https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/tu-club%2Fpexels-ketut-subiyanto-4307884.jpg?alt=media&token=fe48b54a-8e7e-49af-9d45-042fc1f4e03a' alt='' className='client' />
            <h4>Name</h4>
            <p>Feedback</p>
          </div>
        </div>
      </div>
      <div className='tu-app-form' ref={sec7}>
        <h1 style={{fontFamily:'Canva Sans'}}>Let us know about you!</h1>
        <form onSubmit={handleSubmit}>
          <div style={{ marginTop: '10px', marginBottom: '10px' }}>
            <select name="title" value={formData.title} onChange={handleChange} style={{ marginRight: '10px', width: '70px', height: '30px' }} required>
              <option value="" disabled>Title*</option>
              <option value="Mr">Mr</option>
              <option value="Mrs">Mrs</option>
            </select>
            <input type='text' name='firstName' value={formData.firstName} onChange={handleChange} className='tu-input-first-name' placeholder='First Name*' style={{ width: '170px' }} required />
            <input type='text' name='lastName' value={formData.lastName} onChange={handleChange} className='tu-input-last-name' placeholder='Last Name*' required />
          </div>
          <div style={{ marginTop: '10px', marginBottom: '10px' }}>
            <input type='email' name='email' value={formData.email} onChange={handleChange} className='tu-input' placeholder='Email*' style={{ width: '250px' }} required />
          </div>
          <div style={{ marginTop: '10px', marginBottom: '10px' }}>
            <input type='tel' name='phone' value={formData.phone} onChange={handleChange} className='tu-input' placeholder='Phone*' style={{ width: '250px' }} required />
          </div>
          <div style={{ marginTop: '10px', marginBottom: '10px', width:'250px' }} >
            <Select
              options={countryOptions}
              styles={customStyles}
              value={selectedCountry}
              onChange={handleSelectChange}
              placeholder="Select Country of residence*"
              isSearchable
              required
            />
          </div>
          <div style={{ marginTop: '10px', marginBottom: '10px' }}>
            <textarea name='message' value={formData.message} onChange={handleChange} className='tu-textarea' placeholder='Message' rows={3} style={{ width: '250px' }} />
          </div>
          <p className='form-condition'>Our team would get in touch with you and explain how TU Club works</p>
          <p>
            <input type='checkbox' name='termsAccepted' checked={formData.termsAccepted} onChange={handleChange} className='tu-checkbox' required style={{ marginRight: '5px',height:'10px' }} />
            I confirm,I have read and accept the <Link to="/terms" className='link'>Terms and Conditions</Link> and the <Link to="/privacy" className='link'>Website Privacy Policy</Link>.
          </p>
          {error && <p style={{ color: 'red' }}>{error}</p>}
          <button type='submit' className='btn btn-success' style={{ width: '250px' }}>Submit</button>
        </form>
      </div>
      <div className='tu-plans' style={{ background: '#EDE2D7' }} ref={sec8}>
        <h1 style={{ color: '#493724', marginBottom: '10px',fontFamily:'Leteraturnaya' }}>Features & Plans</h1>
        <table border="1">
          <thead>
            <tr>
              <th>TU Club one time joining Fee USD</th>
              <th>Free Bed Nights PA at our select resorts on twin/double sharing basis</th>
              <th>% share on real estates on trading</th>
              <th>% Assured ROI Post 36 Months</th>
              <th>Launch Offer</th>
            </tr>
          </thead>
          <tbody>
            {membershipData.map((plan, index) => (
              <tr key={index}>
                <td>{plan.fee}</td>
                <td>{plan.freeBedNights}</td>
                <td>{plan.shareOnRealEstate}</td>
                <td>{plan.roi}</td>
                <td>{plan.launchOffer}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className='tu-app-contact' ref={sec9}>
        <div className='flex-contact' style={{ background: '#8B949D' }}>
          <div>
            <h1 style={{fontFamily:'Literaturnaya'}}>Contact Information</h1>
          </div>
          <div className='contact-bottom-flex'>
            <hr style={{ width: '100%' }} />
            <p className='bold'>Address</p>
            <p>Travel Unbounded World Pvt Ltd, Dubai</p>
            <hr style={{ width: '100%' }} />
            <p className='bold'>Email address</p>
            <p>now@travelunbounded.com</p>
            <hr style={{ width: '100%' }} />
          </div>
        </div>
        <div style={{ background: '#EDE2D7' }}>
          <img src='https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/tu-club%2FLat%20page%20image.jpg?alt=media&token=101ac099-a78b-4117-bd81-71bcbc76d858' alt='' className='tu-contact-img d-none d-md-block' />
        </div>
      </div>
      </div>
      <div className='mobile-view d-block d-md-none'>
        <div className='container' style={{height:'100vh'}}>
          <div className='row'>
            <div className='col-12 nav-tu-club'>
            <div className="logo" style={{height:'50px'}}>
              <Link to='/'><img src="/logo.png" alt="Logo" style={{ filter: 'brightness(100%)' }} /></Link>
            </div>
            <Link to='/'><div className='mt-2' style={{paddingRight:'10px'}}><button className='icon-button'><IoMdHome size={15}/></button></div></Link> 
            </div>
            <div className='col-6'>
                <img src='https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/tu-club%2FTU%20CLUB%20Home%20image.jpg?alt=media&token=cec27631-a623-48c5-bbe6-2eaf7bf3a5cb' alt='' className='tu-home-img-2-mobile' />
            </div>
              <div className='col-6 flex-mobile-center'>
                <h5 style={{color:'#493725',fontSize:'18px'}}>Own your<br /> Dream <br /> Holiday<br />  Homes</h5>
                <hr style={{width:'60%',color:'#493725'}}/>
                <div className='flex-mobile-center'>
                <p style={{color:'#493725', width:'60%', fontSize:'8px'}}>Introducing the Travel Unbounded 'Club'.</p>
                </div>
              </div>
              <div className='col-12 flex-mobile-center' style={{color:'#493725', textAlign:'center'}}>
                <h6 style={{fontSize:'12px', fontFamily:'Lora, serif', fontWeight:'bold'}} className='mt-2'>The TU Club</h6>
                <p style={{fontSize:'8px',width:'70%', fontFamily:'Roboto, sans-serif'}}>You are not just a traveller but also an Co - Owner of our holiday homes around the World!!! </p>
                <p style={{fontSize:'8px',width:'70%', fontFamily:'Roboto, sans-serif'}}>For just a fraction of the total cost, this is your chance to become a Co - Owner of our fully maintained exotic resorts, cottages & boutique homestays </p>
                <p style={{fontSize:'8px',width:'70%', fontFamily:'Roboto, sans-serif'}} className='mb-3'>Travel to our exotic destinations every year with your friends and families at a fractional cost*</p>
              </div>
              <div className='col-6'>
                <h6 style={{paddingLeft:'10px',fontSize:'10px', fontFamily:'Lora, serif', fontWeight:'bold'}}>FOR WHO?</h6>
                <ul>
                <li style={{fontSize:'6px', fontFamily: 'Noto Sans, sans-serif',fontWeight:'600'}}>This exclusive TU Club is open for High Net Individuals only.</li>
                <li style={{fontSize:'6px', fontFamily: 'Noto Sans, sans-serif',fontWeight:'600'}}>We offer Co - Ownership to you at just a fractional cost of the total cost of our well maintained resorts, cottages & botique homestays, located at exotic locations.</li>
                <li style={{fontSize:'6px', fontFamily: 'Noto Sans, sans-serif',fontWeight:'600'}}>With a one time joining fee and a minimal annual fee, you can access our growing portfolio of off beat real estates.</li>
                <li style={{fontSize:'6px', fontFamily: 'Noto Sans, sans-serif',fontWeight:'600'}}>With a fractional cost, you can enjoy yearly holidays at our exotic, select resorts / destinations with your friends and family</li>
                </ul>
              </div>
              <div className='col-6'>
              <img src='https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/tu-club%2FTU%20CLUB%202nd%20page%20image.jpg?alt=media&token=8ed9f570-5758-4d4b-9b9b-d4a069acc2dc' alt='' className='tu-card-img-mobile' />
              </div>
              <div className='col-6 mt-2'>
              <img src='https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/tu-club%2FTU%20CLUB%203rd%20page%20image.jpg?alt=media&token=ea3dfee3-6b07-4bc8-97a2-5a6be440bc00' alt='' className='tu-card-img-mobile-2' />
              </div>
              <div className='col-6 mt-3'>
                <h6 style={{paddingLeft:'10px',fontFamily: 'Lora, serif',fontSize:'10px', fontWeight:'bold'}}>JUST FOR YOU...</h6>
                <ul>
                <li style={{fontSize:'6px', fontFamily: 'Noto Sans, sans-serif',fontWeight:'600'}}>Travel with your loved ones to our exotic destinations every year with just a fractional cost.</li>
                <li style={{fontSize:'6px', fontFamily: 'Noto Sans, sans-serif',fontWeight:'600'}}>If you can’t travel, transfer your shares to your friends.</li>
                <li style={{fontSize:'6px', fontFamily: 'Noto Sans, sans-serif',fontWeight:'600'}}>Make big profits through your shares when we trade these properties!</li>
                </ul>
              </div>
              <div className='button-container-mobile'>
              <h1 className='text-center mt-3 bold' style={{color:'#B58259',fontFamily:'Noto Sans, sans-serif',fontWeight:'bold', width:'100%'}} onClick={() => scrollHandler(sec11)}>Become a Co - Owner 'N<TbHandClick className='click' size={30} onClick={() => scrollHandler(sec11)} style={{color:'#000000'}}/>W'</h1>
            </div>
            </div>
          </div>
          <div className='container' style={{height:'100vh'}} ref={sec11}>
          <div className='row'>
          <h3 className='mt-4' style={{fontFamily:'Lora, serif'}}>Let us know about you!</h3>
            <div className='col-2' style={{marginLeft:'10px'}}>
            <select name="title" value={formData.title} onChange={handleChange} style={{color:'black', textAlign:'center', fontFamily:'Noto Sans,serif',fontSize:'10px'}} required>
              <option value="" disabled>Title*</option>
              <option value="Mr">Mr</option>
              <option value="Mrs">Mrs</option>
            </select>
            </div>
            <div className='col-4' style={{marginRight:'10px'}}>
            <input type='text' name='firstName' value={formData.firstName} onChange={handleChange}  placeholder='First Name*' style={{fontWeight:'bold',color:'#000000', textAlign:'center',fontFamily:'Noto Sans,serif',fontSize:'10px'}} required />
            </div>
            <div className='col-5'>
            <input type='text' name='lastName' value={formData.lastName} onChange={handleChange}  placeholder='Last Name*' style={{fontWeight:'bold',color:'#000000', textAlign:'center',fontFamily:'Noto Sans,serif',fontSize:'10px'}} required />
            </div>
            <div className='col-6' style={{marginLeft:'10px',textAlign:'flex-tart'}}>
            <input type='email' name='email' value={formData.email} onChange={handleChange}  placeholder='Email*' style={{fontWeight:'bold',color:'#000000', textAlign:'start',fontFamily:'Noto Sans,serif',fontSize:'10px',paddingLeft:'10px'}}  required />
            <input type='tel' name='phone' value={formData.phone} onChange={handleChange}  placeholder='Phone*' style={{fontWeight:'bold',color:'#000000',textAlign:'start',fontFamily:'Noto Sans,serif',fontSize:'10px',paddingLeft:'10px'}} required />
            <Select
              options={countryOptions}
              value={selectedCountry}
              onChange={handleSelectChange}
              placeholder="Country of residence*"
              isSearchable
              required
              styles={customStyles}
            />
           <textarea name='message' value={formData.message} onChange={handleChange} placeholder='Message' rows={4} style={{fontWeight:'bold',color:'#000000', textAlign:'start',fontFamily:'Noto Sans,serif',fontSize:'10px', paddingLeft:'10px'}}/>
          </div>
          <div className='col-5'>
          <img src='https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/tu-club%2FLat%20page%20image.jpg?alt=media&token=101ac099-a78b-4117-bd81-71bcbc76d858' alt='' className='tu-contact-img-mobile' />
          </div>
          <p style={{fontFamily:'Noto Sans, serif', fontSize:'10px'}}>
          <input type='checkbox' id='mobile-checkbox' name='termsAccepted' checked={formData.termsAccepted} onChange={handleChange} required />
            I confirm,I have read and accept the <Link to="/terms" className='link'>Terms and Conditions</Link> and the <Link to="/privacy" className='link'>Website Privacy Policy</Link>.
          </p>
          <div className='col-6'>
          <button type='submit' onClick={handleSubmit} className='btn btn-success' style={{ width:'100%',marginLeft:'10px', background:'#B58259', border:'none' }}>Submit</button>
          </div>
      <h3 className='mt-4 text-center' style={{color:'#493725',fontFamily:'Lora, serif',fontWeight:'bold'}}>Features and Plans</h3>
      <div style={tableContainerStyle}>
      <table style={{ borderCollapse: 'collapse', width: '100%' }}>
        <thead>
          <tr>
            <th style={cellStyle} className='cell'>One Time Joining Fee (USD)</th>
            <th style={cellStyle} className='cell'>Free Bed Nights PA</th>
            <th style={cellStyle} className='cell'>% Share on Real Estates on Trading</th>
            <th style={cellStyle} className='cell'>% Assured ROI Post 36 Months</th>
            <th style={cellStyle} className='cell'>Launch Offer</th>
          </tr>
        </thead>
        <tbody>
          {plans.map((plan, index) => (
            <tr key={index}>
              <td style={cellStyle} className='cell'>{plan.fee}</td>
              <td style={cellStyle} className='cell'>{plan.nights}</td>
              <td style={cellStyle} className='cell'>{plan.share}</td>
              <td style={cellStyle} className='cell'>{plan.roi}</td>
              <td style={cellStyle} className='cell'>{plan.offer}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    </div>
    </div>
    <div className='container' style={{height:'100vh'}}>
      <div className='row'>
      <h3 className='mt-4 text-center' style={{color:'#493725', fontFamily:'Lora, serif', fontWeight:'bold'}}>Client Feedbacks</h3>
      <div className='client-row text-center' style={{lineHeight:'50%'}}>
      <div className='col-4'>
            <img src='https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/tu-club%2Fpexels-edmond-dantes-4342352.jpg?alt=media&token=cbdbeba0-271f-48ec-b707-a7ec8d19c1dc' alt='' className='client-mobile' />
            <p className='mt-2'>Name</p>
            <p>Feedback</p>
          </div>
          <div className='col-4'>
            <img src='https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/tu-club%2Fpexels-gabby-k-6999225.jpg?alt=media&token=32e9ecce-06c4-4877-9fff-fff4e2005268' alt='' className='client-mobile' />
            <p className='mt-2'>Name</p>
            <p>Feedback</p>
          </div>
          <div className='col-4'>
            <img src='https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/tu-club%2Fpexels-ketut-subiyanto-4307884.jpg?alt=media&token=fe48b54a-8e7e-49af-9d45-042fc1f4e03a' alt='' className='client-mobile' />
            <p className='mt-2'>Name</p>
            <p>Feedback</p>
          </div>
      </div>
      <h3 className='mt-3 mb-3 text-center' style={{color:'#493725', fontFamily:'Lora, serif', fontWeight:'bold'}}>Contact Information</h3>
        <div style={{lineHeight:'50%', fontFamily:'Canva Sans'}}>
        <p style={{fontFamily:'Roboto, sans-serif'}}>Address</p>
        <p style={{fontFamily:'Roboto, sans-serif'}}>Travel Unbounded World Pvt Ltd, Dubai</p>
        <p className='mt-4' style={{fontFamily:'Roboto, sans-serif'}}>E-mail Address</p>
        <p style={{fontFamily:'Roboto, sans-serif'}}>now@travelunbounded.com</p>
        </div>
        <div className='client-column'>
          <hr style={{width:'70%', color:'#493725'}}/>
          <p style={{fontFamily:'Lora, serif'}}>Your Keys to own your holiday homes</p>
          <p style={{fontFamily:'Lora, serif',fontWeight:'bold'}}>Travel Unbounded "Club".</p>
        </div>
        <div className='client-pictures'>
        <div>
          <img src='https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/tu-club%2FHome%20page%20image.jpg?alt=media&token=32b0660a-3c2c-4228-9256-64b7d82e056b' alt='' className='client-img' />
        </div>
        <div>
          <img src='https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/tu-club%2Fkeys%20copy.jpg?alt=media&token=9499318e-739d-472e-ae9e-f0f616033dfc' alt='' className='client-img' />
        </div>
        </div>
    </div>
    </div>
    </div>
    </>
  );
}

export default TUClub;
