import React,{useState,useEffect} from "react";
import {BrowserRouter as Router,Routes, Route} from 'react-router-dom';
import MobileHome from "./components/MobileHome";
import 'bootstrap/dist/css/bootstrap.min.css';
import NotFound from "./components/NotFound";
import MobileHomeHead from "./components/MobileHomeHead";
import MobileHomeChange from "./components/MobileHomeChange";
import Contact from "./components/Contact";
import Nature from './components/Nature';
import Oneness from "./components/Oneness";
import Wilderness from "./components/Weekends";
import NaturePlace from "./components/NaturePlace";
import OnenessPlace from "./components/OnenessPlace";
import WildernessPlace from "./components/WeekendPlace";
import NatureHotel from "./components/NatureHotel";
import OnenessHotel from "./components/OnenessHotel";
import WildernessHotel from "./components/WeekendHotel";
import NatureBooking from './components/NatureBooking';
import OnenessBooking from './components/OnenessBooking';
import WeekendBooking from './components/WeekendBooking';
import Packages from "./components/Packages";
import EachPackage from './components/EachPackage';
import EnquireForm from './components/EnquireForm';
import ContactLeisure from "./components/ContactLeisure";
import ContactBusiness from "./components/ContactBusiness";
import MarketPlace from "./components/MarketPlace";
import Nav from "./components/Nav";
import NatureDataForm from "./components/NatureDataForm";
import Login from './components/Login';
import Bookings from './components/Bookings';
import NatureVendorLogin from './components/NatureVendorLogin';
import Listing from './components/Listing';
import Terms from './components/Terms';
import Privacy from './components/Privacy';
import Client from './components/ClientTerms';
import Partner from './components/PartnerTerms';
import NatureCalendar from './components/NatureCalendar';
import GuestBookings from './components/GuestBookings';
import AddPackages from './components/AddPackages';
import AddAvailability from './components/Availabilities';
import TUClub from "./components/TuClub";
//import Dashboard from './components/Dashborad'
import DashboardLogin from './components/DashboardLogin';
import './App.css';
import Club from './components/Club';
import ClubLogin from './components/ClubLogin';
import ClubRegister from './components/ClubRegister';
import ClubFooter from './components/ClubFooter';
import CustomList from './components/CustomList';
import ClubHome from './components/ClubHome';
import ClubPlans from './components/ClubPlans';
import ClubTestimonials from './components/ClubTestimonials';
import ClubNews from './components/ClubNews';
import ClubService from "./components/ClubService";
import ClubPortfolio from "./components/ClubPortfolio";
import ScrollToTop from "./components/ScrollToTop";
import ClubHomesPage from "./components/ClubHomesPage";
import PartnerHomesPage from "./components/PartnerHomesPage";
import EachClubHome from "./components/EachClubHome";
import EachAssociateHome from './components/EachAssociateHome';

function App() {
  const [componentIndex, setComponentIndex] = useState(0);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setComponentIndex(1);
    }, 6000);

    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setComponentIndex(2);
    }, 12000);

    return () => clearTimeout(timeout);
  }, []);

  const renderComponent = () => {
    switch (componentIndex) {
      case 0:
        return <MobileHome />;
      case 1:
        return <MobileHomeHead />;
      case 2:
        return <MobileHomeChange />;
      default:
        return null;
    }
  };
  return (
    <Router>
      <ScrollToTop/>
      <Routes>
        <Route exact path='/now' Component={renderComponent} />
        <Route exact path='/' Component={Club} />
        <Route exact path='/contact' Component={Contact} />
        <Route exact path='/nature' Component={Nature} />
        <Route exact path='/oneness' Component={Oneness} />
        <Route exact path='/weekends' Component={Wilderness} />
        <Route exact path='/packages' Component={Packages}/>
        <Route exact path="/nature/:state/:place" Component={NaturePlace}/>
        <Route exact path="/oneness/:state/:place" Component={OnenessPlace}/>
        <Route exact path="/weekends/:state/:city/:place" Component={WildernessPlace}/>
        <Route exact path="/nature/:state/:place/:hotelId" Component={NatureHotel}/>
        <Route exact path="/oneness/:state/:place/:hotelId" Component={OnenessHotel}/>
        <Route exact path="/weekends/:state/:city/:place/:hotelId" Component={WildernessHotel}/>
        <Route exact path="/nature/:state/:place/:hotelId/booking" Component={NatureBooking}/>
        <Route exact path="/oneness/:state/:place/:hotelId/booking" Component={OnenessBooking}/>
        <Route exact path="/weekends/:state/:city/:place/:hotelId/booking" Component={WeekendBooking}/>
        <Route exact path="/contact/leisure" Component={ContactLeisure}/>
        <Route exact path="/contact/business" Component={ContactBusiness}/>
        <Route exact path="/market-place" Component={MarketPlace}/>
        <Route exact path="/nav" Component={Nav}/>
        <Route exact path='/home-animation' Component={MobileHome} />
        <Route exact path='/home-head' Component={MobileHomeHead} />
        <Route exact path='/home' Component={MobileHomeChange} />
        <Route exact path='/login' Component={Login} />
        <Route exact path='/admin-dashboard' Component={DashboardLogin} />
        <Route exact path='/your-bookings' Component={Bookings} />
        <Route exact path='/nature-data-form' Component={NatureDataForm} />
        <Route exact path='/vendor-login' Component={NatureVendorLogin} />
        <Route exact path='/list-hotel' Component={Listing} />
        <Route exact path='/terms' Component={Terms} />
        <Route exact path='/privacy' Component={Privacy} />
        <Route exact path='/client-terms' Component={Client} />
        <Route exact path='/partner-terms' Component={Partner} />
        <Route exact path='/vendor-login/calendar' Component={NatureCalendar} />
        <Route exact path='/guest-bookings' Component={GuestBookings} />
        <Route exact path='/add-packages' Component={AddPackages} />
        <Route exact path='/tu-club' Component={TUClub} />
        <Route exact path='/club-owner-login' Component={ClubLogin} />
        <Route exact path='/club-register' Component={ClubRegister} />
        <Route exact path='/contact-club' Component={ClubFooter} />
        <Route exact path='/custom-list' Component={CustomList} />
        <Route exact path='/tu-club-home' Component={ClubHome} />
        <Route exact path='/club-plans' Component={ClubPlans} />
        <Route exact path='/club-news' Component={ClubNews} />
        <Route exact path='/club-service' Component={ClubService} />
        <Route exact path='/club-portfolio' Component={ClubPortfolio} />
        <Route exact path='/club-testimonials' Component={ClubTestimonials} />
        <Route exact path='/club-homes' Component={ClubHomesPage} />
        <Route exact path='/club-homes/:id' Component={EachClubHome} />
        <Route exact path='/associate-homes/:id' Component={EachAssociateHome} />
        <Route exact path='/associate-homes' Component={PartnerHomesPage} />
        <Route exact path='/add-availabilities' Component={AddAvailability} />
        <Route exact path="/packages/:state/:city/:place" Component={EachPackage}/>
        <Route exact path='/packages/:state/:city/:place/enquiry-form' Component={EnquireForm} />
        <Route path="*" Component={NotFound} />
      </Routes>
    </Router> 
  );
}

export default App;
