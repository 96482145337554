import React from 'react';
import {Link} from 'react-router-dom';
import ClubHeader from '../ClubHeader';
import ClubFooter from '../ClubFooter';
import { FaArrowRightLong } from "react-icons/fa6";
import './index.css';

const membershipData = [
  {
    fee: "10,000",
    freeBedNights: 4,
    shareOnRealEstate: 5,
    roi: "18%",
    launchOffer: "1200 Sqft of farm land"
  },
  {
    fee: "20,000",
    freeBedNights: 6,
    shareOnRealEstate: 10,
    roi: "18%",
    launchOffer: "1200 Sqft of farm land with 500 Sqft villa"
  },
  {
    fee: "60,000",
    freeBedNights: 8,
    shareOnRealEstate: 15,
    roi: "18%",
    launchOffer: "2400 Sqft of farm land with 750 Sqft villa"
  },
  {
    fee: "1,00,000",
    freeBedNights: 10,
    shareOnRealEstate: 20,
    roi: "18%",
    launchOffer: "5000 Sqft of farm land with 1000 Sqft villa"
  }
];

const ClubPlans = () => {
  return (
    <>
      <ClubHeader />
      <img
        src='https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/tu-club%2FTU%20CLUB%203rd%20page%20image.jpg?alt=media&token=ea3dfee3-6b07-4bc8-97a2-5a6be440bc00'
        alt=''
        className='club-plans-img'
      />
      <div className='club-plans'>
        <h1 className='mt-5 club-plans-title'>Features & Plans</h1>
        <div className='table-container'>
          <table className='club-plans-table'>
            <thead>
              <tr>
                <th>Joining Fee (USD)</th>
                <th>Free Bed Nights PA</th>
                <th>% Share on Real Estate</th>
                <th>% ROI Post 36 Months</th>
                <th>Launch Offer</th>
              </tr>
            </thead>
            <tbody>
              {membershipData.map((plan, index) => (
                <tr key={index}>
                  <td>{plan.fee}</td>
                  <td>{plan.freeBedNights}</td>
                  <td>{plan.shareOnRealEstate}</td>
                  <td>{plan.roi}</td>
                  <td>{plan.launchOffer}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className='club-testimonial-section'>
            <div className='club-testimonial-section-flex-1'>
                <h2 className='mt-3'>Register now and unlock a world of exceptional experiences!</h2>
                <p>Discover unparalleled luxury and personalized service at our exquisite properties. Enjoy
                access to handpicked locations and curated activities that cater to your every desire. Begin
                your journey today and indulge in unforgettable moments designed just for you.</p>
                <div className='explore-btn'>
                <Link to='/club-register'><button>Register to TU-Club <FaArrowRightLong style={{color:'brown'}}/></button></Link>
                </div>
            </div>
            <div className='club-testimonial-section-flex-2'>
            <img src='https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/tu-club%2FTU%20CLUB%202nd%20page%20image.jpg?alt=media&token=8ed9f570-5758-4d4b-9b9b-d4a069acc2dc' alt='' className='club-testimonial-section-img'/>
            </div>
            </div>
      <ClubFooter />
    </>
  );
};

export default ClubPlans;
