import React, { useState, useEffect } from 'react';
import './index.css';

const MobileHomeHead = () => {
    const [animationIndex, setAnimationIndex] = useState(0);
    const texts = ['Nature', 'Oneness', 'Weekends'];
    const totalTexts = texts.length;

    useEffect(() => {
        const interval = setInterval(() => {
            setAnimationIndex((prevIndex) => (prevIndex + 1) % totalTexts);
        }, 2000); 
        return () => clearInterval(interval);
    }, [totalTexts]);

    const getColorClass = (index, letterIndex) => {
        if (letterIndex === 0) {
            return `head-${index + 1}`;
        }
        return '';
    };

    return (
        <div className='now-container'>
            <div className=''>
                {texts.map((text, index) => (
                    <h3
                        key={index}
                        className={`now-head typing-text mb-5 ${
                            index === animationIndex ? 'animate' : index < animationIndex ? 'stay' : 'hidden'
                        }`}
                        style={{
                            animation: index === animationIndex ? 'slideFromLeft 1s ease-in-out forwards' : 'none'
                        }}
                    >
                        {text.split('').map((letter, letterIndex) => (
                            <span
                                key={letterIndex}
                                className={`${getColorClass(index, letterIndex)}`}
                            >
                                {letter}
                            </span>
                        ))}
                    </h3>
                ))}
                <p className='text-center mt-5' style={{color:'white',fontFamily:'Canva Sans'}}>Holidays Made With Love</p>
            </div>
        </div>
    );
};

export default MobileHomeHead;
