import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { IoMdHome } from "react-icons/io";
import axios from 'axios';
import { FiX } from 'react-icons/fi';
import StarRating from '../StarRating';
import { toast, Toaster } from "react-hot-toast";
import './index.css';

const firebaseConfig = {
    apiKey: "AIzaSyB4BPMHKw0OXM2PhQX9NcT25lDBWpRpIPQ",
    authDomain: "phone-auth-d0a8d.firebaseapp.com",
    projectId: "phone-auth-d0a8d",
    storageBucket: "phone-auth-d0a8d.appspot.com",
    messagingSenderId: "309252587015",
    appId: "1:309252587015:web:3f9dc2505965e66269b4a2"
};

if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
}

const Bookings = () => {
    const [user, setUser] = useState(null);
    const [showFeedbackPopup, setShowFeedbackPopup] = useState(false);
    const [feedback, setFeedback] = useState('');
    const [starRating, setStarRating] = useState(0);
    const [currentRating, setCurrentRating] = useState(0);
    const [ratingCount, setRatingCount] = useState(0);
    const [showPopup, setShowPopup] = useState(false);
    const [showPolicyPopup, setShowPolicyPopup] = useState(false);
    const [showOtpPopup, setShowOtpPopup] = useState(false);
    const [otp, setOtp] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [confirmationResult, setConfirmationResult] = useState(null);
    const [bookings, setBookings] = useState([]);
    const [hotelData, setHotelData] = useState({});
    const [selectedBookingId, setSelectedBookingId] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                setUser(user);
                fetchBookings(user.uid);
            } else {
                window.location.href = '/login';
            }
        });

        return () => unsubscribe();
    }, []);

    const fetchBookings = async (userId) => {
        try {
            const snapshot = await firebase.firestore().collection('bookings').where('userId', '==', userId).get();
            const bookingsData = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
            setBookings(bookingsData);
            setIsLoading(false);

            const hotelDataPromises = bookingsData.map(async (booking) => {
                const hotelRefPath = booking.categoryPath === 'WeekendGateways'
                    ? firebase.firestore()
                        .collection(booking.categoryPath)
                        .doc(booking.state)
                        .collection(booking.state)
                        .doc(booking.city)
                        .collection(booking.city)
                        .doc(booking.place)
                        .collection('hotels')
                        .doc(booking.hotelName)
                    : firebase.firestore()
                        .collection(booking.categoryPath)
                        .doc(booking.state)
                        .collection(booking.state)
                        .doc(booking.place)
                        .collection('hotels')
                        .doc(booking.hotelName);

                const hotelSnapshot = await hotelRefPath.get();
                if (hotelSnapshot.exists) {
                    return { bookingId: booking.id, data: hotelSnapshot.data() };
                } else {
                    console.log("No such document!");
                    return null;
                }
            });

            const hotelDataResults = await Promise.all(hotelDataPromises);
            const hotelDataMap = {};
            hotelDataResults.forEach((hotel) => {
                if (hotel) {
                    hotelDataMap[hotel.bookingId] = hotel.data;
                }
            });

            setHotelData(hotelDataMap);
        } catch (error) {
            console.error('Error fetching bookings:', error);
        }
    };

    const handleSubmitFeedback = async (e) => {
        e.preventDefault();

        if (selectedBookingId) {
            const booking = bookings.find((booking) => booking.id === selectedBookingId);
            if (booking) {
                const { categoryPath, state, city, place, hotelName } = booking;

                const newRating = ((currentRating * ratingCount) + starRating) / (ratingCount + 1);
                const newRatingCount = ratingCount + 1;

                setRatingCount(newRatingCount);
                setCurrentRating(newRating);

                setFeedback('');
                setStarRating(newRating);

                setShowFeedbackPopup(false);

                const hotelRefPath = categoryPath === 'WeekendGateways'
                    ? firebase.firestore()
                        .collection(categoryPath)
                        .doc(state)
                        .collection(state)
                        .doc(city)
                        .collection(city)
                        .doc(place)
                        .collection('hotels')
                        .doc(hotelName)
                    : firebase.firestore()
                        .collection(categoryPath)
                        .doc(state)
                        .collection(state)
                        .doc(place)
                        .collection('hotels')
                        .doc(hotelName);

                const hotelSnapshot = await hotelRefPath.get();
                const existingData = hotelSnapshot.data();

                const totalRating = (existingData.totalRating || 0) + starRating;
                const totalFeedback = (existingData.totalFeedback || []).concat({ rating: starRating, feedback });
                const averageRating = totalRating / newRatingCount;
                const TURating = Math.min(5, Math.max(0, averageRating)).toFixed(1);
                await hotelRefPath.update({
                    userName: user.displayName || user.email,
                    totalRating,
                    totalFeedback,
                    TURating: TURating,
                });

                console.log('Feedback submitted successfully');
                toast.success('Feedback submitted successfully');
            } else {
                console.error('Invalid booking id');
                toast.error('No booking selected for feedback');
            }
        } else {
            console.error('No booking selected for feedback');
            toast.error('No booking selected for feedback');
        }
    };

    const averageRating = hotelData[selectedBookingId]?.TURating || 0;

    const handleCancel = (bookingId) => {
        setSelectedBookingId(bookingId);
        setShowPopup(true);
    };

    const handleConfirmCancel = () => {
        setShowPopup(false);
        setShowPolicyPopup(true);
    };

    const handleProceedToOtp = () => {
        setShowPolicyPopup(false);
        setShowOtpPopup(true);
    };

    const handleCancelBooking = () => {
        setShowPopup(false);
        setShowPolicyPopup(false);
        setShowOtpPopup(false);
    };

    const sendOtp = async (e) => {
        e.preventDefault();
        try {
          const appVerifier = new firebase.auth.RecaptchaVerifier(
            "recaptcha-container"
          );
          const confirmation = await firebase
            .auth()
            .signInWithPhoneNumber(`+91${phoneNumber}`, 
            appVerifier);
          setConfirmationResult(confirmation);
          toast.success("OTP sent successfully");
        } catch (error) {
          console.error("Error sending OTP:", error);
        }
      };

      const verifyOtp = async (e) => {
        e.preventDefault();
        try {
          await confirmationResult.confirm(otp);
          handleCancelConfirmed();
          toast.success("OTP Verified Successfully");
          setOtp("");
        } catch (error) {
          console.error("Error verifying OTP:", error);
          toast.error("Please Enter Correct OTP");
        }
      };
    
   
    const handleCancelConfirmed = async () => {
        if (selectedBookingId) {
            try {
                await firebase.firestore().collection('bookings').doc(selectedBookingId).update({ cancelled: true });
                setBookings((prevBookings) =>
                    prevBookings.map((booking) =>
                        booking.id === selectedBookingId ? { ...booking, cancelled: true } : booking
                    )
                );
                setShowOtpPopup(false);
                toast.success('Booking Cancelled');
                sendEmailNotification();
            } catch (error) {
                console.error('Error updating booking:', error);
            }
        }
    };

    const sendEmailNotification = async () => {
        try {
            const booking = bookings.find((booking) => booking.id === selectedBookingId);
            if (booking) {
                const response = await axios.post('https://formbackend.netlify.app/cancellationEmail', {
                    email: booking.email,
                    name: booking.leadTravelerName,
                    category: booking.category,
                    hotelMail: booking.hotelMail,
                    state: booking.state,
                    hotelId: booking.hotelName,
                    contact: booking.contact,
                    bookingId: booking.bookingId,
                    place: booking.place,
                    checkin: booking.checkin,
                    checkout: booking.checkout,
                    numAdults:booking.totalAdults,
                    numKids:booking.totalKids,
                    numRooms: booking.numRooms,
                    totalPrice: booking.totalPrice,
                });

                if (response.status === 200) {
                    await firebase.firestore().collection('cancellations').add({
                        userId: user.uid,
                        email: booking.email,
                        name:booking.leadTravelerName,
                        state:booking.state,
                        hotelId:booking.hotelName,
                        hotelMail:booking.hotelMail,
                        category:booking.category,
                        contact:booking.contact,
                        bookingId: booking.bookingId,
                        place: booking.place,
                        checkin: booking.checkin,
                        checkout: booking.checkout,
                        numAdults:booking.totalAdults,
                        numKids:booking.totalKids,
                        numRooms: booking.numRooms,
                        totalPrice: booking.totalPrice,
                        cancelledAt: firebase.firestore.FieldValue.serverTimestamp(),
                    });
                    console.log('Cancellation Email sent successfully');
                } else {
                   console.log("Error storing cancellation data")
                }
            }
        } catch (error) {
            console.error('Error sending cancellation email:', error);
        }
    };

    if (isLoading) {
        return(
          <div className='loading-container'>
          <p className='loading'></p>
          </div>
        )
      }
    
    return (
        <div>
            <Toaster toastOptions={{ duration: 5000 }} style={{ fontStyle: 'Canva Sans', color: 'black' }} />
            {user ? (
                <div className='container'>
                    <nav className="nature-nav mb-3">
                        <div className='logo'>
                        <Link to='/'><img src="/logo.png" alt="Logo" style={{filter:'brightness(100%)'}} /></Link>
                        </div>
                        <Link to='/now'><div className='mb-2'><button className='icon-button'><IoMdHome size={15} /></button></div></Link>
                    </nav>
                    <h1 className='text-center booking-head' style={{ color: 'brown' }}>View Your Bookings</h1>
                    <p className='text-center font'>Welcome, {user.displayName || user.email}!</p>
                    <hr style={{ width: '100%', color: 'brown', border: '1px solid brown' }} />
                    {bookings.length === 0 ? (
                        <h3 className="font text-center d-flex flex-column justify-content-center" style={{ color: 'gray', height: '60vh' }}>No bookings yet</h3>
                    ) : (
                        bookings.map((booking) => {
                            const checkoutDate = new Date(booking.checkout);
                            const isCompleted = checkoutDate < new Date();
                            return (
                                <div className='container' key={booking.id}>
                                    <div className='row'>
                                        <div className='col-6 col-md-12 col-lg-12 col-xl-12'>
                                            <div className='details-container'>
                                                <p>Place</p>
                                                <p>Hotel</p>
                                                <p>Booking ID</p>
                                                <p>Check-in</p>
                                                <p>Check-out</p>
                                                <p>No.of Rooms</p>
                                                <p>Amount paid</p>
                                            </div>
                                        </div>
                                        <div className='col-6 col-md-12 col-lg-12 col-xl-12'>
                                            <div className='details-container'>
                                                <p>{booking.place}</p>
                                                <p>{booking.hotelName}</p>
                                                <p>{booking.bookingId}</p>
                                                <p>{booking.checkin}</p>
                                                <p>{booking.checkout}</p>
                                                <p>{booking.numRooms}</p>
                                                <p>₹{booking.totalPrice}</p>
                                            </div>
                                        </div>
                                        <div className='feedback-container mt-3 mb-3'>
                                        {booking.cancelled ? (
                                                <button className='feedback-btn-cancel' disabled>Give a feedback</button>
                                            ) : (
                                                <button
                                                    className='feedback-btn'
                                                    onClick={() => {
                                                        setSelectedBookingId(booking.id);
                                                        setShowFeedbackPopup(true);
                                                    }}
                                                >
                                                    Give a feedback
                                                </button>
                                            )}
                                            {booking.cancelled ? (
                                                <button className='cancel-btn-cancel' disabled>Cancelled</button>
                                            ) : (
                                                <button
                                                    className={isCompleted ? 'completed-btn' : 'cancel-btn'}
                                                    type='button'
                                                    onClick={() => !isCompleted && handleCancel(booking.id)}
                                                    disabled={isCompleted}
                                                >
                                                    {isCompleted ? 'Completed' : 'Cancel Booking'}
                                                </button>
                                            )}
                                        </div>
                                        <hr style={{ width: '100%', color: 'brown', border: '1px solid brown' }} />
                                    </div>
                                </div>
                            );
                        })
                    )}
                    {showPopup && (
                        <div className="popup text-center">
                            <p style={{ fontSize: '16px', fontWeight: 'bold' }} className='mt-3'>Are you sure you want to Cancel Booking?</p>
                            <div className='mt-3 mb-3'>
                                <button onClick={handleConfirmCancel} className='confirm-btn' style={{ background: 'green', color: 'white' }}>Yes</button>
                                <button onClick={handleCancelBooking} className='confirm-btn' style={{ background: 'red', color: 'white' }}>No</button>
                            </div>
                        </div>
                    )}
                    {showPolicyPopup && (
                        <div className="popup text-center">
                            <p style={{ fontSize: '16px', fontWeight: 'bold' }} className='mt-3'>Read Cancellation Policies</p>
                            {selectedBookingId && hotelData[selectedBookingId] && (
                                <>
                                    <p style={{ fontSize: '14px', marginTop: '10px' }}>{hotelData[selectedBookingId].cp1}</p>
                                    <p style={{ fontSize: '14px', marginTop: '10px' }}>{hotelData[selectedBookingId].cp2}</p>
                                    <p style={{ fontSize: '14px', marginTop: '10px' }}>{hotelData[selectedBookingId].cp3}</p>
                                </>
                            )}
                            <div className='mt-3 mb-3'>
                                <button onClick={handleProceedToOtp} className='confirm-btn' style={{ background: 'blue', color: 'white' }}>Proceed</button>
                                <button onClick={handleCancelBooking} className='confirm-btn' style={{ background: 'red', color: 'white' }}>Cancel</button>
                            </div>
                        </div>
                    )}
                    {showOtpPopup && (
                        <div className="popup text-center">
                            <p style={{ fontSize: '16px', fontWeight: 'bold' }} className='mt-3'>Verify Your Phone Number To Cancel Booking</p>
                            <input
                                type="text"
                                placeholder="Enter phone number"
                                onChange={(e) => setPhoneNumber(e.target.value)}
                                className='mb-2'
                            />
                            <button onClick={sendOtp} className='send-btn mb-2' style={{ background: 'green', color: 'white', border:'none', width:'100px' }}>Send OTP</button>
                            <input
                                type="text"
                                placeholder="Enter OTP"
                                value={otp}
                                onChange={(e) => setOtp(e.target.value)}
                            />
                            <div className='mt-3 mb-3'>
                                <button onClick={verifyOtp} className='confirm-btn' style={{ background: 'green', color: 'white' }}>Verify OTP</button>
                                <button onClick={handleCancelBooking} className='confirm-btn mt-2' style={{ background: 'red', color: 'white' }}>Cancel</button>
                            </div>
                            <div id="recaptcha-container"></div>
                        </div>
                    )}
                    {showFeedbackPopup && (
                        <div className="feedback-popup">
                            <div className="feedback-content">
                                <span className="close" onClick={() => setShowFeedbackPopup(false)}><FiX size={20} /></span>
                                <p className='font bold'>How was your experience?</p>
                                <div className="star-rating">
                                    <StarRating rating={starRating} onStarClick={setStarRating} />
                                </div>
                                <p className='font bold'>Write anything else..!</p>
                                <textarea
                                    className="feedback-textarea"
                                    placeholder="Write your feedback here..."
                                    value={feedback}
                                    onChange={(e) => setFeedback(e.target.value)}
                                />
                                <button className="submit-btn" onClick={handleSubmitFeedback}>Submit</button>
                                <p className='font text-center mt-2'>Average TU Rating: {averageRating}</p>
                            </div>
                        </div>
                    )}
                </div>
            ) : (
                <Link to='/login' className='d-flex flex-row justify-content-center'>Loading...</Link>
            )}
        </div>
    );
};

export default Bookings;
