import React, {useRef, useState, useEffect } from 'react';
import ClubHomesSlider from '../ClubHomesSlider';
import ClubHeader from '../ClubHeader2';
import ClubFooter from '../ClubFooter';
import { FaArrowRightLong } from "react-icons/fa6";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link, useParams } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './index.css';

const properties = [
  {
    id:1,
    headerTitle: 'Casa Paradiso',
    description: [
        'Situated on a cobbled cul-de-sac in the heart of Marylebone, The Mews sits amongst historic former carriage houses. Laid out over three floors, it offers two bedrooms, open plan living spaces and a kitchen with beautifully laid out dining space.',
        'This prime location offers a plethora of things to see and do right on your doorstep. Whether it\'s taking a stroll along Oxford Street to the iconic Selfridges department store, or heading to Regent\'s Park and the world-famous London Zoo. A short walk away, you also have access to The Marylebone Village, one of London\'s most vibrant shopping districts, renowned for its abundance of independent eateries and lifestyle boutiques.'
    ],
    galleryTitle: 'Gallery',
    galleryDescription: 'Upon entering from street level, you are welcomed by an open-plan living area packed with all the latest mod cons. Head downstairs to a fully equipped kitchen and dining area - perfect for your next dinner party. The top floor houses two beautifully furnished double bedrooms with en-suite bathrooms. Right on your doorstep is access to some of the city\'s best restaurants, shops and cultural attractions, making this Club Home one of London\'s most desirable addresses. Please note due to the stairs, this home is not wheelchair friendly.',
    imageGallery: [
        '/img-1.avif',
        '/img-2.avif',
        '/img-3.avif'
    ],
    features: [
        'Two bedrooms, sleeping 4 guests',
        'A paperback library, and a selection of toys and games for young children',
        'Flat screen TV with access to NOW TV, Apple TV and Chrome Cast',
        'Fully equipped kitchen',
        'Baby cot and highchair',
        'Hydrocotton towels and waffle bathrobes',
        'Egyptian cotton bed linen',
        'Tablet, printer and Wi-Fi access',
        'Fresh herb garden'
    ],
    floorPlans: [
        { image: '/floor-map.avif', title: 'First Floor' },
        { image: '/floor-map-2.avif', title: 'Ground Floor' },
        { image: '/floor-map-3.avif', title: 'Lower Ground Floor' }
    ],
    cardInfo: [
        'Classic Collection',
        'Owned property',
        '3 Bedrooms / Sleeps 6',
        'City Break'
    ],
    transferInfo: 'From Heathrow Airport - 40 minutes From Heathrow ( via Elizabeth High Speed Rail line ) - 20 mins',
    registerLink: '/club-register',
},
{
    id:2,
    headerTitle: 'Villa La Solana',
    description: [
        'Situated on a cobbled cul-de-sac in the heart of Marylebone, The Mews sits amongst historic former carriage houses. Laid out over three floors, it offers two bedrooms, open plan living spaces and a kitchen with beautifully laid out dining space.',
        'This prime location offers a plethora of things to see and do right on your doorstep. Whether it\'s taking a stroll along Oxford Street to the iconic Selfridges department store, or heading to Regent\'s Park and the world-famous London Zoo. A short walk away, you also have access to The Marylebone Village, one of London\'s most vibrant shopping districts, renowned for its abundance of independent eateries and lifestyle boutiques.'
    ],
    galleryTitle: 'Gallery',
    galleryDescription: 'Upon entering from street level, you are welcomed by an open-plan living area packed with all the latest mod cons. Head downstairs to a fully equipped kitchen and dining area - perfect for your next dinner party. The top floor houses two beautifully furnished double bedrooms with en-suite bathrooms. Right on your doorstep is access to some of the city\'s best restaurants, shops and cultural attractions, making this Club Home one of London\'s most desirable addresses. Please note due to the stairs, this home is not wheelchair friendly.',
    imageGallery: [
        '/img-1.avif',
        '/img-2.avif',
        '/img-3.avif'
    ],
    features: [
        'Two bedrooms, sleeping 4 guests',
        'A paperback library, and a selection of toys and games for young children',
        'Flat screen TV with access to NOW TV, Apple TV and Chrome Cast',
        'Fully equipped kitchen',
        'Baby cot and highchair',
        'Hydrocotton towels and waffle bathrobes',
        'Egyptian cotton bed linen',
        'Tablet, printer and Wi-Fi access',
        'Fresh herb garden'
    ],
    floorPlans: [
        { image: '/floor-map.avif', title: 'First Floor' },
        { image: '/floor-map-2.avif', title: 'Ground Floor' },
        { image: '/floor-map-3.avif', title: 'Lower Ground Floor' }
    ],
    cardInfo: [
        'City Collection',
        'Leased property',
        '2 Bedrooms / Sleeps 4',
        'City Break'
    ],
    transferInfo: 'From Heathrow Airport - 40 minutes From Heathrow ( via Elizabeth High Speed Rail line ) - 20 mins',
    registerLink: '/club-register',
},
{
    id:3,
    headerTitle: 'Gran Vista',
    description: [
        'Situated on a cobbled cul-de-sac in the heart of Marylebone, The Mews sits amongst historic former carriage houses. Laid out over three floors, it offers two bedrooms, open plan living spaces and a kitchen with beautifully laid out dining space.',
        'This prime location offers a plethora of things to see and do right on your doorstep. Whether it\'s taking a stroll along Oxford Street to the iconic Selfridges department store, or heading to Regent\'s Park and the world-famous London Zoo. A short walk away, you also have access to The Marylebone Village, one of London\'s most vibrant shopping districts, renowned for its abundance of independent eateries and lifestyle boutiques.'
    ],
    galleryTitle: 'Gallery',
    galleryDescription: 'Upon entering from street level, you are welcomed by an open-plan living area packed with all the latest mod cons. Head downstairs to a fully equipped kitchen and dining area - perfect for your next dinner party. The top floor houses two beautifully furnished double bedrooms with en-suite bathrooms. Right on your doorstep is access to some of the city\'s best restaurants, shops and cultural attractions, making this Club Home one of London\'s most desirable addresses. Please note due to the stairs, this home is not wheelchair friendly.',
    imageGallery: [
        '/img-1.avif',
        '/img-2.avif',
        '/img-3.avif'
    ],
    features: [
        'Two bedrooms, sleeping 4 guests',
        'A paperback library, and a selection of toys and games for young children',
        'Flat screen TV with access to NOW TV, Apple TV and Chrome Cast',
        'Fully equipped kitchen',
        'Baby cot and highchair',
        'Hydrocotton towels and waffle bathrobes',
        'Egyptian cotton bed linen',
        'Tablet, printer and Wi-Fi access',
        'Fresh herb garden'
    ],
    floorPlans: [
        { image: '/floor-map.avif', title: 'First Floor' },
        { image: '/floor-map-2.avif', title: 'Ground Floor' },
        { image: '/floor-map-3.avif', title: 'Lower Ground Floor' }
    ],
    cardInfo: [
        'Classic Collection',
        'Owned property',
        '3 Bedrooms / Sleeps 6',
        'City Break'
    ],
    transferInfo: 'From Heathrow Airport - 40 minutes From Heathrow ( via Elizabeth High Speed Rail line ) - 20 mins',
    registerLink: '/club-register',
},
{
    id:4,
    headerTitle: 'La Retraite',
    description: [
        'Situated on a cobbled cul-de-sac in the heart of Marylebone, The Mews sits amongst historic former carriage houses. Laid out over three floors, it offers two bedrooms, open plan living spaces and a kitchen with beautifully laid out dining space.',
        'This prime location offers a plethora of things to see and do right on your doorstep. Whether it\'s taking a stroll along Oxford Street to the iconic Selfridges department store, or heading to Regent\'s Park and the world-famous London Zoo. A short walk away, you also have access to The Marylebone Village, one of London\'s most vibrant shopping districts, renowned for its abundance of independent eateries and lifestyle boutiques.'
    ],
    galleryTitle: 'Gallery',
    galleryDescription: 'Upon entering from street level, you are welcomed by an open-plan living area packed with all the latest mod cons. Head downstairs to a fully equipped kitchen and dining area - perfect for your next dinner party. The top floor houses two beautifully furnished double bedrooms with en-suite bathrooms. Right on your doorstep is access to some of the city\'s best restaurants, shops and cultural attractions, making this Club Home one of London\'s most desirable addresses. Please note due to the stairs, this home is not wheelchair friendly.',
    imageGallery: [
        '/img-1.avif',
        '/img-2.avif',
        '/img-3.avif'
    ],
    features: [
        'Two bedrooms, sleeping 4 guests',
        'A paperback library, and a selection of toys and games for young children',
        'Flat screen TV with access to NOW TV, Apple TV and Chrome Cast',
        'Fully equipped kitchen',
        'Baby cot and highchair',
        'Hydrocotton towels and waffle bathrobes',
        'Egyptian cotton bed linen',
        'Tablet, printer and Wi-Fi access',
        'Fresh herb garden'
    ],
    floorPlans: [
        { image: '/floor-map.avif', title: 'First Floor' },
        { image: '/floor-map-2.avif', title: 'Ground Floor' },
        { image: '/floor-map-3.avif', title: 'Lower Ground Floor' }
    ],
    cardInfo: [
        'City Collection',
        'Owned property',
        '2 Bedrooms / Sleeps 4',
        'City Break'
    ],
    transferInfo: 'From Heathrow Airport - 40 minutes From Heathrow ( via Elizabeth High Speed Rail line ) - 20 mins',
    registerLink: '/club-register',
},
{
    id:5,
    headerTitle: 'Kentroma House',
    description: [
        'Situated on a cobbled cul-de-sac in the heart of Marylebone, The Mews sits amongst historic former carriage houses. Laid out over three floors, it offers two bedrooms, open plan living spaces and a kitchen with beautifully laid out dining space.',
        'This prime location offers a plethora of things to see and do right on your doorstep. Whether it\'s taking a stroll along Oxford Street to the iconic Selfridges department store, or heading to Regent\'s Park and the world-famous London Zoo. A short walk away, you also have access to The Marylebone Village, one of London\'s most vibrant shopping districts, renowned for its abundance of independent eateries and lifestyle boutiques.'
    ],
    galleryTitle: 'Gallery',
    galleryDescription: 'Upon entering from street level, you are welcomed by an open-plan living area packed with all the latest mod cons. Head downstairs to a fully equipped kitchen and dining area - perfect for your next dinner party. The top floor houses two beautifully furnished double bedrooms with en-suite bathrooms. Right on your doorstep is access to some of the city\'s best restaurants, shops and cultural attractions, making this Club Home one of London\'s most desirable addresses. Please note due to the stairs, this home is not wheelchair friendly.',
    imageGallery: [
        '/img-1.avif',
        '/img-2.avif',
        '/img-3.avif'
    ],
    features: [
        'Two bedrooms, sleeping 4 guests',
        'A paperback library, and a selection of toys and games for young children',
        'Flat screen TV with access to NOW TV, Apple TV and Chrome Cast',
        'Fully equipped kitchen',
        'Baby cot and highchair',
        'Hydrocotton towels and waffle bathrobes',
        'Egyptian cotton bed linen',
        'Tablet, printer and Wi-Fi access',
        'Fresh herb garden'
    ],
    floorPlans: [
        { image: '/floor-map.avif', title: 'First Floor' },
        { image: '/floor-map-2.avif', title: 'Ground Floor' },
        { image: '/floor-map-3.avif', title: 'Lower Ground Floor' }
    ],
    cardInfo: [
        'Classic Collection',
        'Owned property',
        '3 Bedrooms / Sleeps 6',
        'City Break'
    ],
    transferInfo: 'From Heathrow Airport - 40 minutes From Heathrow ( via Elizabeth High Speed Rail line ) - 20 mins',
    registerLink: '/club-register',
},
{
    id:6,
    headerTitle: 'Masseria L\'Olivo',
    description: [
        'Situated on a cobbled cul-de-sac in the heart of Marylebone, The Mews sits amongst historic former carriage houses. Laid out over three floors, it offers two bedrooms, open plan living spaces and a kitchen with beautifully laid out dining space.',
        'This prime location offers a plethora of things to see and do right on your doorstep. Whether it\'s taking a stroll along Oxford Street to the iconic Selfridges department store, or heading to Regent\'s Park and the world-famous London Zoo. A short walk away, you also have access to The Marylebone Village, one of London\'s most vibrant shopping districts, renowned for its abundance of independent eateries and lifestyle boutiques.'
    ],
    galleryTitle: 'Gallery',
    galleryDescription: 'Upon entering from street level, you are welcomed by an open-plan living area packed with all the latest mod cons. Head downstairs to a fully equipped kitchen and dining area - perfect for your next dinner party. The top floor houses two beautifully furnished double bedrooms with en-suite bathrooms. Right on your doorstep is access to some of the city\'s best restaurants, shops and cultural attractions, making this Club Home one of London\'s most desirable addresses. Please note due to the stairs, this home is not wheelchair friendly.',
    imageGallery: [
        '/img-1.avif',
        '/img-2.avif',
        '/img-3.avif'
    ],
    features: [
        'Two bedrooms, sleeping 4 guests',
        'A paperback library, and a selection of toys and games for young children',
        'Flat screen TV with access to NOW TV, Apple TV and Chrome Cast',
        'Fully equipped kitchen',
        'Baby cot and highchair',
        'Hydrocotton towels and waffle bathrobes',
        'Egyptian cotton bed linen',
        'Tablet, printer and Wi-Fi access',
        'Fresh herb garden'
    ],
    floorPlans: [
        { image: '/floor-map.avif', title: 'First Floor' },
        { image: '/floor-map-2.avif', title: 'Ground Floor' },
        { image: '/floor-map-3.avif', title: 'Lower Ground Floor' }
    ],
    cardInfo: [
        'City Collection',
        'Leased property',
        '2 Bedrooms / Sleeps 4',
        'City Break'
    ],
    transferInfo: 'From Heathrow Airport - 40 minutes From Heathrow ( via Elizabeth High Speed Rail line ) - 20 mins',
    registerLink: '/club-register',
},
];

const EachClubHome = () => {
    const { id } = useParams();
    const EachHomeSwiperRef = useRef(null);
    const [isBeginning, setIsBeginning] = useState(true);
    const [isEnd, setIsEnd] = useState(false);

    const clubHomeData = properties.find((property) => property.id === parseInt(id));

    const handlePrev = (swiperRef) => {
        if (swiperRef.current) {
            swiperRef.current.swiper.slidePrev();
        }
    };

    const handleNext = (swiperRef) => {
        if (swiperRef.current) {
            swiperRef.current.swiper.slideNext();
        }
    };

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
    };      

    useEffect(() => {
        if (EachHomeSwiperRef.current) {
            const swiper = EachHomeSwiperRef.current.swiper;

            swiper.on('slideChange', () => {
                setIsBeginning(swiper.isBeginning);
                setIsEnd(swiper.isEnd);
            });
        }
    }, []);

    return (
        <div>
            <ClubHeader/>
            <ClubHomesSlider images={clubHomeData.imageGallery}/>
            <div className='club-each-card-section'> 
                <h1>{clubHomeData.headerTitle}</h1>
                {clubHomeData.description.map((para, index) => (
                    <p key={index}>{para}</p>
                ))}
                <div className="explore-btn">
                    <Link to={clubHomeData.registerLink}>
                        <button>Meet the Property Manager <FaArrowRightLong style={{ color: 'brown' }} /></button>
                    </Link>
                </div>
            </div>
            <div className='portfolio-slider-container'>
                <div className='portfolio-text-container card-text mt-5'>
                    <h1>{clubHomeData.galleryTitle}</h1>
                    <div className='gallery-row'>
                        <div className='each-card-verticle-line'></div>
                        <p>{clubHomeData.galleryDescription}</p>
                    </div>
                </div>
                <div className='portfolio-card-container card-slider'>
                    <Swiper
                        ref={EachHomeSwiperRef}
                        breakpoints={{
                            1024: { slidesPerView: 1.1, spaceBetween: 10 },
                            768: { slidesPerView: 1.1, spaceBetween: 10 },
                            640: { slidesPerView: 1.1, spaceBetween: 5 },
                            320: { slidesPerView: 1.1, spaceBetween: 5 }
                        }}
                    >
                        {clubHomeData.imageGallery.map((img, index) => (
                            <SwiperSlide key={index}>
                                <div className="portfolio-card">
                                    <img src={img} alt='' className="portfolio-image"/>
                                </div>
                            </SwiperSlide>
                        ))}
                        <div className='swiper-buttons-container-portfolio'>
                            <div className={`swiper-button-prev-portfolio ${isBeginning ? "disabled" : ""}`} onClick={() => handlePrev(EachHomeSwiperRef)}>←</div>
                            <div className={`swiper-button-next-portfolio ${isEnd ? "disabled" : ""}`} onClick={() => handleNext(EachHomeSwiperRef)}>→</div>
                        </div>
                    </Swiper>
                </div>
            </div>
            <div className='each-card-list-container'>
                <ul className='each-card-list-items'>
                    {clubHomeData.cardInfo.map((info, index) => (
                        <li key={index}>{info}</li>
                    ))}
                </ul>
            </div>
            <div className='each-card-list-container-transfers'>
                <h2>Transfers:</h2>
                <p>{clubHomeData.transferInfo}</p>
            </div>
            <div className='features-container'>
                <h2 className='features-head'>Features</h2>
                <ul className='features-list'>
                    {clubHomeData.features.map((feature, index) => (
                        <li key={index}>{feature}</li>
                    ))}
                </ul>
            </div>
            <div className='each-home-floor-maps'>
                <div className='floorplan-container'>
                <h2 className='floor-map-head'>Floor Plans</h2>
                    <Slider {...settings}>
                        {clubHomeData.floorPlans.map((plan, index) => (
                            <div key={index}>
                               <p className='floorplan-title'>{plan.title}</p>
                                <img src={plan.image} alt='' className='floorplan-image'/>
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>
            <ClubFooter/>
        </div>
    );
}

export default EachClubHome;
