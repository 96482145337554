import React, { useState, useEffect } from 'react';
import './index.css';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

const Users = () => {
    const [users, setUsers] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredUsers, setFilteredUsers] = useState([]);

    useEffect(() => {
        const fetchUsers = async () => {
            const usersSnapshot = await firebase.firestore().collection('users').get();
            const usersData = usersSnapshot.docs.map(doc => doc.data());

            setUsers(usersData);
            setFilteredUsers(usersData); 
        };

        fetchUsers();
    }, []);

    useEffect(() => {
        const filtered = users.filter(user => 
            user.email.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredUsers(filtered);
    }, [searchTerm, users]);

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    return (
        <div className="users-container">
            <div className="search-bar-container">
                <input 
                    type="text" 
                    placeholder="Search by Email" 
                    value={searchTerm}
                    onChange={handleSearchChange}
                />
            </div>
            <div className="table-container">
                <table className="bookings-table">
                    <thead>
                        <tr>
                            <th>Email</th>
                            <th>Name</th>
                            <th>Role</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredUsers.map((user, index) => (
                            <tr key={index}>
                                <td>{user.email}</td>
                                <td>{user.name}</td>
                                <td>{user.role}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default Users;
