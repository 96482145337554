import React, { useState, useEffect } from 'react';
import './index.css';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

const Vendors = () => {
    const [vendors, setVendors] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredVendors, setFilteredVendors] = useState([]);

    useEffect(() => {
        const fetchVendors = async () => {
            const vendorsSnapshot = await firebase.firestore().collection('vendorUsers').get();
            const vendorsData = vendorsSnapshot.docs.map(doc => doc.data());

            setVendors(vendorsData);
            setFilteredVendors(vendorsData); 
        };

        fetchVendors();
    }, []);

    useEffect(() => {
        const filtered = vendors.filter(vendor => 
            vendor.name.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredVendors(filtered);
    }, [searchTerm, vendors]);

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    return (
        <div className="vendors-container">
            <div className="search-bar-container">
                <input 
                    type="text" 
                    placeholder="Search by Hotel Name" 
                    value={searchTerm}
                    onChange={handleSearchChange}
                />
            </div>
            <div className="table-container">
                <table className="bookings-table">
                    <thead>
                        <tr>
                            <th>Hotel Name</th>
                            <th>Hotel Email</th>
                            <th>Category</th>
                            <th>State</th>
                            <th>City</th>
                            <th>Place</th>
                            <th>Role</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredVendors.map((vendor, index) => (
                            <tr key={index}>
                                 <td>{vendor.name}</td>
                                 <td>{vendor.email}</td>
                                <td>{vendor.category}</td>
                                <td>{vendor.state}</td>
                                <td>{vendor.city}</td>
                                <td>{vendor.place}</td>
                                <td>{vendor.role}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default Vendors;
