import React from 'react';
import ClubHeader from '../ClubHeader';
import ClubFooter from '../ClubFooter';
import Club3 from '../Club3';
import { FaArrowRightLong } from "react-icons/fa6";
import {Link } from 'react-router-dom';
import './index.css';

const ClubTestimonial = () => {
    return (
        <div className='club-testimonial-container'>
        <ClubHeader/>
        <div className='club-testimonial-img-container'>
            <img src='https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/tu-club%2FLat%20page%20image.jpg?alt=media&token=101ac099-a78b-4117-bd81-71bcbc76d858' alt='' className='club-testimonial-img' />
        </div>
        <div>
        <Club3/>
        </div>
        <div>
        <div className='club-testimonial-section'>
            <div className='club-testimonial-section-flex-1'>
                <h1 className='mt-3'>Register to TU CLUB</h1>
                <p>If the concept of second home ownership intrigues you, why commit significant capital to a
                single holiday property in one location, along with the maintenance hassles from afar?
                Choose the smart way — join The Travel Unbounded Club.</p>
                <p>Register now to explore our membership plans and find the perfect option for your travel
                needs.</p>
                <div className='explore-btn'>
                <Link to='/club-register'><button>Register to TU-Club <FaArrowRightLong style={{color:'brown'}}/></button></Link>
                </div>
            </div>
            <div className='club-testimonial-section-flex-2'>
            <img src='https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/tu-club%2FTU%20CLUB%202nd%20page%20image.jpg?alt=media&token=8ed9f570-5758-4d4b-9b9b-d4a069acc2dc' alt='' className='club-testimonial-section-img'/>
            </div>
            </div>
            </div>
        <ClubFooter/>
        </div>
    );
}
export default ClubTestimonial;