import { Link } from "react-router-dom";
import "./index.css";

const NotFound = () => {
  const onClickBackToHome = () => {
    window.history.back('/');
  };

  return (
    <div className="not-found-container">
        <h1 className="font">404</h1>
      <h1 className="font mb-3">Page Not Found</h1>
      <p
        className="
      font mb-3" style={{color:'red'}}
      >
        we are sorry, the page you requested could not be found!
      </p>
      <Link to="/">
        <button
          type="button"
          className="btn btn-primary"
          onClick={onClickBackToHome}
        >
          Go Back to Home
        </button>
      </Link>
    </div>
  );
};

export default NotFound;
