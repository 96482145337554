import React from 'react';
import { FaFacebook } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FaInstagramSquare } from "react-icons/fa";
import { FaWhatsappSquare } from "react-icons/fa";
import './index.css';

const Footer = () => {
  return (
    <div className="footer-container">
      <div className="contact-section">
        <div className="contact-item">
          <span className="title">CALL</span>
          <p>+91 9845090014</p>
        </div>
        <div className='contact-item'>
          <div className='logo'>
            <img src='/logo.png' alt='logo' style={{filter:'brightness(100%)'}}/>
          </div>
        </div>
        <div className="contact-item">
          <span className="title">EMAIL</span>
          <p>now@travelunbounded.com</p>
        </div>
      </div>
      <div className="address-section">
        <span className="title">REGISTERED ADDRESSES</span>
        <div className='contact-section-address'>
        <p>No 10, 8th Main, 12th Cross, Vinayaka Nagar B block, Konene Agrahara, Bangalore 560017</p>
        <p>No 32, Global Incubation Services, CA Site, No 1, HAL 3rd Stage EXTN, behind Hotel Leela Palace, HAL 2nd Stage, Kodihalli, Bengaluru, Karnataka 560008</p>
        <p>Westpark Towers, Muthithi Road, Nairobi, P.O. Box: 6950 Postal Code 00100</p>
        </div>
      </div>

      <div className="links-section">
        <a href="/contact">CONTACT</a>
        <a href="/club-team">CLUB TEAM</a>
        <a href="/privacy-policy">PRIVACY POLICY</a>
        <a href="/terms-conditions">TERMS & CONDITIONS</a>
      </div>

      <div className="social-media-section">
        <a href="https://www.facebook.com/travelunboundedcom" target="__blank" aria-label="Facebook"><FaFacebook size={30} /></a>
        <a href="https://www.linkedin.com/company/travel-unbounded/" target="__blank" aria-label="LinkedIn"><FaLinkedin size={30} /></a>
        <a href="https://www.instagram.com/travel_ub/" target="__blank" aria-label="Instagram"><FaInstagramSquare size={30} /></a>
        <a href="https://wa.me/9845090014" target="__blank" aria-label="WhatsApp"><FaWhatsappSquare size={30} /></a>
      </div>

      <div className="copyright-section">
        <p>Copyright@2024 Travel Unbounded. All rights reserved.</p>
      </div>
    </div>
  );
};

export default Footer;
