import React from 'react';
import ClubFooter from '../ClubFooter';
import ClubHeader from '../ClubHeader2';
import { FaBed, FaBath, FaRulerCombined } from "react-icons/fa";
import { HiOutlineArrowSmallRight } from "react-icons/hi2";
import { FaHandshakeSimple } from "react-icons/fa6";
import { ImClubs } from "react-icons/im";
import {Link} from 'react-router-dom';
import './index.css';

const properties = [
    {
      id:1,
      image: "/img-1.avif",
      title: "Casa Paradiso",
      location: "Laveno, Italy",
      type:"Classic Collection | Owned",
      beds: 5,
      baths: 3,
      size: "4000 sqft",
    },
    {
      id:2,
      image: "/img-2.avif",
      title: "Villa La Solana",
      location: "Ibiza, Spain",
      type:"City Collection | Owned",
      beds: 4,
      baths: 3,
      size: "3500 sqft",
    },
    {
      id:3,
      image: "/img-3.avif",
      title: "Gran Vista",
      location: "Mallorca, Spain",
      type:"Classic Collection | Owned",
      beds: 6,
      baths: 5,
      size: "5000 sqft",
    },
    {
      id:4,
      image: "/img-1.avif",
      title: "La Retraite",
      location: "Le Rouret, France",
      type:"City Collection | Owned",
      beds: 4,
      baths: 3,
      size: "4000 sqft",
    },
    {
      id:5,
      image: "/img-2.avif",
      title: "Kentroma House",
      location: "Corfu, Greece",
      type:"Classic Collection | Owned",
      beds: 4,
      baths: 3,
      size: "3500 sqft",
    },
    {
      id:6,
      image: "/img-3.avif",
      title: "Masseria L'Olivo",
      location: "Puglia, Italy",
      type:"City Collection | Owned",
      beds: 5,
      baths: 5,
      size: "5000 sqft",
    },
  ];

const ClubHomesPage = () => {
    return (
        <div className='club-homes'>
        <ClubHeader style={{background:'white'}}/>
        <div className='club-homes-select-container'>
            <select name='collections'>
                <option value='all'>All Collections</option>
                <option value='classic'>Classic Collection</option>
                <option value='city'>City Collection</option>
            </select>
            <select name='types'>
                <option value='all'>All Types</option>
                <option value='classic'>Villas</option>
                <option value='city'>Chalets</option>
                <option value='city'>Appartments</option>
            </select>
            <select name='locations'>
                <option value='all'>All Locations</option>
                <option value='classic'>India</option>
            </select>
        </div>    
        <div className='club-homes-icon-key'>
            <p>Icon Key: </p>
            <p><ImClubs className='club-home-icon'/> Club Homes</p>
            <p><FaHandshakeSimple className='club-home-icon'/> Associate Homes</p>
        </div>
        <div className='club-homes-cards'>
        {properties.map((property, index) => (
            <div className="property-card-club-homes" key={index}>
              <img
                src={property.image}
                alt={property.title}
                className="property-image-club-homes"
              />
              <div className='club-home-icon-card-container'>
                <FaHandshakeSimple className='club-home-icon-card'/>
              </div>
              <div className="property-info-club-homes">
                <h3>{property.title}</h3>
                <p>{property.location}</p>
                <p>{property.type}</p>
                <div className="property-details-club-homes">
                  <FaBed /> {property.beds} Beds &nbsp; | &nbsp;
                  <FaBath /> {property.baths} Baths &nbsp; | &nbsp;
                  <FaRulerCombined /> {property.size}
                </div>
                <Link to={`/associate-homes/${property.id}`}>
                <p className='read-more-club-homes'>View Details <HiOutlineArrowSmallRight /></p>
                </Link>
              </div>
            </div>
        ))}
        </div>
        <ClubFooter/>
        </div>
    );
}

export default ClubHomesPage;
