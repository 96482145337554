import React, { useState, useEffect } from 'react';
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { toast, Toaster } from "react-hot-toast";
import Listing from '../Listing';
import './index.css';

const firebaseConfig = {
  apiKey: "AIzaSyB4BPMHKw0OXM2PhQX9NcT25lDBWpRpIPQ",
  authDomain: "phone-auth-d0a8d.firebaseapp.com",
  projectId: "phone-auth-d0a8d",
  storageBucket: "phone-auth-d0a8d.appspot.com",
  messagingSenderId: "309252587015",
  appId: "1:309252587015:web:3f9dc2505965e66269b4a2"
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

const db = firebase.firestore();

const VendorLogin = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [creatingAccount, setCreatingAccount] = useState(false);
    const [name, setName] = useState('');
    const [loggedInUser, setLoggedInUser] = useState(null);
    const [state, setState] = useState('');
    const [category, setCategory] = useState('');
    const [place, setPlace] = useState('');
    const [city, setCity] = useState('');
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [places, setPlaces] = useState([]);
    const [vendor, setVendor] = useState(null);

    useEffect(() => {
        const fetchStates = async () => {
          try {
            const statesSnapshot = await db.collection(category).get();
            const statesData = statesSnapshot.docs.map(doc => doc.id);
            setStates(statesData);
          } catch (error) {
            console.error('Error fetching states: ', error);
          }
        };

        if (category) {
            fetchStates();
        } else {
            setStates([]);
        }
      }, [category]);


      const handleStateChange = async (e) => {
        const selectedState = e.target.value;
        setState(selectedState);

        if (category === "WeekendGateways") {
            try {
              const citiesSnapshot = await db.collection(category).doc(selectedState).collection(selectedState).get();
              const citiesData = citiesSnapshot.docs.map(doc => doc.id);
              setCities(citiesData);
              setPlaces([]);
            } catch (error) {
              console.error('Error fetching cities: ', error);
            }
        } else {
            try {
              const placesSnapshot = await db.collection(category).doc(selectedState).collection(selectedState).get();
              const placesData = placesSnapshot.docs.map(doc => doc.id);
              setPlaces(placesData);
            } catch (error) {
              console.error('Error fetching places: ', error);
            }
        }
      };

      const handleCityChange = async (e) => {
        const selectedCity = e.target.value;
        setCity(selectedCity);

        try {
          const placesSnapshot = await db.collection(category).doc(state).collection(state).doc(selectedCity).collection(selectedCity).get();
          const placesData = placesSnapshot.docs.map(doc => doc.id);
          setPlaces(placesData);
        } catch (error) {
          console.error('Error fetching places: ', error);
        }
      };

      useEffect(() => {
        firebase.auth().onAuthStateChanged(user => {
            if (user) {
                setLoggedInUser(user);
                db.collection('vendorUsers').doc(user.uid).get().then(doc => {
                    if (doc.exists && doc.data().role === 'vendor') {
                        setVendor(true);
                    } else {
                        setVendor(false);
                    }
                });
            } else {
                setLoggedInUser(null);
                setVendor(false);
            }
        });
    }, []);

    const handleEmailChange = (e) => setEmail(e.target.value);
    const handlePasswordChange = (e) => setPassword(e.target.value);
    const handleNameChange = (e) => setName(e.target.value);

    const togglePasswordVisibility = () => setShowPassword(!showPassword);

    const handleLogin = async () => {
        try {
            const userCredential = await firebase.auth().signInWithEmailAndPassword(email, password);
            const user = userCredential.user;
            const userDoc = await db.collection('vendorUsers').doc(user.uid).get();
            const userData = userDoc.data();
    
            if (userData.role === 'vendor') {
                console.log('Logged in successfully!');
                setEmail('');
                setPassword('');
                toast.success('Logged in successfully');
            } else {
                throw new Error('Unauthorized access');
            }
        } catch (error) {
            console.error('Error signing in:', error);
            toast.error('Please enter valid Email or Password');
        }
    };
    

    const handleCreateAccount = async () => {
      try {
          const userCredential = await firebase.auth().createUserWithEmailAndPassword(email, password);
          const user = userCredential.user;
  
          await db.collection('vendorUsers').doc(user.uid).set({
              category,
              name,
              state,
              city: category === "WeekendGateways" ? city : "",
              place,
              email,
              role: 'vendor'
          });
  
          console.log('Account created successfully!');
          setCreatingAccount(false);
          setEmail('');
          setPassword('');
          setName('');
          setCategory('');
          toast.success('Account created successfully!')
      } catch (error) {
          console.error('Error creating account:', error.message); 
          toast.error('Error creating account. Please try again.');
      }
  };

    const handleSubmit = (e) => {
        e.preventDefault();
    }

    return (
        <>
        <div className='container'>
                <Toaster toastOptions={{ duration: 5000 }} style={{ fontStyle: 'Canva Sans', color: 'black' }} />
                {loggedInUser && vendor ? (
                       <Listing/>
                ) : (
                    <form onSubmit={handleSubmit}>
                        <div className='parent-container'>
                        <div className='signin-container'>
                            <div className='element-container'>
                                <h3 className='login-head mb-3 text-center'>{creatingAccount ? 'Create Account' : 'Login'}</h3>
                                {creatingAccount && (
                                    <>
                                    <div className='input-container-select'>
                                        <label>Category</label>
                                            <select value={category} onChange={(e) => setCategory(e.target.value)} className='mb-3'>
                                            <option value="">Select Category</option>
                                            <option value="NatureDestinations">Nature</option>
                                            <option value="OnenessDestinations">Oneness</option>
                                            <option value="WeekendGateways">Weekend Gateways</option>
                                            </select>
                                        </div>
                                        <div className='input-container-select'>
                                        <label>State</label>
                                            <select value={state} onChange={handleStateChange} className='mb-3'>
                                            <option value="" disabled>Select State</option>
                                            {states.map(state => (
                                                <option key={state} value={state}>
                                                {state}
                                                </option>
                                            ))}
                                            </select>
                                        </div>
                                        {category === "WeekendGateways" && (
                                            <div className='input-container-select'>
                                            <label>City</label>
                                                <select value={city} onChange={handleCityChange} className='mb-3'>
                                                <option value="" disabled>Select City</option>
                                                {cities.map(city => (
                                                    <option key={city} value={city}>
                                                    {city}
                                                    </option>
                                                ))}
                                                </select>
                                            </div>
                                        )}
                                        <div className='input-container-select'>
                                        <label>Place</label>
                                            <select value={place} onChange={(e) => setPlace(e.target.value)}  className='mb-3'>
                                            <option value="" disabled>Select Place</option>
                                            {places.map(place => (
                                                <option key={place} value={place}>
                                                {place}
                                                </option>
                                            ))}
                                            </select>
                                        </div>
                                        <label>Hotel Name</label>
                                        <input type="text" className='mb-3' placeholder="Enter your name" value={name} onChange={handleNameChange} />
                                    </>
                                )}
                                <label>Email</label>
                                <input type="email" className='mb-3' placeholder="Enter email" value={email} onChange={handleEmailChange} autoComplete='username' />
                                <label>Password</label>
                                <div className="password-input-container">
                                    <input
                                        type={showPassword ? "text" : "password"}
                                        className='mb-3 password-input'
                                        placeholder="Enter password"
                                        value={password}
                                        onChange={handlePasswordChange}
                                        autoComplete="current-password"
                                    />
                                    <div className="eye-icon-container" onClick={togglePasswordVisibility}>
                                        {showPassword ? <FiEye size={20} /> : <FiEyeOff size={20} />}
                                    </div>
                                </div>
                                {creatingAccount ? (
                                    <button onClick={handleCreateAccount} type='submit' className='btn-submit-mail'>Create Account</button>
                                ) : (
                                    <>
                                        <button onClick={handleLogin} type='submit' className='btn-submit-mail mb-2'>Login with Email</button>
                                    </>
                                )}
                                <p className='create-account-link' onClick={() => setCreatingAccount(!creatingAccount)}>{creatingAccount ? 'Already have an account? Login' : 'Create an account'}</p>
                            </div>
                        </div>
                        </div>
                    </form>
                )}
            </div>
        </>
    );
};

export default VendorLogin;
