
import React from 'react';
import { FaStar } from 'react-icons/fa';

const StarRating = ({ rating, onStarClick }) => {
    const renderStars = () => {
        const stars = [];
        for (let i = 1; i <= 5; i++) {
            stars.push(
                <span key={i} onClick={() => onStarClick(i)}>
                    <FaStar color={i <= rating ? '#ffc107' : '#e4e5e9'} size={24} />
                </span>
            );
        }
        return stars;
    };

    return <div>{renderStars()}</div>;
};

export default StarRating;
