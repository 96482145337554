import React from 'react';
import ClubHeader from '../ClubHeader';
import ClubFooter from '../ClubFooter';
import { FaArrowRightLong } from "react-icons/fa6";
import { MdSecurity, MdCleanHands,MdOutlineFamilyRestroom, MdOutlineVilla } from "react-icons/md";
import { LiaSearchLocationSolid } from "react-icons/lia";
import { TbToolsKitchen2 } from "react-icons/tb";
import { FaUserDoctor } from "react-icons/fa6";
import { FaCarSide } from "react-icons/fa";
import {Link} from 'react-router-dom';
import './index.css';

const cardsData = [
  { icon: MdSecurity, title: 'Safety and Security', content: 'Each property is equipped with advanced security systems and protocols to ensure your safety and peace of mind at all times.' },
  { icon: MdCleanHands, title: 'Impeccable Hygiene', content: 'We adhere to the highest standards of cleanliness and hygiene, with thorough cleaning practices to provide a spotless and healthy environment.' },
  { icon: LiaSearchLocationSolid, title: 'Handpicked Locations', content: 'Our properties are situated in meticulously selected locations, offering breathtaking views and exclusive access to the most desirable destinations.' },
  { icon: MdOutlineVilla, title: 'Memorable Experiences', content: 'We curate exceptional experiences tailored to your preferences, ensuring that every moment spent in our properties is unique and unforgettable.' },
  { icon: MdOutlineFamilyRestroom, title: 'Family-Friendly', content: 'Designed with all ages in mind, our properties offer comfort and amenities that cater to families, making them perfect for guests of every generation.' },
  { icon: TbToolsKitchen2, title: 'Well-Equipped Kitchen', content: 'Enjoy the convenience of a fully equipped kitchen, allowing you to prepare meals to your taste or request our chefs to create culinary delights for you.' },
  { icon: FaUserDoctor, title: 'Doctor on Call', content: 'For your reassurance, a doctor is available on call to provide medical assistance whenever needed.' },
  { icon: FaCarSide, title: '24-Hour Vehicle Support', content: 'Benefit from round-the-clock vehicle support for seamless transportation and mobility throughout your stay.' }
];

const ClubService = () => {
    return (
        <>
        <ClubHeader/>
          <div>
            <img src='/club-service.avif' alt='' className='club-service-img' />
          </div>
          <div className='club-service-flex'>
            <h1>SERVICE</h1>
            <p>According to your membership tier, you can reserve your dream holiday at TU Club homes well in advance, alleviating any last-minute rush or anxiety.</p>
            <p>At each TU Club Home, a dedicated team is there to warmly welcome you
            and ensure that your stay is as seamless and unforgettable as possible.</p>
            <p>Savor local cuisines crafted by our chefs, who are trained under the guidance of our
            Master Chef. Enjoy personalized local sightseeing with our experienced guides, and relax
            with seamless internal transfers arranged by our trusted logistics partners.</p>
          </div>
          <h1 className='text-center mb-3'>Property Signature Features</h1>
          <div className="service-cards-container">
            {cardsData.map((card, index) => (
                <div className="service-card" key={index}>
                    <div className="service-card-row">
                        <div>
                            <card.icon className='service-icon' />
                        </div>
                        <div className="service-vertical-line"></div>
                        <div className="service-text">
                            <h3>{card.title}</h3>
                            <p>{card.content}</p>
                        </div>
                    </div>
                </div>
            ))}
        </div>
        <div className='club-testimonial-section'>
            <div className='club-testimonial-section-flex-1'>
                <h2 className='mt-3'>Register now and unlock a world of exceptional experiences!</h2>
                <p>Discover unparalleled luxury and personalized service at our exquisite properties. Enjoy
                access to handpicked locations and curated activities that cater to your every desire. Begin
                your journey today and indulge in unforgettable moments designed just for you.</p>
                <div className='service-btn'>
                <Link to='/club-register'><button>Register to TU-Club <FaArrowRightLong style={{color:'brown'}}/></button></Link>
                </div>
            </div>
            <div className='club-testimonial-section-flex-2'>
            <img src='https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/tu-club%2FTU%20CLUB%202nd%20page%20image.jpg?alt=media&token=8ed9f570-5758-4d4b-9b9b-d4a069acc2dc' alt='' className='club-testimonial-section-img'/>
            </div>
            </div>
        <ClubFooter/>
        </>
    );
}

export default ClubService;
