import React, { useState, useEffect } from 'react';
import { Stepper, Step, StepLabel, Button, TextField, Checkbox, FormControlLabel, Select, MenuItem, InputLabel } from '@mui/material';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import { Link } from 'react-router-dom';
import { toast, Toaster } from "react-hot-toast";
import './list.css';

const firebaseConfig = {
  apiKey: "AIzaSyB4BPMHKw0OXM2PhQX9NcT25lDBWpRpIPQ",
  authDomain: "phone-auth-d0a8d.firebaseapp.com",
  projectId: "phone-auth-d0a8d",
  storageBucket: "phone-auth-d0a8d.appspot.com",
  messagingSenderId: "309252587015",
  appId: "1:309252587015:web:3f9dc2505965e66269b4a2"
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

const db = firebase.firestore();

const HotelForm = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [isChecked, setIsChecked] = useState(false);
  const [formData, setFormData] = useState(() => {
    const savedData = localStorage.getItem('formData');
    return savedData ? JSON.parse(savedData) : {
      categories: [],
      hotelMail: '',
      hotelContact: '',
      singleRoomCost: '',
      price: '',
      extraBedCost: '',
      aboutHotel: '',
      rating: '',
      facilities: [],
      img1: '',
      img2: '',
      img3: '',
      img4: '',
      cp1: '',
      cp2: '',
      cp3: '',
    };
  });
  const [loggedInUser, setLoggedInUser] = useState(null);
  const [userData, setUserData] = useState(null);
  const [formSubmitted, setFormSubmitted] = useState(() => {
    const storedFormSubmitted = localStorage.getItem('formSubmitted');
    return storedFormSubmitted ? JSON.parse(storedFormSubmitted) : false;
  });
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const handleCheckboxChangeTerms = (event) => {
    setIsChecked(event.target.checked);
  };

  const handleCategoryChange = (event) => {
    setSelectedCategories(event.target.value);
    setFormData({ ...formData, categories: event.target.value });
  };

  useEffect(() => {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        setLoggedInUser(user);
        setIsLoading(false);
      } else {
        setLoggedInUser(null);
      }
    });
  }, []);

  useEffect(() => {
    if (loggedInUser) {
      db.collection('vendorUsers').doc(loggedInUser.uid).get()
        .then(doc => {
          if (doc.exists) {
            const userData = doc.data();
            setUserData(userData);
          } else {
            console.log('No such document!');
          }
        })
        .catch(error => {
          console.log('Error getting document:', error);
        });
    }
  }, [loggedInUser]);


  useEffect(() => {
    if (loggedInUser) {
      db.collection('vendorUsers').doc(loggedInUser.uid).get()
        .then(doc => {
          if (doc.exists) {
            const userData = doc.data();
            setUserData(userData);
            if (formSubmitted && userData && userData.category && userData.name && userData.state && userData.place) {
              let docRef;
              if (userData.category === 'WeekendGateways') {
                docRef = db.collection(userData.category)
                  .doc(userData.state)
                  .collection(userData.state)
                  .doc(userData.city)
                  .collection(userData.city)
                  .doc(userData.place)
                  .collection('hotels')
                  .doc(userData.name);
              } else {
                docRef = db.collection(userData.category)
                  .doc(userData.state)
                  .collection(userData.state)
                  .doc(userData.place)
                  .collection('hotels')
                  .doc(userData.name);
              }

              docRef.get()
                .then(doc => {
                  if (doc.exists) {
                    const hotelData = doc.data();
                    setFormData(hotelData);
                  } else {
                    console.log('No such hotel document!');
                  }
                })
                .catch(error => {
                  console.log('Error getting hotel document:', error);
                });
            }
          } else {
            console.log('No such document!');
          }
        })
        .catch(error => {
          console.log('Error getting document:', error);
        });
    }
  }, [loggedInUser, formSubmitted]);

  useEffect(() => {
    localStorage.setItem('formData', JSON.stringify(formData));
  }, [formData]);

  const steps = ['', '', '', '', ''];

  const facilitiesOptions = [
    "Wifi", "Pet Friendly", "AC rooms", "Room heater", "Wheel chair", "Kid's play area", "Garden",
    "Breakfast Included", "Laundry", "Intercom", "Doctor on call", "Emergency medical kit",
    "Nature trail", "Guide", "Bird Hide", "TV", "Rooms on ground level", "Barbeque", "Camp Fire", "Indoor games",
    "Outdoor games", "Swimming Pool", "Gym", "Bar", "Beach Front", "Hill View", "Lake View", "Temple Darshan arranged"
  ];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setFormData({ ...formData, facilities: [...formData.facilities, value] });
    } else {
      setFormData({ ...formData, facilities: formData.facilities.filter(f => f !== value) });
    }
  };

  const handleImageUpload = async (e) => {
    const { name, files } = e.target;
    const file = files[0];
    const storageRef = firebase.storage().ref(`images/${file.name}`);
    await storageRef.put(file);
    const url = await storageRef.getDownloadURL();
    setFormData({ ...formData, [name]: url });
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const validateForm = () => {
    const requiredFields = [
      'name', 'hotelMail', 'hotelContact', 'singleRoomCost', 'price',
      'extraBedCost', 'aboutHotel', 'rating', 'img1', 'img2', 'img3', 'img4', 'cp1'
    ];
    for (let field of requiredFields) {
      if (!formData[field]) {
        return false;
      }
    }
    return true;
  };

  const addFivePercent = (value) => {
    return parseFloat(value) * 1.05;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      toast.error('Please fill all required fields.');
      return;
    }
    if (activeStep === steps.length - 1) {
      try {
        const updatedFormData = {
          ...formData,
          singleRoomCost: addFivePercent(formData.singleRoomCost),
          price: addFivePercent(formData.price),
          extraBedCost: addFivePercent(formData.extraBedCost),
        };

        let docRef;
        if (userData.category === 'WeekendGateways') {
          docRef = db.collection(userData.category)
            .doc(userData.state)
            .collection(userData.state)
            .doc(userData.city)
            .collection(userData.city)
            .doc(userData.place)
            .collection('hotels')
            .doc(userData.name);
        } else {
          docRef = db.collection(userData.category)
            .doc(userData.state)
            .collection(userData.state)
            .doc(userData.place)
            .collection('hotels')
            .doc(userData.name);
        }

        await docRef.set(updatedFormData);

        console.log('Data added successfully');
        toast.success('Hotel data added successfully');
        setFormSubmitted(true);
        localStorage.setItem('formSubmitted', JSON.stringify(true));
      } catch (error) {
        console.error('Error storing data to Firestore:', error);
        toast.error('Error adding hotel data');
      }
    } else {
      toast.error('Please complete all steps before submitting.');
    }
  };

  if (isLoading) {
    return(
      <div className='loading-container'>
      <p className='loading'></p>
      </div>
    )
  }

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <div>
            <InputLabel>Add to other Category</InputLabel>
            <Select
              multiple
              value={selectedCategories}
              onChange={handleCategoryChange}
              fullWidth
              margin="dense"
              required
            >
              <MenuItem value="NatureDestinations">Nature</MenuItem>
              <MenuItem value="OnenessDestinations">Oneness</MenuItem>
              <MenuItem value="WeekendGateways">Weekend Gateways</MenuItem>
            </Select>
            <TextField label="Hotel Email" name="hotelMail" value={formData.hotelMail} onChange={handleInputChange} fullWidth margin="dense" required />
            <TextField label="Hotel Contact" name="hotelContact" value={formData.hotelContact} onChange={handleInputChange} fullWidth margin="dense" required />
            <TextField label="About Hotel" name="aboutHotel" value={formData.aboutHotel} onChange={handleInputChange} fullWidth margin="dense" multiline rows={2} required />
          </div>
        );
      case 1:
        return (
          <div>
            <TextField label="Google Rating" name="rating" value={formData.rating} onChange={handleInputChange} fullWidth margin="dense" required />
            <TextField label="Single Occupancy Room Price" name="singleRoomCost" value={formData.singleRoomCost} onChange={handleInputChange} fullWidth margin="dense" required />
            <TextField label="Double Occupancy Room Price" name="price" value={formData.price} onChange={handleInputChange} fullWidth margin="dense" required />
            <TextField label="Double + Extra Bed Price" name="extraBedCost" value={formData.extraBedCost} onChange={handleInputChange} fullWidth margin="dense" required />
          </div>
        );
      case 2:
        return (
          <div>
            <h4 className='mt-2 font'>Select Facilities you provide</h4>
            <div className='facilities-container-listing mt-3 mb-3'>
              {facilitiesOptions.map((facility) => (
                <FormControlLabel
                  key={facility}
                  control={<Checkbox value={facility} checked={formData.facilities.includes(facility)} onChange={handleCheckboxChange} />}
                  label={facility}
                  className="facility-label"
                />
              ))}
            </div>
          </div>
        );
      case 3:
        return (
          <div>
            <h4 className='mt-2 font'>Upload Images</h4>
            <p className='font' style={{ color: 'red' }}>*Maximum size 1 mb</p>
            <div className='images-listing'>
              <label className='font'>Hotel Home Image:</label>
              <input type="file" name="img1" onChange={handleImageUpload} style={{ marginBottom: '10px' }} required />
              <label className='font'>Hotel Room Image1:</label>
              <input type="file" name="img2" onChange={handleImageUpload} style={{ marginBottom: '10px' }} required />
              <label className='font'>Hotel Room Image2:</label>
              <input type="file" name="img3" onChange={handleImageUpload} style={{ marginBottom: '10px' }} required />
              <label className='font'>Hotel Room Image3:</label>
              <input type="file" name="img4" onChange={handleImageUpload} style={{ marginBottom: '10px' }} required />
            </div>
          </div>
        );
      case 4:
        return (
          <div>
            <TextField
              label="Cancellation Policies"
              name="cp1"
              value={formData.cp1}
              onChange={handleInputChange}
              fullWidth
              multiline
              rows={2}
              margin="dense"
            />
            <TextField
              label="Cancellation Policies"
              name="cp2"
              value={formData.cp2}
              onChange={handleInputChange}
              fullWidth
              multiline
              rows={2}
              margin="dense"
            />
            <TextField
              label="Cancellation Policies"
              name="cp3"
              value={formData.cp3}
              onChange={handleInputChange}
              fullWidth
              multiline
              rows={2}
              margin="dense"
            />
            <div>
              <label className='font'>
                <input
                  type="checkbox"
                  checked={isChecked}
                  onChange={handleCheckboxChangeTerms}
                  style={{ marginRight: '10px' }}
                />
                I agree, and continue with <Link to='/partner-terms' style={{ color: 'blue', textDecoration: 'underline' }}>Terms & conditions</Link>
              </label>
            </div>
          </div>
        );
      default:
        return 'Unknown step';
    }
  };

  if (formSubmitted) {
    return (
      <div className='submitted-screen'>
        <h1 className='font' style={{ color: 'brown' }}>Form Submitted Successfully</h1>
        <img src="/home/green-tick.png" alt='' className='green-tick'/>
        <div className='list-btn'>
        <Link to='/vendor-login/calendar'>
          <Button variant="contained" color="primary" style={{marginRight:'10px', width:'150px'}}>
            Availability Calendar
          </Button>
        </Link>
        <Button variant="contained" color="secondary" onClick={() => setFormSubmitted(false)} style={{marginLeft:'10px',width:'150px'}}>
          Edit Details
        </Button>
      </div>
      </div>
    );
  }

  return (
    <>
      <Toaster toastOptions={{ duration: 5000 }} style={{ fontStyle: 'Canva Sans', color: 'black' }} />
      <div className='btn-container-listing mt-3'>
        <div className='logo'>
        <Link to='/'><img src="/logo.png" alt="Logo" style={{filter:'brightness(100%)'}} /></Link>
          </div>
      </div>
      <div className='hotel-form-listing'>
        <h1 className='font mb-3' style={{ color: 'brown' }}>List your property to fill the details</h1>
        <Stepper activeStep={activeStep} className='mb-3'>
          {steps.map((label, index) => (
            <Step key={index}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <form>
          {renderStepContent(activeStep)}
          <div className='mt-3'>
            <Button disabled={activeStep === 0} style={{ width: '100px' }} onClick={handleBack}>
              Back
            </Button>
            {activeStep === steps.length - 1 && (
              <Button variant="contained" color="primary" style={{ width: '100px' }} onClick={handleSubmit} disabled={!isChecked}>
                Submit
              </Button>
            )}
            {activeStep !== steps.length - 1 && (
              <Button variant="contained" color="primary" style={{ width: '100px' }} onClick={handleNext}>
                Next
              </Button>
            )}
          </div>
        </form>
      </div>
    </>
  );
};

export default HotelForm;
