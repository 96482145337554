import React from 'react';
import {Link} from 'react-router-dom';
import { TbHandClick } from "react-icons/tb";
import Nav from '../Nav';
import './home.css';

const MobileHomeChange = () => {
    return (
        <div>
            <Nav/>
            <div className="container">
            <div className="row">
                <div className="col-12 col-md-6 col-lg-6 col-xl-6">
                <img src="https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/Nature%20Home%20page%20image.jpg?alt=media&token=86b8351f-f92b-483b-a2cd-2494a3805107" alt="nature" className='banner-img' />
               <Link to='/nature'> <button type='button' className='nature-button'>Nature</button> <TbHandClick className='click-button-right d-md-none' size={30}/></Link>
                </div>
                <div className="col-12 col-md-6 col-lg-6 col-xl-6">
                <img src="https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/Oneness%20Home%20page%20image.jpg?alt=media&token=42269040-1985-49d2-9516-c34bdf6c9b2b" alt="oneness" className='banner-img' />
                <Link to='/oneness'> <button type='button' className='oneness-button'>Oneness</button> <TbHandClick className='click-button-left d-md-none' size={30}/></Link>
                </div>
                <div className="col-12 col-md-6 col-lg-6 col-xl-6">
                <img src="https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/Weekedn%20Getaways%20home%20page%20image.jpg?alt=media&token=9c2d0a7f-ba6b-4cdc-a427-9f613521e65f" alt="weekends" className='banner-img' />
               <Link to='/weekends'> <button type='button' className='weekends-button'>Weekends</button> <TbHandClick className='click-button-right d-md-none' size={30}/></Link>
                </div>
                <div className="col-12 col-md-6 col-lg-6 col-xl-6">
                <img src="https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/Packages%20Home%20Page%20image.jpg?alt=media&token=bb5d8e3b-12d5-45fe-b665-1306b0c06aca" alt="packages" className='banner-img' />
               <Link to='/packages'><button type='button' className='packages-button'>Packages</button> <TbHandClick className='click-button-left d-md-none' size={30}/></Link>
                </div>
                <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                <img src="https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/Cotact%20us%20copy%202.jpg?alt=media&token=18bc1683-a8f2-464c-a91a-8f3392b7d5fd" alt="contact" className='banner-img' style={{objectFit:"fill"}}/>
                <Link to='/contact'><button type='button' className='contact-button'>Contact US</button><TbHandClick className='click-button-right d-md-none' size={30}/></Link>
                </div>
            </div>
        </div>
    </div>
    );
}

export default MobileHomeChange;
