import React, { useState, useEffect } from 'react';
import './calendar.css';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { toast, Toaster } from "react-hot-toast";

const firebaseConfig = {
  apiKey: "AIzaSyB4BPMHKw0OXM2PhQX9NcT25lDBWpRpIPQ",
  authDomain: "phone-auth-d0a8d.firebaseapp.com",
  projectId: "phone-auth-d0a8d",
  storageBucket: "phone-auth-d0a8d.appspot.com",
  messagingSenderId: "309252587015",
  appId: "1:309252587015:web:3f9dc2505965e66269b4a2"
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

const db = firebase.firestore();

const NatureCalendar = () => {
  const [availability, setAvailability] = useState({});
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [changes, setChanges] = useState({});
  const [loggedInUser, setLoggedInUser] = useState(null);
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        setLoggedInUser(user);
      } else {
        setLoggedInUser(null);
      }
    });
  }, []);

  useEffect(() => {
    if (loggedInUser) {
      db.collection('vendorUsers').doc(loggedInUser.uid).get()
        .then(doc => {
          if (doc.exists) {
            const userData = doc.data();
            setUserData(userData);
          } else {
            console.log('No such document!');
          }
        })
        .catch(error => {
          console.log('Error getting document:', error);
        });
    }
  }, [loggedInUser]);

  useEffect(() => {
    const fetchData = async () => {
      if (userData && userData.category && userData.state && userData.place && userData.name) {
        try {
          let docRef;
          if (userData.category === 'WeekendGateways') {
            docRef = db.collection(userData.category)
              .doc(userData.state)
              .collection(userData.state)
              .doc(userData.city)
              .collection(userData.city)
              .doc(userData.place)
              .collection('hotels')
              .doc(userData.name)
              .collection('availabilities')
              .doc('dates');
          } else {
            docRef = db.collection(userData.category)
              .doc(userData.state)
              .collection(userData.state)
              .doc(userData.place)
              .collection('hotels')
              .doc(userData.name)
              .collection('availabilities')
              .doc('dates');
          }

          const docSnapshot = await docRef.get();

          if (docSnapshot.exists) {
            const data = docSnapshot.data();
            setAvailability(data);
          } else {
            console.log('No availability data found in Firestore');
          }
        } catch (error) {
          console.error('Error fetching availability from Firestore:', error);
        }
      }
    };

    fetchData();
  }, [userData]);

  const handleAvailabilityChange = (date, value) => {
    setAvailability(prevState => ({
      ...prevState,
      [date]: value
    }));
  };

  const updateAvailabilityInFirestore = async () => {
    try {
      let docRef;
      if (userData.category === 'WeekendGateways') {
        docRef = db.collection(userData.category)
          .doc(userData.state)
          .collection(userData.state)
          .doc(userData.city)
          .collection(userData.city)
          .doc(userData.place)
          .collection('hotels')
          .doc(userData.name)
          .collection('availabilities')
          .doc('dates');
      } else {
        docRef = db.collection(userData.category)
          .doc(userData.state)
          .collection(userData.state)
          .doc(userData.place)
          .collection('hotels')
          .doc(userData.name)
          .collection('availabilities')
          .doc('dates');
      }

      const updatedAvailability = { ...availability, ...changes };
      await docRef.set(updatedAvailability);
      console.log('Availability updated in Firestore');
      toast.success('Availability updated successfully');
      setAvailability(updatedAvailability);
      setChanges({});
    } catch (error) {
      console.error('Error updating availability in Firestore:', error);
      toast.error("Error updating availability");
    }
  };

  const getStartingDayOfWeek = (year, month) => {
    return new Date(year, month, 1).getDay();
  };

  const getDayIndex = (year, month, day) => {
    const startingDayOfWeek = getStartingDayOfWeek(year, month);
    let dayIndex = (day + startingDayOfWeek - 1) % 7;
    return dayIndex;
  };

  const renderDateInputs = () => {
    if (selectedMonth === null) return null;

    const firstDayOfMonth = new Date(selectedYear, selectedMonth, 1);
    const startingDayOfWeek = firstDayOfMonth.getDay();
    const daysInMonth = new Date(selectedYear, selectedMonth + 1, 0).getDate();
    const dates = [];

    for (let i = 0; i < startingDayOfWeek; i++) {
      dates.push('');
    }

    for (let i = 1; i <= daysInMonth; i++) {
      dates.push(i);
    }

    const totalBoxes = 42;
    const remainingDays = totalBoxes - dates.length;
    for (let i = 0; i < remainingDays; i++) {
      dates.push('');
    }

    return dates.map((day, index) => {
      const currentDate = day !== '' ? new Date(selectedYear, selectedMonth, day) : null;
      const formattedDate = currentDate !== null ? formatDate(currentDate) : null;
      const dayIndex = getDayIndex(selectedYear, selectedMonth, day);
      return (
        <div className="calendar-box" key={index}>
          {day !== '' && (
            <React.Fragment>
              <div className="day-label">
                {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'][dayIndex]}
              </div>
              <span className={`date-label ${selectedMonth === new Date().getMonth() && day === new Date().getDate() ? 'current-day' : ''}`}>
                {formattedDate}
              </span>
              <input
                type="text"
                className="availability-input"
                value={availability[formattedDate] || ''}
                onChange={(e) => handleAvailabilityChange(formattedDate, e.target.value)}
              />
            </React.Fragment>
          )}
        </div>
      );
    });
  };

  const renderMonthNames = () => {
    const monthNames = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ];

    return monthNames.map((month, index) => (
      <div
        key={index}
        className={`month-name ${selectedMonth === index ? 'active' : ''}`}
        onClick={() => setSelectedMonth(index)}
      >
        {month}
      </div>
    ));
  };

  const renderYearSelector = () => {
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let i = currentYear; i < currentYear + 5; i++) {
      years.push(i);
    }
    return (
      <select className="year-selector" value={selectedYear} onChange={(e) => setSelectedYear(parseInt(e.target.value))}>
        {years.map((year, index) => (
          <option key={index} value={year}>{year}</option>
        ))}
      </select>
    );
  };

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const handleConfirmLogout = () => {
    firebase.auth().signOut()
      .then(() => {
        console.log('Logged Out Successfully');
        window.location.href = '/vendor-login';
        toast.success("Logged Out Successfully");
      })
      .catch(error => {
        console.error('Error logging out:', error);
        toast.error("Logout Failed");
      });
  };

  const handleGoBack = () => {
    window.history.back();
  };

  return (
    <div className='container'>
      <Toaster toastOptions={{ duration: 5000 }} style={{ fontStyle: 'Canva Sans', color: 'black' }} />
      <div className="calendar-container">
        {userData && userData.name && (
          <>
            <div className='calendar-btn mt-3'>
              <button className='btn btn-primary' style={{width:'150px'}} onClick={handleGoBack}>Back</button>
              <button type='submit' className='btn btn-success' style={{width:'150px'}} onClick={handleConfirmLogout}>Sign Out</button>
            </div>
            <h2 className="calendar-heading mt-3 mb-3">{userData.name.toUpperCase()} - Availability Calendar</h2>
          </>
        )}
        <div className="year-selector-container mb-3">
          {renderYearSelector()}
        </div>
        <div className="month-names mb-3">
          {renderMonthNames()}
        </div>
        <div className='text-center'>
          <button className="btn btn-success mt-2 mb-3" style={{width:'150px'}} onClick={updateAvailabilityInFirestore}>Update</button>
        </div>
        <div className="calendar-dates">
          {renderDateInputs()}
        </div>
      </div>
    </div>
  );
};

export default NatureCalendar;
