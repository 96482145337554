import React,{useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import { IoMdHome } from "react-icons/io";
import { FaArrowRight } from "react-icons/fa";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import './index.css';

const firebaseConfig = {
  apiKey: "AIzaSyB4BPMHKw0OXM2PhQX9NcT25lDBWpRpIPQ",
  authDomain: "phone-auth-d0a8d.firebaseapp.com",
  projectId: "phone-auth-d0a8d",
  storageBucket: "phone-auth-d0a8d.appspot.com",
  messagingSenderId: "309252587015",
  appId: "1:309252587015:web:3f9dc2505965e66269b4a2"
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

  const db = firebase.firestore();

  const Nature = () => {
    const [country, setCountry] = useState('');
    const [state, setState] = useState('');
    const [place, setPlace] = useState('');
    const [states, setStates] = useState([]);
    const [places, setPlaces] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
  
    const [natureTrendingPlaces, setNatureTrendingPlaces] = useState([]);
    const [natureWeekendPlaces, setNatureWeekendPlaces] = useState([]);

    useEffect(() => {
      const fetchNatureTrendingPlaces = async () => {
          try {
              const snapshot = await firebase.firestore().collection('natureTrendingPlaces').get();
              const data = snapshot.docs.map((doc) => ({
                  id: doc.id,
                  ...doc.data()
              }));
              setNatureTrendingPlaces(data);
              setIsLoading(false);
          } catch (error) {
              console.error('Error fetching trending places: ', error);
          }
      };

      fetchNatureTrendingPlaces();
  }, []);

  useEffect(() => {
    const fetchNatureWeekendPlaces = async () => {
        try {
            const snapshot = await firebase.firestore().collection('natureWeekendPlaces').get();
            const data = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data()
            }));
            setNatureWeekendPlaces(data);
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching trending places: ', error);
        }
    };

    fetchNatureWeekendPlaces();
}, []);

  useEffect(() => {
    const fetchStates = async () => {
      try {
        const statesSnapshot = await db.collection('NatureDestinations').get();
        const statesData = statesSnapshot.docs.map(doc => doc.id);
        setStates(statesData);
      } catch (error) {
        console.error('Error fetching states: ', error);
      }
    };

    fetchStates();
  }, []);

  const handleStateChange = async (e) => {
    const selectedState = e.target.value;
    setState(selectedState);

    try {
      const placesSnapshot = await db.collection('NatureDestinations').doc(selectedState).collection(selectedState).get();
      const placesData = placesSnapshot.docs.map(doc => doc.id);
      setPlaces(placesData);
    } catch (error) {
      console.error('Error fetching places: ', error);
    }
  };
  
    const handleCountryChange = (e) => {
      const value = e.target.value;
      setCountry(value);
      setState('');
      setPlace('');
    };
  
    const handleNextClick = () => {
      if (place) {
        const formattedState = state.replace(/\s+/g, '-');
        const formattedPlace = place.replace(/\s+/g, '-');
        window.location.href = `/nature/${formattedState}/${formattedPlace}`;
      }
    };
  
    if (isLoading) {
      return(
        <div className='loading-container'>
        <p className='loading'></p>
        </div>
      )
    }

    return (
      <div>
        <div className='banner-container'>
          <img src='https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/flowers.jpg?alt=media&token=6f1c3dee-b30d-4f28-b39a-e990b9e0dc6e' alt='' className='nature-img' />
          <nav className="nature-nav">
            <div className='logo'>
            <Link to='/'><img src="/logo.png" alt="Logo" /></Link>
            </div>
            <Link to='/now'>
            <div className='mb-2'>
              <button className='icon-button'>
                <IoMdHome size={15} />
              </button>
            </div>
          </Link>
          </nav>
          <div>
            <h1 className='banner-text'>Nature</h1>
          </div>
          <div className='container-list'>
            <div className='input-container'>
              <select value={country} onChange={handleCountryChange}>
                <option value="IN">India</option>
              </select>
            </div>
            <div className='input-container'>
        <select value={state} onChange={handleStateChange}>
          <option value="" disabled>State</option>
          {states.map(state => (
            <option key={state} value={state}>
              {state}
            </option>
          ))}
        </select>
      </div>
      <div className='input-container'>
        <select value={place} onChange={(e) => setPlace(e.target.value)}>
          <option value="" disabled>Place</option>
          {places.map(place => (
            <option key={place} value={place}>
              {place}
            </option>
          ))}
        </select>
      </div>
          </div>
          <div className='next-button-container'>
            <button className='icon-button' onClick={handleNextClick} disabled={!place}><FaArrowRight size={15}/></button>
          </div>
        </div>
        <div className='container'>
          <div className='row'>
            {natureTrendingPlaces.map((data, index) => (
              <div key={index} className='col-6 col-md-3 col-lg-3 col-xl-3 img-container'>
                <img src={data.imageUrl} alt='' className='package-img' />
                <Link to={`/nature/${data.state.replace(/\s+/g, '-')}/${data.trendingPlace.replace(/\s+/g, '-')}`}>
                  <button type='button' className='img-button'>{data.trendingPlace}</button>
                </Link>
              </div>
            ))}
            {natureWeekendPlaces.map((data, index) => (
              <div key={index} className='col-6 col-md-3 col-lg-3 col-xl-3 img-container'>
                <img src={data.imageUrl} alt='' className='package-img' />
                <Link to={`/nature/${data.state.replace(/\s+/g, '-')}/${data.trendingPlace.replace(/\s+/g, '-')}`}>
                  <button type='button' className='img-button'>{data.trendingPlace}</button>
                </Link>
              </div>
            ))}
            <button type='button' className='trending-button'>Trending</button>
            <button type='button' className='weekend-button'>Weekend Deals</button>
          </div>
        </div>
      </div>
    );
  }
  
  export default Nature;