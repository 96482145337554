import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import { toast } from "react-hot-toast";
import { collection, addDoc } from 'firebase/firestore';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import Select from 'react-select';
import { countries, getEmojiFlag } from 'countries-list';
import ClubHeader from '../ClubHeader2';
import ClubFooter from '../ClubFooter';
import './index.css';

const firebaseConfig = {
    apiKey: "AIzaSyB4BPMHKw0OXM2PhQX9NcT25lDBWpRpIPQ",
    authDomain: "phone-auth-d0a8d.firebaseapp.com",
    projectId: "phone-auth-d0a8d",
    storageBucket: "phone-auth-d0a8d.appspot.com",
    messagingSenderId: "309252587015",
    appId: "1:309252587015:web:3f9dc2505965e66269b4a2"
  };
  
  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
  }
  
  const db = firebase.firestore();

const ClubRegister = () => {
    const [formData, setFormData] = useState({
        title: '',
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        country: '',
        message: '',
        termsAccepted: false,
      });
      const [error, setError] = useState('');
      const [selectedCountry, setSelectedCountry] = useState(null);

      const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData((prev) => ({
          ...prev,
          [name]: type === 'checkbox' ? checked : value,
        }));
      };
    
      const handleSelectChange = (selectedOption) => {
        setFormData((prev) => ({ ...prev, country: selectedOption.label }));
        setSelectedCountry(selectedOption);
      };
    
      const handleSubmit = async (e) => {
        e.preventDefault();
        const { title, firstName, lastName, email, phone, country, termsAccepted } = formData;
    
        if (
          !title ||
          !firstName.trim() ||
          !lastName.trim() ||
          !email.trim() ||
          !phone.trim() ||
          !country.trim() ||
          !termsAccepted
        ) {
          toast.error('Please fill all required feilds');
          return;
        }
    
        try {
          await addDoc(collection(db, 'tu-club-submissions'), formData);
          setError('');
          setFormData({
            title: '',
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            country: '',
            message: '',
            termsAccepted: false,
          });
          setSelectedCountry(null);
          toast.success("Our team would get in touch with you and explain how TU Club works")
        } catch (error) {
          setError('Error submitting form, please try again.');
          toast.error("Please fill all required feilds")
        }
      };

      const countryOptions = Object.keys(countries).map((code) => ({
        value: code,
        label: `${countries[code].name} (${code})`,
        emoji: getEmojiFlag(code),
      }));
    
      const customStyles = {
        control: (base) => ({
          ...base,
          minHeight: '30px',
          borderRadius: '0px',
          border:'1px solid #f9f9f9',
          boxShadow: 'none',
          background:'wheat',
          color:'black',
          fontSize:'16px',
          fontFamily: 'Lora'
        }),
        option: (provided) => ({
          ...provided,
          padding: 0,
        }),
      };

    return (
      <>
      <ClubHeader />
      <div className='club-register-container'>
      <div className='club-register'>
          <h1>REGISTER TO RECEIVE BROCHURE</h1>
          <p>Please complete the short form below and a representative will be in touch to introduce the Club, discuss your travel requirements and talk through how Club membership works.</p>
          <form onSubmit={handleSubmit}>
              <div className='name-container'>
                  <select name="title" value={formData.title} onChange={handleChange} className='title' required>
                      <option value="" disabled>Title*</option>
                      <option value="Mr">Mr</option>
                      <option value="Mrs">Mrs</option>
                  </select>
                  <input type='text' name='firstName' className='first-name' value={formData.firstName} onChange={handleChange} placeholder='First Name*' required />
              </div>
              <div>
                <input type='text' name='lastName' value={formData.lastName} onChange={handleChange} placeholder='Last Name*' required />
              </div>
              <div>
                  <input type='email' name='email' value={formData.email} onChange={handleChange} className='tu-input' placeholder='Email*' required />
              </div>
              <div>
                  <input type='tel' name='phone' value={formData.phone} onChange={handleChange} className='tu-input' placeholder='Phone*' required />
              </div>
              <div>
                  <Select
                      options={countryOptions}
                      styles={customStyles}
                      value={selectedCountry}
                      onChange={handleSelectChange}
                      placeholder="Select Country of residence*"
                      isSearchable
                      required
                  />
              </div>
              <div style={{marginTop:'10px'}}>
                  <textarea name='message' value={formData.message} onChange={handleChange} className='tu-textarea' placeholder='Message' rows={5} />
              </div>
              <div className='checkbox-container'>
                  <input type='checkbox' name='termsAccepted' checked={formData.termsAccepted} onChange={handleChange} className='club-checkbox' required />
                  <p>I confirm, I have read and accept the <Link to="/terms" className='link'>Terms and Conditions</Link> and the <Link to="/privacy" className='link'>Website Privacy Policy</Link></p>
              </div>
              {error && <p style={{ color: 'red' }}>{error}</p>}
              <button type='submit' className='btn btn-success'>Register to TU Club</button>
          </form>
      </div>
      </div>
      <ClubFooter />
  </>
    );
}

export default ClubRegister;
