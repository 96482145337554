import React from 'react';
import ClubHeader from '../ClubHeader';
import ClubFooter from '../ClubFooter';
import Club3 from '../Club3';
import {Link} from 'react-router-dom';
import { FaArrowRightLong } from "react-icons/fa6";
import { SiOpenaccess } from "react-icons/si";
import { LuCrown } from "react-icons/lu";
import { TbBeach } from "react-icons/tb";
import './index.css';

const cardData = [
    {
      icon: SiOpenaccess,
      title: "Exclusive Access",
      content: "Gain entry to our curated portfolio of offbeat and meticulously maintained properties.",
    },
    {
      icon: LuCrown,
      title: "Fractional Ownership",
      content: "Enjoy the benefits of high-end real estate without the full financial commitment.",
    },
    {
      icon: TbBeach,
      title: "Flexible Holidays",
      content: "Experience yearly vacations at our select resorts and destinations with friends and family.",
    },
  ];


const ClubHome = () => {
    return (
        <>
            <ClubHeader/>
            <div>
                <img src='https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/tu-club%2FHome%20page%20image.jpg?alt=media&token=32b0660a-3c2c-4228-9256-64b7d82e056b' alt='' className='club-home-img'/>
            </div>
            <div className='club-home-section-1'>
                <h1>Welcome to the Exclusive TU Club</h1>
                <h4>Elevate Your Travel Experience</h4>
                <p>At TU Club, we cater exclusively to high-net-worth individuals who seek unparalleled
                luxury and unique experiences. Our club offers a unique co-ownership model that allows
                you to enjoy at a fraction of the cost of owning luxury resorts, cottages, and boutique home
                stays in some of the most exotic locations.</p>
            </div>
            <h1 className='text-center'>Membership Benefits</h1>
            <div className="home-cards-container">
            {cardData.map((card, index) => (
                <div className="home-card" key={index}>
                <div className='home-card-icon'><card.icon size={50}/></div>
                <div className='home-card-line'></div>
                <h3>{card.title}</h3>
                <p>{card.content}</p>
                </div>
            ))}
            </div>
            <div className='membership-text'>
            <p>Joining TU Club involves a one-time membership fee and a minimal annual fee,
            giving you access to extraordinary locations and a world of luxury at a fraction of
            the cost.</p>
            <p>Join us today and transform your travel dreams into reality.</p>
            </div>
            <div className='club-home-section-2 mt-5'>
            <div className='club-home-section-flex-1'>
                <h1 className='mt-3'>TU Club</h1>
                <hr style={{ width: '100%' }} />
                <ul>
                    <li>This exclusive TU Club is open for High Net Individuals only.</li>
                    <li>We offer Co - Ownership to you at just a fractional cost of the total cost of our well maintained resorts, cottages & boutique homestays, located at exotic locations.</li>
                    <li>With a one-time joining fee and a minimal annual fee, you can access our growing portfolio of offbeat real estates.</li>
                    <li>With a fractional cost, you can enjoy yearly holidays at our exotic, select resorts / destinations with your friends and family</li>
                </ul>
                <div className='explore-btn'>
               <Link to='/club-register'><button>Register to TU-Club <FaArrowRightLong style={{color:'brown'}}/></button></Link>
                </div>
            </div>
            <div className='club-home-section-flex-2'>
                <img src='https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/tu-club%2FTU%20CLUB%202nd%20page%20image.jpg?alt=media&token=8ed9f570-5758-4d4b-9b9b-d4a069acc2dc' alt='' className='club-home-section-img' />
            </div>
            </div>
            <div style={{marginTop:'100px', marginBottom:'100px'}}>
            <Club3/>
            </div>
            <div className='club-home-section-2'>
                <div className='club-home-section-flex-2'>
                <img src='https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/tu-club%2FTU%20CLUB%203rd%20page%20image.jpg?alt=media&token=ea3dfee3-6b07-4bc8-97a2-5a6be440bc00' alt='' className='club-home-section-img' />
                </div>
            <div className='club-home-section-flex-1'>
            <h1>Just for you..</h1>
            <hr style={{ width: '100%' }} />
            <ul>
                <li>Travel with your loved ones to our exotic destinations every year with just a fractional cost.</li>
                <li>If you can’t travel, transfer your shares to your friends.</li>
                <li>Make big profits through your shares when we trade these properties!</li>
            </ul>
                <div className='explore-btn'>
                    <Link to="/club-register"><button>Register to TU-Club <FaArrowRightLong style={{color:'brown'}}/></button></Link>
                </div>
            </div>
            </div>
            <ClubFooter/>
        </>
    );
}

export default ClubHome;
