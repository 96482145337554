import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { IoMdHome } from 'react-icons/io';
import './index.css';
import { toast, Toaster } from "react-hot-toast";
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import axios from 'axios';
import { useCallback } from 'react';
import { RxCross2 } from "react-icons/rx";


const firebaseConfig = {
    apiKey: "AIzaSyB4BPMHKw0OXM2PhQX9NcT25lDBWpRpIPQ",
    authDomain: "phone-auth-d0a8d.firebaseapp.com",
    projectId: "phone-auth-d0a8d",
    storageBucket: "phone-auth-d0a8d.appspot.com",
    messagingSenderId: "309252587015",
    appId: "1:309252587015:web:3f9dc2505965e66269b4a2"
};

if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
}


const NatureBooking = () => {
    const { state, place, hotelId } = useParams();
    const [price, setPrice] = useState('');
    const [user, setUser] = useState(null);
    const formattedPlace = place.replace(/-/g, ' ');
    const formattedState = state.replace(/-/g, ' ');
    const formattedHotelId = hotelId.replace(/-/g,' ');
    const [availability, setAvailability] = useState(null);
    const [hotel, setHotel] = useState([]);
    const [formData, setFormData] = useState({
        checkin: '',
        checkout: '',
        leadTravelerName: '',
        contact: '',
        email: '',

    });

    const [showPopup, setShowPopup] = useState(false);
    const [block, setBlock] = useState(false);
    const [discount, setDiscount] = useState('');
    const [withDiscount, setWithDiscount] = useState('');
    const [actual, setActual] = useState('');
    const [gst, setGst] = useState('');
    const [blockOption, setBlockOption] = useState(false);

    useEffect(() => {
        const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                setUser(user);
                fetchUserBooking(user.uid);
            } else {
                toast.success('Login NOW to keep a track of your bookings!')
            }
        });

        return () => unsubscribe();
    }, []);

    const [selectedRooms, setSelectedRooms] = useState([{ adults: 1, kids: 0, type: 'Single Occupancy' }]);
    const [isSaved, setIsSaved] = useState(false);
  
    const calculateRoomType = (adults, kids) => {
      const totalOccupants = adults + kids;
      if (totalOccupants === 1) {
        return 'Single Occupancy';
      } else if (totalOccupants === 2) {
        return 'Double/Twin sharing';
      } else if (totalOccupants === 3) {
        return 'Double + Extra bed';
      }
      return '';
    };
  
    const calculateRoomPrice = useCallback((roomType) => {
      switch (roomType) {
        case 'Single Occupancy':
          return parseInt(hotel.singleRoomCost);
        case 'Double/Twin sharing':
          return parseInt(hotel.price);
        case 'Double + Extra bed':
          return parseInt(hotel.extraBedCost);
        default:
          return 0;
      }
    }, [hotel.singleRoomCost, hotel.price, hotel.extraBedCost]);
  
    const handleAddRoom = () => {
      if (selectedRooms.length < availability) {
        setSelectedRooms(prevRooms => [...prevRooms, { adults: 1, kids: 0, type: 'Single Occupancy' }]);
      } else {
        alert('Cannot add more rooms. Availability exceeded.');
      }
    };
  
    const handleRemoveRoom = (index) => {
      if (selectedRooms.length > 1) {
        const updatedRooms = [...selectedRooms];
        updatedRooms.splice(index, 1);
        setSelectedRooms(updatedRooms);
      }
    };
  
    const handleRoomChange = (index, type, value) => {
      const updatedRooms = [...selectedRooms];
      updatedRooms[index][type] = parseInt(value);
  
      const { adults, kids } = updatedRooms[index];
      updatedRooms[index].type = calculateRoomType(adults, kids);
  
      if (type === 'adults' && adults === 3) {
        updatedRooms[index].kids = 0;
      }
  
      setSelectedRooms(updatedRooms);
    };

   
      const handleSaveRooms = () => {
        setSelectedRooms(selectedRooms);
        setIsSaved(true);
        setShowPopup(false);
      };
    
      const handleOpenPopup = () => {
        setShowPopup(true);
      };

    const handleGoogleLogin = async () => {
        const provider = new firebase.auth.GoogleAuthProvider();
        try {
            await firebase.auth().signInWithPopup(provider);
            console.log('Logged in with Google successfully!');
            toast.success('Logged in with Google Successfully!')
        } catch (error) {
            console.error('Error signing in with Google:', error);
        }
    };

    const fetchUserBooking = async (userId) => {
        try {
            const bookingSnapshot = await firebase.firestore().collection('bookings')
                .where('userId', '==', userId)
                .limit(1)
                .get();

            if (!bookingSnapshot.empty) {
                const bookingData = bookingSnapshot.docs[0].data();
                setFormData((prevFormData) => ({
                    ...prevFormData,
                    leadTravelerName: bookingData.leadTravelerName || '',
                    email: bookingData.email || '',
                    contact: bookingData.contact || '',
                }));
            }
        } catch (error) {
            console.error('Error fetching user booking:', error);
        }
    };

    useEffect(() => {
        const fetchHotel = async () => {
            try {
                const hotelSnapshot = await firebase.firestore().collection('OnenessDestinations')
                    .doc(formattedState).collection(formattedState).doc(formattedPlace)
                    .collection('hotels').doc(hotelId).get();
                if (hotelSnapshot.exists) {
                    setHotel({ id: hotelSnapshot.id, ...hotelSnapshot.data() });
                } else {
                    console.log('Hotel not found');
                }
            } catch (error) {
                console.error('Error fetching hotel: ', error);
            }
        };

        fetchHotel();
    }, [formattedState, formattedPlace, hotelId]);
    
    const handleBlockClick = () => {
        setBlockOption(true);
      };
    
      const handlePayNowClick = () => {
        setBlockOption(false);
      };
    const generateBookingId = () => {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        const length = 8;
        let bookingId = '';
        for (let i = 0; i < length; i++) {
            bookingId += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        return bookingId;
    };

    const formatDate = (date) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
  };

  const handlePayment = async () => {
    if (availability <= 0) {
        toast.error('Please change Checkin and Checkout and try again');
        return;
    }

    try {
        const response = await axios.post('https://formbackend.netlify.app/payment', {
            amount: blockOption ? price * 10.0 : withDiscount * 100,
          });
          const { data } = response;

          const options = {
            key: 'rzp_test_2U3wItuISJsrjG',
            amount: data.amount,
            currency: 'INR',
            name: 'Travel Unbounded',
            description: 'Test Transaction',
            order_id: data.id,
            handler: async function (response) {
                const paymentId = response.razorpay_payment_id;
                try {
                    let userId = null;
                    if (user) {
                        userId = user.uid;
                    }
                    const bookingId = generateBookingId();

                    const bookingRef = await firebase.firestore().collection('bookings').add({
                        userId: userId,
                        category:'Oneness',
                        categoryPath:'OnenessDestinations',
                        state: state,
                        place: place,
                        hotelName: hotelId,
                        bookingId: bookingId,
                        paymentId: paymentId,
                        checkin: formData.checkin,
                        checkout: formData.checkout,
                        leadTravelerName: formData.leadTravelerName,
                        contact: formData.contact,
                        email: formData.email,
                        totalPrice: blockOption ? price * 10.0 : withDiscount,
                        hotelMail:hotel.hotelMail,
                        numRooms: selectedRooms.length,
                        numAdults:totalAdults,
                        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
                        numKids:totalKids
                    });
                    console.log('Booking added with ID:', bookingRef.id);
                    updateAvailability(formData.checkin, formData.checkout, formData.numRooms);
                    try {
                        if (user) {
                        const response = await axios.post('https://formbackend.netlify.app/sendEmail', {
                          email: formData.email,
                          hotelMail: hotel.hotelMail,
                          bookingDetails: {
                            name:formData.leadTravelerName,
                            email:formData.email,
                            contact:formData.contact,
                            category:'Oneness',
                            state: state,
                            place: place,
                            hotelId: hotelId,
                            bookingId: bookingId,
                            paymentId: paymentId,
                            checkin: formData.checkin,
                            checkout: formData.checkout,
                            totalPrice: blockOption ? price * 10.0 : withDiscount,
                            hotelMail:hotel.hotelMail,
                            timestamp: firebase.firestore.FieldValue.serverTimestamp(),
                            numRooms: selectedRooms.length,
                            numAdults:totalAdults,
                            numKids:totalKids
                          }
                        });
                        console.log(response.data);
                         } else {
                          const response = await axios.post('https://formbackend.netlify.app/sendEmailGuest', {
                          email: formData.email,
                          hotelMail: hotel.hotelMail,
                          bookingDetails: {
                            name:formData.leadTravelerName,
                            email:formData.email,
                            contact:formData.contact,
                            category:'Oneness',
                            state: state,
                            place: place,
                            hotelId: hotelId,
                            bookingId: bookingId,
                            paymentId: paymentId,
                            checkin: formData.checkin,
                            checkout: formData.checkout,
                            totalPrice: blockOption ? price * 10.0 : withDiscount,
                            hotelMail:hotel.hotelMail,
                            timestamp: firebase.firestore.FieldValue.serverTimestamp(),
                            numRooms: selectedRooms.length,
                            numAdults:totalAdults,
                            numKids:totalKids
                          }
                        });
                        console.log(response.data);
                          }
                    
                      } catch (error) {
                        console.error('Error sending email:', error);
                      };
                    setFormData({
                        checkin: '',
                        checkout: '',
                        leadTravelerName: '',
                        contact: '',
                        email: '',
                    });
                    setPrice('');
                    setAvailability(null);
                    setIsSaved(false);
                    toast.success('Booking successful!');
                } catch (error) {
                    console.error('Error adding booking:', error);
                    toast.error('Error adding booking. Please try again later.');
                }
            },
            prefill: {
              name: formData.name,
              email: formData.email,
              contact: formData.contact,
            },
            theme: {
              color: 'rgb(137, 66, 179)',
            },
          };
          const rzp = new window.Razorpay(options);
          rzp.open();
        } catch (error) {
          console.error('Error while processing payment:', error);
        }
      };

const handleSubmit = async (e) => {
    e.preventDefault();
    handlePayment();
};

const updateAvailability = async (checkin, checkout) => {
    try {
        const startDate = new Date(checkin);
        const endDate = new Date(checkout);
        const datesInRange = getDatesInRange(startDate, endDate);

        for (const date of datesInRange) {
            const formattedDate = formatDate(date);
            const docRef = firebase.firestore().collection('OnenessDestinations')
                .doc(formattedState)
                .collection(formattedState)
                .doc(formattedPlace)
                .collection('hotels')
                .doc(formattedHotelId)
                .collection('availabilities')
                .doc('dates');

            await firebase.firestore().runTransaction(async (transaction) => {
                const doc = await transaction.get(docRef);
                if (!doc.exists) {
                    console.error('Availability document does not exist');
                    return;
                }
                const availabilityData = doc.data();
                const updatedAvailability = availabilityData[formattedDate] - parseInt(selectedRooms.length);
                if (updatedAvailability >= 0) {
                    transaction.update(docRef, { [formattedDate]: updatedAvailability });
                } else {
                    console.error('Not enough rooms available on date:', formattedDate);
                }
            });
        }
    } catch (error) {
        console.error('Error updating availability:', error);
    }
};

const handleInputChange = (e) => {
    const { name, value } = e.target;
    let selectedRooms = 1;
    const totalOccupants = parseInt(formData.numAdults || 0) + parseInt(formData.numKids || 0);
    if (name === 'roomType' || name === 'numAdults' || name === 'numKids') {
        if (value === 'Double/Twin sharing') {
            selectedRooms = Math.ceil(totalOccupants / 2);
        } else if (value === 'Double + Extra bed') {
            selectedRooms = Math.ceil(totalOccupants / 3);
        }
        else if (value === 'Single Occupancy') {
            selectedRooms = Math.ceil(totalOccupants / 1);
        }
        setFormData({ ...formData, numRooms: selectedRooms.toString(), [name]: value });
    } else if (name === 'checkin') {
        const checkinDate = new Date(value);
        const nextDay = new Date(checkinDate);
        nextDay.setDate(checkinDate.getDate() + 1);
        setFormData({ ...formData, checkout: nextDay.toISOString().split('T')[0], [name]: value });
        const selectedDate = new Date(value);
            const today = new Date();
            const thirtyDaysFromNow = new Date(today.setDate(today.getDate() + 30));
            setBlock(selectedDate > thirtyDaysFromNow);
    } else {
        setFormData({ ...formData, [name]: value });
    }
};
useEffect(() => {
    const fetchData = async () => {
        try {
            if (!formData.checkin || !formData.checkout) {
                setAvailability('');
                return;
            }
            const startDate = new Date(formData.checkin);
            const endDate = new Date(formData.checkout);
            const datesInRange = getDatesInRange(startDate, endDate);

            let availabilityArray = [];

            for (let date of datesInRange) {
                const formattedDate = formatDate(date);
                const docSnapshot = await firebase.firestore().collection('OnenessDestinations')
                    .doc(formattedState).collection(formattedState).doc(formattedPlace)
                    .collection('hotels').doc(formattedHotelId)
                    .collection('availabilities').doc('dates')
                    .get();

                if (docSnapshot.exists) {
                    const data = docSnapshot.data();
                    const availability = data[formattedDate] || 0;
                    availabilityArray.push(availability);
                } else {
                    availabilityArray.push(0);
                }
            }
            const minAvailability = Math.min(...availabilityArray);
            setAvailability(minAvailability);
        } catch (error) {
            console.error('Error fetching availability from Firestore:', error);
            setAvailability('Error');
        }
    };

    fetchData();
}, [formData.checkin, formData.checkout, formattedHotelId, formattedState, formattedPlace]);

    

    const getDatesInRange = (startDate, endDate) => {
        const dates = [];
        for (let date = new Date(startDate); date <= endDate; date.setDate(date.getDate() + 1)) {
            dates.push(new Date(date));
        }
        return dates;
    };
 
    const minDate = new Date();
    const maxDate = new Date();
    minDate.setDate(minDate.getDate() + 7);
    maxDate.setDate(maxDate.getDate() + 8);

    const handleClose = () => {
        setShowPopup(false);
    }

    useEffect(() => {
        let totalPrice = 0;

        selectedRooms.forEach((room) => {
            totalPrice += calculateRoomPrice(room.type);
        });
        
        let gstRate = 0;
        if (totalPrice <= 7500) {
            gstRate = 0.12;
        } else {
            gstRate = 0.18;
        }

        const checkinDate = new Date(formData.checkin);
            const checkoutDate = new Date(formData.checkout);
            const timeDifference = checkoutDate.getTime() - checkinDate.getTime();
            const daysDifference = Math.ceil(timeDifference / (1000 * 3600 * 24));

        const rate = totalPrice * daysDifference;
        setActual(rate);
        const gstAmount = rate * gstRate;
        setGst(gstAmount);
        const discount = rate * 0.05;
        setDiscount(discount);
        const withDiscountAmount = (rate - discount) + gstAmount;
        setWithDiscount(withDiscountAmount);
        const withGst = rate + gstAmount;

        setPrice(withGst.toString());

    }, [selectedRooms, calculateRoomPrice, formData.checkin, formData.checkout]);

    const totalAdults = selectedRooms.reduce((total, room) => total + Number(room.adults), 0);
    const totalKids = selectedRooms.reduce((total, room) => total + Number(room.kids), 0);

    return (
        <>
            <Toaster toastOptions={{ duration: 5000 }} style={{fontStyle:'Canva Sans',color:'black'}} />
            <nav className="nature-nav mb-5">
                <div className="logo">
                <Link to='/'><img src="/logo.png" alt="Logo" style={{filter:'brightness(100%)'}} /></Link>
                </div>
                <Link to="/now">
                    <button className="icon-button">
                        <IoMdHome size={15} />
                    </button>
                </Link>
            </nav>
            <h4 className="text-center footer-text mt-5">{place}</h4>
            <h2 className="text-center footer-text">{hotelId.toUpperCase()}</h2>
            <form onSubmit={handleSubmit}>
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-4 col-lg-4 col-xl-4 mb-3">
                    <label>Name of the leadTraveler</label>
                        <input
                            type="text"
                            name="leadTravelerName"
                            value={formData.leadTravelerName}
                            onChange={handleInputChange}
                            className="form-control"
                            placeholder="Name Of Lead Traveler"
                        />
                    </div>
                    <div className="col-6 col-md-4 col-lg-4 col-xl-4 mb-3">
                    <label>Whatsapp Number</label>
                        <input
                            type="text"
                            name="contact"
                            value={formData.contact}
                            onChange={handleInputChange}
                            className="form-control"
                            placeholder="Contact"
                        />
                    </div>
                    <div className="col-6 col-md-4 col-lg-4 col-xl-4 mb-3">
                    <label>Email</label>
                        <input
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleInputChange}
                            className="form-control"
                            placeholder="Email"
                        />
                    </div>
                    <div className="col-6 col-md-4 col-lg-4 col-xl-4 mb-3">
                        <label>Checkin Date</label>
                        <input
                            type="date"
                            name="checkin"
                            value={formData.checkin}
                            min={minDate.toISOString().split('T')[0]}
                            onChange={handleInputChange}
                            className="form-control"
                            placeholder='checkin'
                        />
                    </div>
                    <div className="col-6 col-md-4 col-lg-4 col-xl-4 mb-3">
                        <label>Checkout Date</label>
                        <input
                            type="date"
                            name="checkout"
                            value={formData.checkout}
                            min={maxDate.toISOString().split('T')[0]}
                            onChange={handleInputChange}
                            className="form-control"
                            placeholder='checkout'
                        />
                    </div>
                    <div className="col-12 col-md-4 col-lg-4 col-xl-4">
                        <label>Rooms & Guests</label>
                        <div onClick={handleOpenPopup} className="btn btn-success" style={{width:'100%',background:'#ccc', border:'none', color:'black'}}>
                        Select Rooms & Guests
                        </div>
                    </div>
                    <div className="col-12 col-md-4 col-lg-4 col-xl-4 font mb-3" style={{lineHeight:'50%'}}>
                    {availability !== null ? (
                            <p style={{color:'green', fontWeight:'bold', fontFamily:'Canva Sans'}} className='mt-2'>Available Rooms: {availability}</p>
                        ) : (
                            <p>No availability</p>
                        )}
                    {isSaved && (
                        <div>
                        <p style={{fontWeight:'bold'}} className='mt-4'>Selected Rooms Info</p>
                        <p className='mt-3'>Total Guests: {totalAdults} adults, {totalKids} kids</p>
                        {selectedRooms.map((room, index) => (
                            <div key={index} className="">
                            <p>Room {index + 1} : {room.adults} adults, {room.kids} kids, {room.type}</p>
                            </div>
                        ))}
                        </div>
                    )}  
                    </div>
                   {block && isSaved &&<div className="col-12 col-md-4 col-lg-4 col-xl-4 mb-3">
                    <p style={{fontWeight:'bold'}} className='font'>Block NOW Price Calculation</p>
                    <div className="font" style={{lineHeight:'50%'}}>
                            <div className='space-between mt-3' style={{marginRight:'20px'}}>
                            <p>Room Price</p>
                            <p>₹{actual}</p>
                            </div>
                            <div className='space-between' style={{marginRight:'20px'}}>
                            <p>GST</p>
                            <p>₹{gst}</p>
                            </div>
                            <div className='space-between' style={{fontWeight:'bold',color:'green', marginRight:'20px'}}>
                            <p>Total Price including GST</p>
                            <p>₹{price}</p>
                            </div>
                            <div className='space-between' style={{marginRight:'20px',color:'green',fontWeight:'bold'}}>
                            <p>Block NOW to pay 10% as upfront</p>
                            <p>₹{price * 0.10}</p>
                            </div>
                            <div className='space-between' style={{marginRight:'20px'}}>
                            <p>Amount pay before 10 days to checkin</p>
                            <p>₹{price - price * 0.10}</p>
                            </div>
                            <p>*Block NOW amount is non refundable</p>
                        </div>
                    </div>
                    }
                    <div className="col-12 col-md-4 col-lg-4 col-xl-4">
                        {isSaved &&
                        <div>
                         <p style={{fontWeight:'bold'}} className='font'>Pay NOW Price Calculation</p>
                        <div className="font" style={{lineHeight:'50%'}}>
                            <div className='space-between' style={{marginRight:'20px'}}>
                            <p>Room Price</p>
                            <p>₹{actual}</p>
                            </div>
                            <div className='space-between' style={{marginRight:'20px'}}>
                            <p>GST</p>
                            <p>₹{gst}</p>
                            </div>
                            <div className='space-between' style={{fontWeight:'bold',color:'green', marginRight:'20px'}}>
                            <p>Total Price including GST</p>
                            <p>₹{price}</p>
                            </div>
                            <div className='space-between' style={{marginRight:'20px'}}>
                            <p>Pay Now to get 5% Discount</p>
                            <p>- ₹{discount}</p>
                            </div>
                            <div className='space-between' style={{marginRight:'20px'}}>
                            <p style={{fontWeight:'bold',color:'green'}}>Pay Now</p>
                            <p style={{fontWeight:'bold',color:'green'}}>₹{price - discount}</p>
                            </div>
                        </div>
                        </div>
                    }  
                    </div>
                    <div className="col-12 col-md-12 col-lg-12 col-xl-12 text-center">
                        <button
                            type="submit"
                            className="btn btn-success"
                            style={{ width: '100%' }}
                            onClick={handlePayNowClick}
                            disabled={!isSaved}
                        >
                            Pay NOW and avail 5% discount
                        </button>
                    </div>
                    {block && <div className="col-12 col-md-12 col-lg-12 col-xl-12 mt-3 text-center">
                        <button
                            type="submit"
                            className="btn pay-button"
                            style={{ width: '100%' }}
                            onClick={handleBlockClick}
                            disabled={!isSaved}
                        >
                           Block NOW with just paying 10% of the total cost, balance 10 days prior to travel dates
                        </button>
                    </div>
                    }
                </div> 
            </div>
            </form>
            <div className='text-center'>
                <button onClick={handleGoogleLogin} type='submit' className='btn-google mt-4' style={{ paddingTop: '5px', color:'white', border:'none' }}>
                    <img src='/home/google.png' alt='' className='google' style={{background:'white', padding:'5px'}}/> Signup with Google</button>
            </div>
            <div className='privacy-terms mt-4'>
                <Link to='/client-terms'><p>Terms & Conditions | Client</p></Link>
                <p>@2024 all rights reserved to Travel Unbounded | NOW</p>
            </div>   
            {formData.checkin && formData.checkout && showPopup && (
            <div className="container-popup">
            <div className='close-button-container'>
            <RxCross2 size={30} onClick={handleClose} style={{cursor:'pointer',fontWeight:'bold'}}/>
            </div>
            <h3 className='font text-center' style={{fontWeight:'bold', color:'green'}}>Select Rooms and Guests</h3>
            <div className="popup-inner">
            <h5 className='font' style={{color:'green'}}>Available Rooms: {availability}</h5>
            <h5 className='font' style={{color:'red'}}>Selected Rooms  : {selectedRooms.length}</h5>
            <div className='mb-2'>
            <button onClick={handleAddRoom} className='btn btn-success' style={{width:'150px', marginRight:'10px'}} disabled={selectedRooms.length >= availability}>
                Add Room
            </button>
            <button onClick={handleSaveRooms} className='btn btn-primary' style={{width:'150px'}}>Save Changes</button>
            </div>
            <div className="rooms-container font">
                {selectedRooms.map((room, index) => (
                <div key={index} className="room-card">
                    <p className='bold'>Room {index + 1}</p>
                    <div className="room-inputs">
                    <label htmlFor={`adults${index}`}>Adults: </label>
                    <select
                        id={`adults${index}`}
                        value={room.adults}
                        onChange={(e) => handleRoomChange(index, 'adults', e.target.value)}
                    >
                        {[1, 2, 3].map((num) => (
                        <option key={num} value={num}>{num}</option>
                        ))}
                    </select>
                    <label htmlFor={`kids${index}`}>Kids(above 5 years):</label>
                    <select
                        id={`kids${index}`}
                        value={room.kids}
                        onChange={(e) => handleRoomChange(index, 'kids', e.target.value)}
                        disabled={room.adults === 3} 
                    >
                        {[...(room.adults === 1 ? [0, 1, 2] : room.adults === 2 ? [0, 1] : [0])].map((num) => (
                        <option key={num} value={num}>{num}</option>
                        ))}
                    </select>
                    </div>
                    <p>Room Type: {room.type}</p>
                    <p>Price: ₹{calculateRoomPrice(room.type)}</p>
                    <div className='remove-btn-container'>
                    {index > 0 && <button onClick={() => handleRemoveRoom(index)} className='remove-btn mb-2'>Remove</button>}
                </div>
                </div>
                ))}
            </div>
            </div>
        </div>
        )}  
        </>
    );
};

export default NatureBooking;
