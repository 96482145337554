import React from 'react';
import './index.css';
import {Link} from 'react-router-dom';

const NowNav = () => {

    return (
      <div className='navbar'>
        <div className='container'>
          <div className="logo">
          <Link to='/'><img src="/logo.png" alt="Logo" style={{filter:'brightness(100%)'}} /></Link>
          </div>
        </div>
      </div>
    );
}

export default NowNav;

