import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {IoMdHome} from 'react-icons/io';
import { toast, Toaster } from "react-hot-toast";
import './index.css';

const ContactLeisure = () => {
  const [formData, setFormData] = useState({
    name: '', 
    people:'',
    kids:'',
    adults:'',
    where:'',
    when:'',
    days:'',
    email:'',
    contact:''
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const {
        name,
        people,
        kids,
        adults,
        where,
        when,
        days,
        email,
        contact,
      } = formData;

      if (
        !name ||
        !people ||
        !kids ||
        !adults ||
        !where ||
        !when ||
        !days ||
        !email ||
        !contact
      ) {
        toast.error("Please fill in all fields.");
        return;
      }

      const response = await fetch("https://formbackend.netlify.app/contact-leisure", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name,
          people,
          kids,
          adults,
          where,
          when,
          days,
          email,
          contact,
        }),
      });
      const data = await response.json();
      console.log(data.message);
      toast.success("Our team will get back to you soon!");
      setFormData({
        name: "",
        people:"",
        kids:"",
        adults:"",
        where:"",
        when:"",
        days:"",
        email:"",
        contact:"",
      });
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  return (
    <>
        <nav className="nature-nav mb-5">
        <div className='logo'>
        <Link to='/'><img src="/logo.png" alt="Logo" style={{filter:'brightness(100%)'}} /></Link>
        </div>
        <Link to='/now'><div className='mb-2'><button className='icon-button'><IoMdHome size={15}/></button></div></Link> 
    </nav>
    <div className='contact-font mb-2'>
      <h4 className='text-center' style={{fontSize:'12px', fontFamily:'Canva Sans', fontWeight:'bold',padding:'5px'}}>For Liesure groups</h4>
    </div>
    <h2 className='contact-font-bold bold mb-2'>Contact us to curate a personalised journey for you</h2>
    <div className="container">
      <div className="row">
        <div className="col-10 col-md-6 col-lg-6 col-xl-6">
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            className="form-control contact-input"
            placeholder="What's your name"
            required
          />
        </div>
        <div className="col-10 col-md-6 col-lg-6 col-xl-6">
          <input
            type="text"
            name="people"
            value={formData.people}
            onChange={handleInputChange}
            className="form-control contact-input"
            placeholder="How many of you want to travel"
            required
          />
        </div>
        <div className="col-10 col-md-6 col-lg-6 col-xl-6">
          <input
            type="text"
            name="kids"
            value={formData.kids}
            onChange={handleInputChange}
            className="form-control contact-input"
            placeholder="How many of you are below 6 years"
            required
          />
        </div>
        <div className="col-10 col-md-6 col-lg-6 col-xl-6">
          <input
            type="text"
            name="adults"
            value={formData.adults}
            onChange={handleInputChange}
            className="form-control contact-input"
            placeholder="How many of you are above 6 years"
            required
          />
        </div>
        <div className="col-10 col-md-6 col-lg-6 col-xl-6">
          <input
            type="text"
            name="where"
            value={formData.where}
            onChange={handleInputChange}
            className="form-control contact-input"
            placeholder="Where do you want to travel"
            required
          />
        </div>
        <div className="col-10 col-md-6 col-lg-6 col-xl-6">
          <input
            type="text"
            name="when"
            value={formData.when}
            onChange={handleInputChange}
            className="form-control contact-input"
            placeholder="When do you want to travel"
            required
          />
        </div>
        <div className="col-10 col-md-6 col-lg-6 col-xl-6">
          <input
            type="text"
            name="days"
            value={formData.days}
            onChange={handleInputChange}
            className="form-control contact-input"
            placeholder="How many days you want to travel"
            required
          />
        </div>
        <div className="col-10 col-md-6 col-lg-6 col-xl-6">
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            className="form-control contact-input"
            placeholder="Can you provide your email ID"
            required
          />
        </div>
        <div className="col-10 col-md-6 col-lg-6 col-xl-6 mb-3">
          <input
            type="phone"
            name="contact"
            value={formData.contact}
            onChange={handleInputChange}
            className="form-control contact-input"
            placeholder="Can you provide your contact number"
            required
          />
        </div>
        <div className="col-12 col-md-12 col-lg-12 col-xl-12 text-center">
          <button
            type="submit"
            onClick={handleSubmit}
            className="btn btn-primary pay-button"
            style={{width:'150px'}}
          >
            Submit
          </button>
        </div>
        <Toaster toastOptions={{ duration: 5000 }} style={{fontStyle:'Canva Sans',color:'black'}}/>
      </div>
    </div>
    </>
  );
};

export default ContactLeisure;
