import React from 'react';
import NewsCard from '../NewsCard';
import ClubHeader from '../ClubHeader2';
import ClubFooter from '../ClubFooter';
import './index.css';

const newsData = [
  {
    image: '/club-hero.avif',
    title: 'The Sunday Times with Tim Henman',
    date: '4th August, 2024',
    description: 'The Times features why Tim Henman invested with The Hideaways Club and how ...',
  },
  {
    image: '/tu-6.avif',
    title: 'The Journal Summer 2024',
    date: '8th July, 2024',
    description: 'We are delighted to present the summer edition of The Journal. In this ...',
  },
  {
    image: '/tu-7-1.avif',
    title: 'From Kalkan to Canyon',
    date: '4th July, 2024',
    description: 'Just a 30-minute drive from the charming town of Kalkan lies Saklikent National ...',
  },
  {
    image: '/club-hero.avif',
    title: 'The Sunday Times with Tim Henman',
    date: '4th August, 2024',
    description: 'The Times features why Tim Henman invested with The Hideaways Club and how ...',
  },
  {
    image: '/tu-6.avif',
    title: 'The Journal Summer 2024',
    date: '8th July, 2024',
    description: 'We are delighted to present the summer edition of The Journal. In this ...',
  },
  {
    image: '/tu-7-1.avif',
    title: 'From Kalkan to Canyon',
    date: '4th July, 2024',
    description: 'Just a 30-minute drive from the charming town of Kalkan lies Saklikent National ...',
  },
];

function ClubNews() {
  return (
    <>
    <ClubHeader/>
    <div className="news-container">
      {newsData.map((news, index) => (
        <NewsCard
          key={index}
          image={news.image}
          title={news.title}
          date={news.date}
          description={news.description}
        />
      ))}
    </div>
    <ClubFooter/>
    </>
  );
}

export default ClubNews;
