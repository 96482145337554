import React, { useState, useEffect } from 'react';
import MobileNav from '../MobileNav';
import './index.css';

const getImagePaths = () => {
    const images = [];
    for (let i = 1; i <= 12; i++) {
        images.push(`/home/img-${i}.jpg`);
    }
    return images;
};

const MobileHome = () => {
    const [revealedIndex, setRevealedIndex] = useState(-1);
    const [stopAnimations, setStopAnimations] = useState(false);
    const imagePaths = getImagePaths();

    useEffect(() => {
        if (revealedIndex < imagePaths.length - 1 && !stopAnimations) {
            const timeout = setTimeout(() => {
                setRevealedIndex(prevIndex => prevIndex + 1);
            }, 100);

            return () => clearTimeout(timeout);
        } else if (revealedIndex === imagePaths.length - 1) {
            const stopTimeout = setTimeout(() => {
                setStopAnimations(true);
                const resetTimeout = setTimeout(() => {
                    setStopAnimations(false);
                }, 2000);
                return () => clearTimeout(resetTimeout);
            }, 4000);
            return () => clearTimeout(stopTimeout);
        }
    }, [revealedIndex, stopAnimations, imagePaths.length]);

    return (
        <>
            <MobileNav />
            <div className="container">
                <div className="row">
                    {imagePaths.map((path, index) => (
                        <div key={index} className="col-4 col-md-4 col-lg-4 col-xl-4 photo-fade">
                            <img
                                src={path}
                                alt={`slide ${index + 1}`}
                                className={`home-img ${index <= revealedIndex ? 'neon blink' : ''}`}
                                style={{ visibility: index <= revealedIndex || stopAnimations ? 'visible' : 'hidden' }}
                                onLoad={() => {
                                    if (index === revealedIndex + 1) {
                                        setRevealedIndex(index);
                                    }
                                }}
                            />
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

export default MobileHome;
