import React, { useState, useEffect } from 'react';
import './index.css';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

const AllBookings = () => {
    const [bookings, setBookings] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredBookings, setFilteredBookings] = useState([]);

    useEffect(() => {
        const fetchBookings = async () => {
            const bookingsSnapshot = await firebase.firestore().collection('bookings').get();
            const bookingsData = bookingsSnapshot.docs.map(doc => doc.data());
        
            bookingsData.forEach(booking => {
                booking.checkinDate = new Date(booking.checkin);
            });

            bookingsData.sort((a, b) => b.checkinDate - a.checkinDate);
            
            bookingsData.forEach(booking => {
                delete booking.checkinDate;
            });

            setBookings(bookingsData);
            setFilteredBookings(bookingsData);
        };

        fetchBookings();
    }, []);

    useEffect(() => {
        const filtered = bookings.filter(booking => 
            booking.bookingId.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredBookings(filtered);
    }, [searchTerm, bookings]);

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    return (
        <div className="all-bookings-container">
            <div className="search-bar-container">
                <input 
                    type="text" 
                    placeholder="Search by Booking ID" 
                    value={searchTerm}
                    onChange={handleSearchChange}
                />
            </div>
            <div className="table-container">
                <table className="bookings-table">
                    <thead>
                        <tr>
                            <th>Booking ID</th>
                            <th>Payment ID</th>
                            <th>Cancelled</th>
                            <th>Category</th>
                            <th>State</th>
                            <th>Place</th>
                            <th>Hotel Name</th>
                            <th>Traveler Name</th>
                            <th>Check-in</th>
                            <th>Check-out</th>
                            <th>Number of Adults</th>
                            <th>Number of Kids</th>
                            <th>Number of Rooms</th>
                            <th>Room Type</th>
                            <th>Contact</th>
                            <th>Email</th>
                            <th>Amount paid</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredBookings.map((booking, index) => (
                            <tr key={index}>
                                <td>{booking.bookingId}</td>
                                <td>{booking.paymentId}</td>
                                <td>{booking.cancelled ? 'Yes' : 'No'}</td>
                                <td>{booking.category}</td>
                                <td>{booking.state}</td>
                                <td>{booking.place}</td>
                                <td>{booking.hotelName}</td>
                                <td>{booking.leadTravelerName}</td>
                                <td>{booking.checkin}</td>
                                <td>{booking.checkout}</td>
                                <td>{booking.numAdults}</td>
                                <td>{booking.numKids}</td>
                                <td>{booking.numRooms}</td>
                                <td>{booking.roomType}</td>
                                <td>{booking.contact}</td>
                                <td>{booking.email}</td>
                                <td>{booking.totalPrice}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default AllBookings;
