import React from 'react';
import {Link} from 'react-router-dom';
import { IoMdHome } from "react-icons/io";
import { CiMail } from "react-icons/ci";
import { IoCallOutline } from "react-icons/io5";
import { FaWhatsapp } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";
import { BsTerminalDash } from "react-icons/bs";
import './index.css';

const Contact = () => {
    return (
        <div className='contact-container'>
            <div className='container'>
                <div className="logo contact-nav">
                <Link to='/'><img src="/logo.png" alt="Logo" style={{filter:'brightness(100%)'}} /></Link>
                    <Link to='/now'><div className='mb-2'><button className='icon-button'><IoMdHome size={15}/></button></div></Link> 
                </div>
                <div>
                    <h1 className='text-center contact-head mb-5'>Contact US</h1>
                </div>
                <div className='icon-text-container'>
                <div className='icon-text mb-3'>
                <a href="mailto:support@travelunbounded.com"><p><button className='icon mail'><CiMail/></button>support@travelunbounded.com</p></a>
                </div>
                <div className='icon-text mb-3'>
                <a href="tel:+919845090014"><p><button className='icon call'><IoCallOutline /></button> +91 9845090014</p></a>
                </div>

               <div className='icon-text mb-3'>
               <a href="https://wa.me/9663579014" target="__blank">  <p><button className='icon whatsapp'><FaWhatsapp /></button> +91 9845090014</p></a>
                </div>
               <div className='icon-text mb-3'>
               <a
                    href="https://www.instagram.com/travel_ub/"
                    target="__blank"
                  ><p><button className='icon insta'><FaInstagram /></button> @travel_ub</p></a>
                </div>
                <div className='icon-text mb-3'>
                <a
                    href="https://www.facebook.com/travelunboundedcom"
                    target="__blank"
                  ><p><button className='icon fb'><FaFacebookF /></button> Travel Unbounded</p></a>
                </div>
                </div>
                <div className='address'>
                    <h4 className='mb-3'>Offices:</h4>
                    <p>Reg- Bangalore</p>
                    <p>Branches - Goa, Karnataka, Tamil Nadu, Nairobi</p>
                </div>
                <div className='privacy icon-text'>
                   <Link to='/terms'> <p><BsTerminalDash size={20}/> Terms & Conditions </p></Link> <Link to='/privacy'><p> | Privacy Policy</p></Link>
                </div>
                <p className='text-center font'>@2024 all rights reserved to Travel Unbounded | NOW</p>
            </div>
        </div>
    );
}

export default Contact;
