import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { IoMdHome } from "react-icons/io";
import firebase from "firebase/compat/app";
import { FaArrowRight } from "react-icons/fa";
import "firebase/compat/firestore";
import './index.css';

const firebaseConfig = {
    apiKey: "AIzaSyB4BPMHKw0OXM2PhQX9NcT25lDBWpRpIPQ",
    authDomain: "phone-auth-d0a8d.firebaseapp.com",
    projectId: "phone-auth-d0a8d",
    storageBucket: "phone-auth-d0a8d.appspot.com",
    messagingSenderId: "309252587015",
    appId: "1:309252587015:web:3f9dc2505965e66269b4a2"
};

if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
}

const db = firebase.firestore();

const Packages = () => {
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [place, setPlace] = useState('');
  const [states, setStates] = useState([]);
  const [places, setPlaces] = useState([]);
  const [cities, setCities] = useState([]);
  const [hotSellingPackages, setHotSellingPackages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchPackages = async () => {
        try {
            const snapshot = await firebase.firestore().collection('HotSellingPackages').get();
            const data = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data()
            }));
            setHotSellingPackages(data);
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching Hot Selling Packages: ', error);
        }
    };

    fetchPackages();
}, []);

  useEffect(() => {
    const fetchStates = async () => {
      try {
        const statesSnapshot = await db.collection('Packages').get();
        const statesData = statesSnapshot.docs.map(doc => doc.id);
        setStates(statesData);
      } catch (error) {
        console.error('Error fetching states: ', error);
      }
    };

    fetchStates();
  }, []);


  const handleStateChange = async (e) => {
    const selectedState = e.target.value;
    setState(selectedState);

    try {
      const citiesSnapshot = await db.collection('Packages').doc(selectedState).collection(selectedState).get();
      const citiesData = citiesSnapshot.docs.map(doc => doc.id);
      setCities(citiesData);
      setPlaces([]);
    } catch (error) {
      console.error('Error fetching places: ', error);
    }
  };

  const handleCityChange = async (e) => {
    const selectedCity = e.target.value;
    setCity(selectedCity);

    try {
      const placesSnapshot = await db.collection('Packages').doc(state).collection(state).doc(selectedCity).collection(selectedCity).get();
      const placesData = placesSnapshot.docs.map(doc => doc.id);
      setPlaces(placesData);
    } catch (error) {
      console.error('Error fetching places: ', error);
    }
  };

  
  const handleNextClick = () => {
    if (place) {
      const formattedState = state.replace(/\s+/g, '-');
      const formattedCity = city.replace(/\s+/g, '-');
      const formattedPlace = place.replace(/\s+/g, '-');
      window.location.href = `/packages/${formattedState}/${formattedCity}/${formattedPlace}`;
    }
};

  

  if (isLoading) {
    return(
      <div className='loading-container'>
      <p className='loading'></p>
      </div>
    )
  }

  return (
    <div>
      <div className='banner-container'>
        <img src='/home/package-1.jpg' alt='' className='nature-img' />
        <nav className="nature-nav">
          <div className='logo'>
          <Link to='/'><img src="/logo.png" alt="Logo"/></Link>
          </div>
          <Link to='/'>
            <div className='mb-2'>
              <button className='icon-button'>
                <IoMdHome size={15} />
              </button>
            </div>
          </Link>
        </nav>
        <div>
          <h1 className='banner-text'>Packages</h1>
        </div>
        <div className='container-list'>
        <div className='input-container'>
          <select value={state} onChange={handleStateChange}>
            <option value="">Country</option>
            {states.map((state, index) => (
              <option key={index} value={state}>
                {state}
              </option>
            ))}
          </select>
        </div>
          <div className='input-container'>
            <select value={city} onChange={handleCityChange}>
                <option value="">State</option>
                {cities.map((city, index) => (
                <option key={index} value={city}>
                {city}
              </option>
              ))}
            </select>
          </div>
        <div className='input-container'>
          <select value={place} onChange={(e) => setPlace(e.target.value)}>
            <option value="" >Place</option>
            {places.map((place, index) => (
              <option key={index} value={place}>
                {place}
              </option>
            ))}
          </select>
        </div>
          </div>
          <div className='next-button-container'>
            <button className='icon-button' onClick={handleNextClick} disabled={!place}><FaArrowRight size={15}/></button>
          </div>
        </div>
      <div className='container'>
        <div className='row'>
          {hotSellingPackages.map(pkg => (
            <div key={pkg.id} className='col-6 col-md-3 col-lg-3 col-xl-3 img-container'>
              <img src={pkg.imageUrl} alt={pkg.place} className='package-img' />
              <Link to={`/packages/${pkg.country}/${pkg.state}/${pkg.place}`}>
                <button type='button' className='img-button'>{pkg.place}</button>
              </Link>
            </div>
          ))}
          <button type='button' className='package-button'>Hot Selling Packages</button>
        </div>
      </div>
    </div>
  );
}

export default Packages;
