import React from 'react';
import Header from '../ClubHeader';
import HeroSection from '../ClubHero';
//import Club2 from '../Club2';
import Club3 from '../Club3';
import Club4 from '../Club4';
import Club5 from '../Club5';
import Club6 from '../Club6';
//import Club7 from '../Club7';
import ClubFooter from '../ClubFooter';
import './index.css';

function Club() {
  return (
    <div className="App">
      <Header />
      <HeroSection />
      <Club4/>
      <Club3/>
      <Club5/>
      <Club6/>
      <ClubFooter/>
    </div>
  );
}

export default Club;
