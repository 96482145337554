import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { IoMdHome } from 'react-icons/io';
import { CiStar } from "react-icons/ci";
import './index.css';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyB4BPMHKw0OXM2PhQX9NcT25lDBWpRpIPQ",
    authDomain: "phone-auth-d0a8d.firebaseapp.com",
    projectId: "phone-auth-d0a8d",
    storageBucket: "phone-auth-d0a8d.appspot.com",
    messagingSenderId: "309252587015",
    appId: "1:309252587015:web:3f9dc2505965e66269b4a2"
};

if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
}

const NatureHotel = () => {
    const { state, place, hotelId } = useParams(); 
    const formattedPlace = place.replace(/-/g, ' ');
    const formattedState = state.replace(/-/g, ' ');
    const [hotel, setHotel] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchHotel = async () => {
            try {
                const hotelSnapshot = await firebase.firestore().collection('NatureDestinations').doc(formattedState).collection(formattedState).doc(formattedPlace).collection('hotels').doc(hotelId).get();
                if (hotelSnapshot.exists) {
                    setHotel({ id: hotelSnapshot.id, ...hotelSnapshot.data() });
                    setIsLoading(false);
                } else {
                    console.log('Hotel not found');
                }
            } catch (error) {
                console.error('Error fetching hotel: ', error);
            }
        };

        fetchHotel();
    }, [formattedState, formattedPlace, hotelId]);

    if (isLoading) {
        return (
          <div className='loading-container'>
            <p className='loading'></p>
          </div>
        );
    }

    return (
        <>
            <div>
                <nav className="nature-nav mb-5">
                    <div className='logo'>
                        <Link to='/'><img src="/logo.png" alt="Logo" style={{filter:'brightness(100%)'}} /></Link>
                    </div>
                    <Link to='/now'><button className='icon-button'><IoMdHome size={15} /></button></Link>
                </nav>
                <div>
                    <h4 className='text-center mt-5 footer-text'>{formattedPlace.toUpperCase()}</h4>
                    <h2 className='text-center footer-text' style={{ fontWeight: 'bold' }}>{hotel && hotel.name.toUpperCase()}</h2>
                </div>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12 col-md-12 col-lg-12 col-xl-12'>
                            <img src={hotel && hotel.img2} alt='' className='hotel-image' />
                        </div>
                        <div className='col-4 col-md-4 col-lg-4 col-xl-4'>
                            <img src={hotel && hotel.img3} alt='' className='hotel-image' />
                        </div>
                        <div className='col-8 col-md-8 col-lg-8 col-xl-8'>
                            <img src={hotel && hotel.img4} alt='' className='hotel-image' />
                        </div>
                        <div className='col-6 col-md-6 col-lg-6 col-xl-6 mb-5'>
                            <h2 className='footer-text mt-3' style={{ fontWeight: 'bold' }}>Facilities:</h2>
                            <ul>
                                {hotel && hotel.facilities && hotel.facilities.map((facility, index) => (
                                    <li key={index} className='footer-text'>{facility}</li>
                                ))}
                            </ul>
                        </div>
                        <div className='col-6 col-md-6 col-lg-6 col-xl-6 mt-3'>
                            <p className='bold footer-text'>₹{hotel && hotel.price} per night</p>
                            <p className='bold'>{hotel && hotel.ratingType}</p>
                            <p className='footer-text' style={{ lineHeight: '0%' }}>{hotel && hotel.reviews} reviews</p>
                            <button className='review-button'>{hotel && hotel.rating} <CiStar style={{paddingBottom:'3px'}}/></button>
                            {hotel && hotel.totalFeedback && hotel.totalFeedback.map((item, index) => (
                                <p className='footer-text' key={index}><span className='bold'>Feedback</span> <br/> {item.feedback}</p>
                            ))}
                        </div>
                        <div>
                            <footer>
                                <Link to={`/nature/${formattedState}/${place}/${hotel && hotel.id}/booking`}> <button className='book-button'>Book Now</button></Link>
                            </footer>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default NatureHotel;
