import React, { useState } from "react";
import "./index.css";
import { FaArrowRightLong } from "react-icons/fa6";
import {Link} from 'react-router-dom';

const testimonials = [
  {
    text: "With The Travel Unbounded Club I have a wide variety of beautiful properties in stunning locations that I can use year-round, plus the potential growth in my investment. I already have and will continue to enjoy the variety of choice the Club offers!",
    author: "TIM HENMAN",
    title: "OWNER AND AMBASSADOR SINCE 2009",
  },
  {
    text: "The Travel Unbounded Club has given me the flexibility to choose from different luxury properties worldwide, ensuring my family and I always have a place to stay that's both beautiful and convenient.",
    author: "JANE DOE",
    title: "MEMBER SINCE 2015",
  },
  {
    text: "Investing in The Travel Unbounded Club has been one of the best decisions I've made. The properties are stunning, and the service is unparalleled.",
    author: "JOHN SMITH",
    title: "INVESTOR SINCE 2010",
  },
  {
    text: "The variety and quality of the properties offered by The Travel Unbounded Club are truly remarkable. It’s been a pleasure being a part of this exclusive club.",
    author: "SARAH JOHNSON",
    title: "MEMBER SINCE 2018",
  },
  {
    text: "As an investor, The Travel Unbounded Club provides me with excellent opportunities to grow my portfolio while enjoying luxury stays at top destinations.",
    author: "MICHAEL BROWN",
    title: "INVESTOR SINCE 2012",
  },
];

const TestimonialSlider = () => {
  const [currentTestimonial, setCurrentTestimonial] = useState(0);

  const handleNext = () => {
    setCurrentTestimonial((prev) => (prev + 1) % testimonials.length);
  };

  const handlePrev = () => {
    setCurrentTestimonial(
      (prev) => (prev - 1 + testimonials.length) % testimonials.length
    );
  };

  const { text, author, title } = testimonials[currentTestimonial];

  return (
    <div className="testimonial-slider">
        <div className="testimonial-controls">
        <button onClick={handlePrev} className="testimonial-button club-icon">
          ←
        </button>
        <button onClick={handleNext} className="testimonial-button club-icon">
          →
        </button>
      </div>
      <div className="testimonial-content">
        <p>{text}</p>
        <h6>{author}</h6>
        <h5 className="testimonial-title">{title}</h5>
      </div>
      <div className="explore-btn">
        <Link to='/club-testimonials'><button>View Testimonials <FaArrowRightLong style={{color:'brown'}}/></button></Link>
      </div>
    </div>
  );
};

export default TestimonialSlider;
