import React from 'react';
import ClubHeader from '../ClubHeader';
import ClubFooter from '../ClubFooter';
import {Link} from 'react-router-dom';
import './index.css'; 

const Login = () => {
  return (
    <>
    <ClubHeader/>
    <div className="login-container-club">
      <h1>Owner Login</h1>
      <div className="underline"></div>
      <form className="login-form-club">
        <label>Email</label>
        <input type="email" name="email" required />
        
        <label>Password</label>
        <input type="password" name="password" required />

        <button type="submit">Log In</button>
      </form>
      <div className="links">
        <Link to="/forgot-password">Forgotten your password? <span>Click here</span></Link><br/>
        <Link to="/register">Interested in membership?</Link><br/> <Link to='/club-register'> <span>Register Your Interest</span></Link>
      </div>
    </div>
    <ClubFooter/>
    </>
  );
}

export default Login;
