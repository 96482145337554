import { useEffect } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyB4BPMHKw0OXM2PhQX9NcT25lDBWpRpIPQ",
    authDomain: "phone-auth-d0a8d.firebaseapp.com",
    projectId: "phone-auth-d0a8d",
    storageBucket: "phone-auth-d0a8d.appspot.com",
    messagingSenderId: "309252587015",
    appId: "1:309252587015:web:3f9dc2505965e66269b4a2"
};

if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
}

const AddDataToFirestore = () => {
  useEffect(() => {
      const addData = async () => {
          try {
              const db = firebase.firestore();
              const natureDestinationsRef = db.collection('WeekendGateways');
              const horselyHillsDocRef = natureDestinationsRef.doc('TamilNadu').collection('TamilNadu').doc('Chennai').collection('Chennai').doc('Sri Venkateshwara Wildlife Sanctuary:Nellore');

              const hotelsCollectionRef = horselyHillsDocRef.collection('hotels');

              const hotelsData = {
                hotelA: {
                    extraBedCost: 6000,
                    facilities: ["Wifi", "Garden", "Hot Water", "Activities", "Kids play area", "Room Heater", "AC", "Breakfast Included", "Pet friendly"],
                    img1: "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/hotelImages%2Fhotel-1.jpg?alt=media&token=896112c4-426a-460f-ae3e-9727139d9294",
                    img2: "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/hotelImages%2Fhotel-1.jpg?alt=media&token=896112c4-426a-460f-ae3e-9727139d9294",
                    img3: "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/hotelImages%2Fhotel-2.jpg?alt=media&token=23d3fce1-1c34-430d-9106-c1169584259e",
                    img4:'https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/hotelImages%2Fhotel-6.jpg?alt=media&token=63750217-b364-4784-be70-09a4f95e7f5f',
                    name: "Hotel A",
                    price: 5000,
                    singleRoomCost: 4000,
                    rating: "4.3",
                    ratingType: "Excellent",
                    reviews: "1500",
                    roomType: "double",
                    adminMail:'ds@travelunbounded.com',
                    hotelMail:'ds@travelunbounded.com',
                    freelancerMail:'ds@travelunbounded.com',
                    cp1:'If you cancell before 10 days to checkin date will be refundable',
                    cp2:'If you cancel before 5 days to checkin date 50% will be refundable',
                    cp3:'Block NOW amount is non refundable',
                    categories:["NatureDestinations","OnenessDestinations","WeekendGateways"]
                },
                hotelB: {
                    extraBedCost: 7000,
                    facilities: ["Wifi", "Garden", "Hot Water", "Activities", "Kids play area", "Room Heater", "AC", "Breakfast Included", "Pet friendly"],
                    img1: "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/hotelImages%2Fhotel-2.jpg?alt=media&token=23d3fce1-1c34-430d-9106-c1169584259e",
                    img2: "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/hotelImages%2Fhotel-1.jpg?alt=media&token=896112c4-426a-460f-ae3e-9727139d9294",
                    img3: "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/hotelImages%2Fhotel-2.jpg?alt=media&token=23d3fce1-1c34-430d-9106-c1169584259e",
                    img4:'https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/hotelImages%2Fhotel-6.jpg?alt=media&token=63750217-b364-4784-be70-09a4f95e7f5f',
                    name: "Hotel B",
                    price: 6000,
                    singleRoomCost: 5000,
                    rating: "4.5",
                    ratingType: "Excellent",
                    reviews: "1000",
                    roomType: "double",
                    adminMail:'ds@travelunbounded.com',
                    hotelMail:'ds@travelunbounded.com',
                    freelancerMail:'ds@travelunbounded.com',
                    cp1:'If you cancell before 10 days to checkin date will be refundable',
                    cp2:'If you cancel before 5 days to checkin date 50% will be refundable',
                    cp3:'Block NOW amount is non refundable',
                },
                hotelC: {
                  extraBedCost: 5000,
                  facilities: ["Wifi", "Garden", "Hot Water", "Activities", "Kids play area", "Room Heater", "AC", "Breakfast Included", "Pet friendly"],
                  img1: "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/hotelImages%2Fhotel-3.jpg?alt=media&token=fe4afe15-771c-48ed-85d3-ac19490f338e",
                  img2: "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/hotelImages%2Fhotel-1.jpg?alt=media&token=896112c4-426a-460f-ae3e-9727139d9294",
                  img3: "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/hotelImages%2Fhotel-2.jpg?alt=media&token=23d3fce1-1c34-430d-9106-c1169584259e",
                  img4:'https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/hotelImages%2Fhotel-6.jpg?alt=media&token=63750217-b364-4784-be70-09a4f95e7f5f',
                  name: "Hotel C",
                  price: 4000,
                  singleRoomCost: 3000,
                  rating: "4.0",
                  ratingType: "Good",
                  reviews: "900",
                  roomType: "double",
                  adminMail:'ds@travelunbounded.com',
                  hotelMail:'ds@travelunbounded.com',
                  freelancerMail:'ds@travelunbounded.com',
                  cp1:'If you cancell before 10 days to checkin date will be refundable',
                  cp2:'If you cancel before 5 days to checkin date 50% will be refundable',
                  cp3:'Block NOW amount is non refundable',
                  categories:["NatureDestinations","OnenessDestinations","WeekendGateways"]
                },
                hotelD: {
                  extraBedCost: 6000,
                  facilities: ["Wifi", "Garden", "Hot Water", "Activities", "Kids play area", "Room Heater", "AC", "Breakfast Included", "Pet friendly"],
                  img1: "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/hotelImages%2Fhotel-4.jpg?alt=media&token=66ab4da3-1ff9-4c9b-a5c3-ed958791763e",
                  img2: "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/hotelImages%2Fhotel-1.jpg?alt=media&token=896112c4-426a-460f-ae3e-9727139d9294",
                  img3: "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/hotelImages%2Fhotel-2.jpg?alt=media&token=23d3fce1-1c34-430d-9106-c1169584259e",
                  img4:'https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/hotelImages%2Fhotel-6.jpg?alt=media&token=63750217-b364-4784-be70-09a4f95e7f5f',
                  name: "Hotel D",
                  price: 5000,
                  singleRoomCost: 4000,
                  rating: "4.0",
                  ratingType: "Excellent",
                  reviews: "1500",
                  roomType: "double",
                  adminMail:'ds@travelunbounded.com',
                  hotelMail:'ds@travelunbounded.com',
                  freelancerMail:'ds@travelunbounded.com',
                  cp1:'If you cancell before 10 days to checkin date will be refundable',
                  cp2:'If you cancel before 5 days to checkin date 50% will be refundable',
                  cp3:'Block NOW amount is non refundable',
                },
                hotelE: {
                  extraBedCost: 7000,
                  facilities: ["Wifi", "Garden", "Hot Water", "Activities", "Kids play area", "Room Heater", "AC", "Breakfast Included", "Pet friendly"],
                  img1: "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/hotelImages%2Fhotel-6.jpg?alt=media&token=63750217-b364-4784-be70-09a4f95e7f5f",
                  img2: "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/hotelImages%2Fhotel-1.jpg?alt=media&token=896112c4-426a-460f-ae3e-9727139d9294",
                  img3: "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/hotelImages%2Fhotel-2.jpg?alt=media&token=23d3fce1-1c34-430d-9106-c1169584259e",
                  img4:'https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/hotelImages%2Fhotel-6.jpg?alt=media&token=63750217-b364-4784-be70-09a4f95e7f5f',
                  name: "Hotel E",
                  price: 6000,
                  singleRoomCost: 5000,
                  rating: "4.6",
                  ratingType: "Excellent",
                  reviews: "1500",
                  roomType: "double",
                  adminMail:'ds@travelunbounded.com',
                  hotelMail:'ds@travelunbounded.com',
                  freelancerMail:'ds@travelunbounded.com',
                  cp1:'If you cancell before 10 days to checkin date will be refundable',
                  cp2:'If you cancel before 5 days to checkin date 50% will be refundable',
                  cp3:'Block NOW amount is non refundable',
                },
                hotelF: {
                  extraBedCost: 6000,
                  facilities: ["Wifi", "Garden", "Hot Water", "Activities", "Kids play area", "Room Heater", "AC", "Breakfast Included", "Pet friendly"],
                  img1: "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/hotelImages%2Fhotel-6.jpg?alt=media&token=63750217-b364-4784-be70-09a4f95e7f5f",
                  img2: "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/hotelImages%2Fhotel-1.jpg?alt=media&token=896112c4-426a-460f-ae3e-9727139d9294",
                  img3: "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/hotelImages%2Fhotel-2.jpg?alt=media&token=23d3fce1-1c34-430d-9106-c1169584259e",
                  img4:'https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/hotelImages%2Fhotel-6.jpg?alt=media&token=63750217-b364-4784-be70-09a4f95e7f5f',
                  name: "Hotel F",
                  price: 5000,
                  singleRoomCost: 4000,
                  rating: "4.3",
                  ratingType: "Excellent",
                  reviews: "1500",
                  roomType: "double",
                  adminMail:'ds@travelunbounded.com',
                  hotelMail:'ds@travelunbounded.com',
                  freelancerMail:'ds@travelunbounded.com',
                  cp1:'If you cancell before 10 days to checkin date will be refundable',
                  cp2:'If you cancel before 5 days to checkin date 50% will be refundable',
                  cp3:'Block NOW amount is non refundable',
                },
              }

              for (const [hotelId, hotelData] of Object.entries(hotelsData)) {
                await hotelsCollectionRef.doc(hotelId).set(hotelData);
              }

              console.log('Data added successfully!');
          } catch (error) {
              console.error('Error adding data: ', error);
          }
      };

      addData();
  }, []);

  return (
      <div className='text-center'>
          <h1>Add Data</h1>
      </div>
  );
};

export default AddDataToFirestore;