import React from 'react';
import './index.css';

const NewsCard = ({ image, title, date, description }) => {
  return (
    <div className="news-card">
      <img src={image} alt={title} className="news-card-image" />
      <div className="news-card-content">
        <p className="news-card-date">{date}</p>
        <h3 className="news-card-title">{title}</h3>
        <p className="news-card-description">{description}</p>
        <a href="#link" className="news-card-link">Read more →</a>
      </div>
    </div>
  );
};

export default NewsCard;
