import React from "react";
import "./index.css";

const Partner = () => {
  return (
    <div className="terms-container">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-12 col-lg-12 col-xl-12">
            <div className="logo mt-3">
                <img src="/logo.png" alt="" style={{filter:'brightness(100%)'}}/>
            </div>
          <h4 className="mt-5 mb-5">Travel Unbounded World Pvt Ltd ( under its brand name And Beyond Roads ) AGREEMENT
            WITH SERVICE PROVIDER</h4>
            <p className="mb-3">
            <b>THIS AGREEMENT</b> (the “Agreement”) is made and entered on __________ [Insert
            date]at <span style={{background:'lightgreen'}}>Bangalore</span> between ________ [Travel Unbounded World Pvt Ltd ( under its brand
            name And Beyond Roads )]<span style={{background:'lightgreen'}}>represented by its Managing Director Mr……..</span> having its
            registered office at No 10, 8th Main, 12th Cross, Vinayakanagar B block,
            KonenaAgrahara, Bangalore 560017, India (hereinafter referred to as the Company),
            which expression shall, unless it be repugnant to the context or meaning thereof, be
            deemed to mean and include its successors and assigns) of the ONE PART and
            _____________<span style={{color:'red'}}> [Insert name & address of the service provider]</span> (here in after referred to
            as the Service Provider) which expression shall, unless it be repugnant to the context or
            meaning thereof, be deemed to mean and include its successors and assigns) of the
            OTHER PART.
            </p>
            <p className="mb-3">
            <b>WHEREAS</b> the Service Provider is providing services related to _______________
            <span style={{color:'red'}}>(Details of business).</span>
            </p>
            <p className="mb-3">
            <b>WHEREAS</b> the company has approached the Service provider to provide services at the 
            Rate of services provided by the Service provider when sending the details pertaining to
            their service in the partner module provided by the Company. This agreed rate is valid for
            a period of 12 months from the commencement of business and can be changed only
            after discussions with the Company after the fore-said period.
            </p>
            <p className="mb-3">
            <b>WHEREAS</b>, each Party is duly authorized and capable of entering into this Agreement.
            </p>
            <h4 className="bold mt-5">PREAMBLE</h4>
            <ul>
                <li className="mb-3">The Company is engaged in the business of making available travel and tourism
                related services.</li>
                <li className="mb-3">Service Provider is desirous of providing the Services (detailed above) to the
                Company’s Clients as per the terms of this Agreement.</li>
            </ul>
            <p className="mb-3">
            <b>NOW THEREFORE</b>, in consideration of the above recitals, the Parties hereby agree as
            follows:
            </p>
            <h4 className="mb-3 mt-3 bold">1. Scope of Services</h4>
            <p className="mb-3">
             The Service Provider is to provide the Company with the following
            services (the “Services”):
            </p>
            <ul>
                <li className="mb-3">The Service provider would cater to the services requested by the Company’s
                clients subject to availability and seasonality. The Services would be selected by the
                Company’s clients from the details provided by the Service provider from the partner /
                vendor module and agreed upon by the Company and the Service provider.</li>
                <li className="mb-3">Use its own infrastructure and equipment(s) for rendering the Services.</li>
                <li className="mb-3">Maintain and use appropriate and up-to-date virus protection procedures and software
                on the equipment used by Service Provider .</li>
                <li className="mb-3">Be responsible for maintaining security of log-in credentials (including the user name
                and password)</li>
                <li className="mb-3">Take all precautions to prevent any unauthorised use of the Company Platform.</li>
                <li className="mb-3">Be responsible for any loss, theft or unauthorized use of its log-in credentials and shall
                immediately notify the Company upon becoming aware of such loss, theft or
                unauthorized use.</li>
                <li className="mb-3">Establish and maintain reasonable safeguards against the destruction, loss or
                unauthorized alteration of the Company Platform.</li>
                <li className="mb-3">Use the Company Platform solely for making travel, lodging reservations; providing
                travel information to its customers; logistic support, transportation, game drives,
                safaris, and any other actions authorized and agreed by the Company and the Service
                provider.</li>
                <li className="mb-3">The Company will be responsible for generating the Booking Voucher to be shared with
                the clients. The Company will share the booking confirmation with the Service Provider 
                and the Service Provider in turn shall share their confirmation Voucher/s with the
                Company on an “as-is” basis.</li>
                <li className="mb-3">No action or omission by the Service Provider beyond the scope of authorisation as
                detailed in this Agreement will bind the Company.</li>
                <li className="mb-3">Promote the Platform Services as per the scope specified by Company.</li>
                <li className="mb-3">Not use the brand name (includes modifications), logo, trade name or references of
                Company in any of the marketing activities, except with the prior approval of Company
                which is to be obtained on case to case basis.</li>
                <li className="mb-3">Submit the customer queries (including but not limited to any queries pertaining to
                Booking of the Platform Services) to the Company immediately where Company’s
                assistance is required to resolution.</li>
                <li className="mb-3">Generate reports (including but not limited to Booking reports) upon request of the
                Company from time to time, in order to verify the performance of the obligations of
                Travel Agent Partner under this Agreement.</li>
                <li className="mb-3">Provide relevant and accurate data and other assistance as the Company may
                reasonably require in respect of the Bookings of Platform Services made pursuant to
                this Agreement.</li>
                <li className="mb-3">Conduct itself in a manner which does not impact or may cause an impact on the
                goodwill of the Company.</li>
                <li className="mb-3">Not promise any refund or compensation on behalf of the Company apart from what
                has been agreed upon as a refund policy of the Service provider without prior written
                consent of the Company.</li>
                <li className="mb-3">Not use, modify, download, copy, compile, cache, sell, transmit, distribute or otherwise
                made available anything from the Company Platform except as specifically provided in
                this Agreement.</li>
                <li className="mb-3">Not gather or extraction anything (using automated tools or otherwise) from the
                Company Platform and also not to share Company’s client’s data for any commercial
                purpose.</li>
            </ul>
            <p className="mb-3 bold">
            2. ACCESS to the Company’s online platform -
            </p>
            <ul>
                <li className="mb-3">The Company provides limited, non-exclusive and non-transferable right to Service
                Provider to access and use the Company Platform.</li>
                <li className="mb-3">The Company Platform shall not be used, compiled, cached, sold, distributed or
                otherwise made available by Service Provider except as specifically provided in this
                Agreement.</li>
                <li className="mb-3">The Company is rendering services as a facilitator between Service Provider and
                customer and the Service Provider shall be responsible for the provision of Platform
                Services to the customer.</li>
            </ul>
            <h4 className="mt-3 mb-3 bold">3. Compensation</h4>
            <p className="mb-3">Company shall provide compensation to the Service Provider at a
            rate of at the  Rate of services provided by the Service provider when sending the details
            pertaining to their service in the partner module provided by the Company, the payment
            terms would be 95% of the total invoice on or before five working days of the Services
            rendered. Service Provider shall provide the Company with an invoice upon completion of
            the Services. Balance Payment for the Services is due within five <span style={{color:'red'}}>[5]</span> days of the date of
            the Service Provider’s invoice.</p>
            <h4 className="mt-3 mb-3 bold">4. Term/Termination.</h4>
            <p className="mb-3">This Agreement shall commence on ______<span style={{color:'red'}}>[Commencement
            Date]</span>and remain in effect for a period of 12 months. This Agreement may be extended
            by mutual written consent of both parties. If either party seeks to terminate this
            Agreement, the terminating party must provide thirty <span style={{color:'red'}}>[30]</span> days notice to the other party.
            You may terminate a Subscription at any time during its Term; however, you must  cater
            to all selected services of the Company and pay all amounts due and owing before the
            termination is effective.</p>
            <h4 className="mt-3 mb-3 bold">5.Independent Contractor Status</h4>
            <p className="mb-3">The Service Provider is serving as an independent
            contractor in providing the Services as per committed while sending the details to the
            Company through the partner module. Under this Agreement, the Service Provider is
            neither an employee nor a partner of the Company.</p>
            <h4 className="mt-3 mb-3 bold">6. Transaction</h4>
            <p className="mb-3">This Document signifies Service provider interest in promoting their
            camps / resorts / hotels / services on  And Beyond Road’s Progressive Web App services
            & solution ( an online booking engine / tour aggregator for wilderness holidays across the
            globe ). The Buyer would initially start using the given interface to update the availability
            of its above mentioned services and also confirm the bookings through the same to the
            Company.</p>
            <p className="mb-3">Key features of the <b>Progressive Web App services & solution</b> include access to the
            following features through an interface provided by the Company:</p>
            <ul>
                <li className="mb-3">Space availability by the partner</li>
                <li className="mb-3">Content table to be uploaded</li>
                <li className="mb-3">Confirmation against bookings made by Company's  clients</li>
                <li className="mb-3">Confirmation against payment received towards services rendered to
                Company's  clients</li>
                <li className="mb-3">Feedback of the Company's  clients after the completion of the trip</li>
            </ul>
            <p className="mb-3">The data and infrastructure are owned by “Travel Unbounded World Pvt Ltd” ( under its
            brand name “And Beyond Roads” )</p>
            <h4 className="mt-3 mb-3 bold">7.  Service Quality</h4>
            <p className="mb-3">The Service provider assures to deliver all the selected services of
            the Company’s clients as per committed to the Company. Any default of delivery will be
            treated as a short of commitment and the respective amount would be deducted from
            the Service provider’s account and Company has the right to black list or remove the
            Service provider from its online platform on these grounds.</p>
            <h4 className="mt-3 mb-3 bold">8. Booking and Cancelation policies</h4>
            <p className="mb-3">The Company is not liable for any claim raised
            by the Clients with regard to the booking and the cancelation of the Service provider. The
            Service provider has to settle these dues within the committed days ( as per the Service
            provider’s ) to the Company’s clients.</p>
            <h4 className="mt-3 mb-3 bold">9. Notice</h4>
            <p className="mb-3">All notices required or permitted under this Agreement will be provided in
            writing and delivered to both parties.</p>
            <h4 className="mt-3 mb-3 bold">10.Governing Law</h4>
            <p className="mb-3">The laws of the Country / State of _____________ <span style={{color:'red'}}>[Name of the
            Governing Country / State]</span> govern all matters arising out of or relating to this Agreement
            and the transactions it contemplates, including, without limitation, its interpretation,
            construction, validity, performance, and enforcement.</p>
            <h4 className="mt-3 mb-3 bold">11. Taxes</h4>
            <ul>
                <li className="mb-3">Pursuant to this Agreement, each Party, with respect to the services rendered in its
                individual capacity, would be solely responsible for the compliance of all applicable laws
                including but not limited to laws regarding Goods and Platform Services Tax (GST),
                central, state, Country or local levies with respect to payment of tax, duties, levies,
                charges, cess, etc.</li>
                <li className="mb-3">The Parties would be responsible for their own tax assessments, audits, inquires, etc.
                and would keep the other Party indemnified from any additional tax demand arising
                out of the same.</li>
                <li className="mb-3">For the service fee (if any) charged, Service Provider shall issue a tax invoice on the
                customers only for the amount of service fee along with applicable taxes and the
                Company will not have any responsibility to raise an invoice on customer towards such
                service fee.</li>
                <li className="mb-3">Service Provider  shall also be responsible for the compliance with the applicable tax
                laws (including its obligations to deposit the necessary taxes with the relevant
                authorities) in connection with the service fee charged by Service Provider  from the
                customer.</li>
            </ul>
            <h4 className="mt-3 mb-3 bold">12.Assignment/Delegation</h4>
            <p className="mb-3">The Company may not assign any of its rights under this
            Agreement or delegate any performance under this Agreement, except with the prior
            written consent of the Service Provider. Any purported assignment of rights or delegation
            of performance in violation of this section is void.</p>
            <h4 className="mt-3 mb-3 bold">13. Acceptable Use:</h4>
            <ul>
                <li className="mb-3">You may use the Product only in accordance with this agreement. You may not
                reverse engineer, decompile, disassemble, or work around technical limitations in the
                Product, except to the extent applicable law permits it despite these limitations. You may
                not disable, tamper with, or otherwise attempt to circumvent any billing mechanism that
                meters your use of the Services. You may not rent, lease, lend, resell, transfer, or host
                the Product, or any portion thereof, to or for third parties except as expressly permitted
                in this agreement or the Online Services Terms.</li>
                <li className="mb-3">All rights pertaining to trade names, trademarks, service marks, logos, symbols,
                proprietary marks and any other Intellectual Property Rights in respect of Company
                Platform as well as any information supplied by the Company shall exclusively vest with
                the Company.</li>
                <li className="mb-3">The Travel Agent Partner shall not acquire any right whatsoever, in the Intellectual
                Property or proprietary rights of the Company on account of access to the Company
                Platform.</li>
            </ul>
            <h4 className="mt-3 mb-3 bold">14. Force Majeure:</h4>
            <p className="mb-3">Neither party will be liable for any failure in performance due to
            causes beyond that party’s reasonable control (such as fire, explosion, power blackout,
            earthquake, flood, severe storms, strike, embargo, labor disputes, acts of civil or military
            authority, war, terrorism (including cyber terrorism), <span style={{background:'lightgreen'}}>pandemic, epidemic</span>, acts of God,
            acts or omissions of Internet traffic carriers, actions or omissions of regulatory or
            governmental bodies (including the passage of laws or regulations or other acts of
            government that impact the delivery of Online Services)). This Section will not, however,
            apply to your payment obligations under this agreement.</p>
            <h4 className="mt-3 mb-3 bold">15. Margins:</h4>
            <p className="mb-3">The Travel Agent Partner agrees to share ____% of the BAR ( Best
            Available Rate ) or B2B rates (which ever is lower) to the company. These rates have to
            be updated on a monthly / season basis.</p>
            <h4 className="mt-3 mb-3 bold" style={{background:'lightgreen'}}>14. Force Majeure:</h4>
            <ul>
                <li className="mb-3" style={{background:'lightgreen'}} >Any dispute or difference arising out of this Agreement shall be amicably   
                settled between the Parties.</li>
                <li className="mb-3" style={{background:'lightgreen'}}>In case of non-settlement of dispute or difference, relating to the
                interpretation and application of the Provisions of this Agreement, such dispute or
                difference shall be settled by Arbitration/ by a Sole Arbitrator as may be agreed to by the
                Parties and shall be governed by the provisions of the Arbitration &amp; Conciliation Act, 1996
                as amended viz. The Arbitration and Conciliation (Amendment) Act, 2015.</li>
                <li className="mb-3" style={{background:'lightgreen'}}>The parties to the dispute shall share equally the Cost of Arbitration as
                intimated by the Arbitrator.</li>
                <li className="mb-3" style={{background:'lightgreen'}}>Arbitration proceedings shall be held at Bangalore, India and the language
                of the arbitration proceedings and that of all documents and communications between
                the parties shall be English.</li>
            </ul>
            <p className="mb-3" style={{background:'lightgreen'}} ><b>Waiver:</b>  The waiver by either Party of breach, default, delay or omission of
            any of the provisions of this Agreement by the other party will not be construed as a
            waiver of ay subsequent breach of the same or other provisions.</p>
            <p className="mb-3" style={{background:'lightgreen'}}><b>Notice:</b> All Notices, requests, demands or other communications
            required or permitted by the terms of this Agreement will be given in writing and
            delivered to the Parties at the following addresses:</p>
            <p className="mb-3" style={{background:'lightgreen'}} >(a)</p>
            <p className="mb-3" style={{background:'lightgreen'}} >(b)</p>
            <h4 className="mt-3 mb-3 bold">15. Entire Agreement</h4>
            <p className="mb-3">This Agreement constitutes the entire agreement between the
            parties with respect to the subject matter of this Agreement and supersedes all other
            agreements, whether written or oral, between the parties.</p>
            <p className="mb-3 bold" style={{background:'lightgreen'}} >IN WITNESS WHEREOF, THE PARTIES OF BOTH THE PARTS HERETO HAVE SET
            THEIR RESPECTIVE HANDS AND SIGNATURES ON THE DAY, MONTH AND YEAR
            FIRST ABOVE WRITTEN.</p>
            <p className="mb-3 bold">Witness</p>
            <div className="container mb-5 mt-3">
                <div className="row">
            <div className="col-6 col-md-6 col-lg-6 col-xl6">
                <p>1.</p>
            </div>
            <div className="col-6 col-md-6 col-lg-6 col-xl6">
                <p>Client’s name: _________________</p>
                <p>Client’s signature: _________________</p>
            </div>
            </div>
            </div>
            <div className="container mt-5">
                <div className="row">
            <div className="col-6 col-md-6 col-lg-6 col-xl6">
                <p>2.</p>
            </div>
            <div className="col-6 col-md-6 col-lg-6 col-xl6">
                <p>Representative: _________________</p>
                <p>Company name: _________________</p>
                <p>Company signature: _________________</p>
                <p>Representative: _________________</p>
            </div>
            </div>
            </div>
            <p className="mb-3 mt-3 bold">Travel Unbounded World Pvt Ltd,</p>
            <p className="mb-3 bold">Reg Office -</p>
            <p className="bold">No 10, 8th Main, 12th Cross, Vinayaka Nagar B block,</p>
            <p className="bold">Konena Agrahara, Bangalore 560017, India</p>
            <p className="bold">support@travelunbounded.com</p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Partner;
