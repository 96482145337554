import React from "react";
import "./index.css";

const Client = () => {
  return (
    <div className="terms-container">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-12 col-lg-12 col-xl-12">
          <h4 className="mt-5 mb-5">APPLICABILITY -</h4>
            <p className="mb-3">
            This Client agreement along with the terms of service forms the and conditions for
            the use of services and products of “ Travel Unbounded World Pvt Ltd , hereby
            referred under it’s sub brand name NOW)”, hereby referred as “NOW”
            </p>
            <p className="mb-3">
            Any person hereby referred as “Client” who uses the services provided on the
            online digital platform of “NOW” through the Progressive Web App, website, mobile
            applications, direct employees of NOW, franchise of NOW, business partners of NOW
            or another form of sales channel agree to be governed by this Client Agreement.
            </p>
            <p className="mb-3">
            Both Client and NOW are individually referred as ‘Party’ or collectively ‘Parties’ to
            this client agreement.
            </p>
            <p className="mb-3">The Client agrees to all the terms and conditions applicable on the NOW website.</p>
            <h4 className="mb-3 mt-5">ELIGIBILITY TO CLIENT -</h4>
            <p className="mb-3">
            The Client should be atleast 18 years of age and should process a govt approved
            document to prove his/her date of birth. Any Client who is aged less than 18 years
            are not eligible to use or transact on NOW site or through any Sales channels of
            NOW.
            </p>
            <p className="mb-3">Any transaction of Client less than 18 years should be made by persons of legal
            contracting age ( it can be the parent or a guardian of the Client aged less than 18
            years ). Any Client less than 18 years trying to use or transact the NOW site would
            be blocked.</p>
            <p className="mb-3">Any Client should read the terms and conditions of NOW, understand all
            requirements  agree and then only start to use or transact on NOW digital platform
            or its Sales channels.</p>
            <p className="mb-3">All rights and liabilities of the Client and NOW with respect to any services or
            product facilitated by NOW shall be restricted to the scope of this Client Agreement.</p>
            <p className="mb-3">Any Client making a booking or choosing to select any service provided by NOW for
            others, should carefully read through all the terms and conditions and is liable to
            intimate to the person the service is finally been provided to. NOW is not liable on
            any miscommunications.</p>
            <p className="mb-3">Client also authorizes NOW&#39;s representative to contact such Client over phone,
            message and email provided by them in the booking form/application. This consent
            shall supersede any preferences set by such Client through national customer
            preference register (NCPR) / DND or any other similar preferences.</p>
            
            <h4 className="mb-3 mt-5">CONTENT -</h4>
            <p className="mb-3">
            All content provided through various Sales Channels, including but not limited to
            audio, images, software, text, icons and such similar content (&quot;Content&quot;), are
            registered by NOW and protected under applicable intellectual property laws. Client
            cannot use this Content for any other purpose, except as specified herein.
            </p>
            <p className="mb-3">
            NOW does not grant the Client a license, right or authority to utilise such
            proprietary logos, service marks, or trademarks in any manner. Any unauthorised
            use of the Content, will be in violation of the applicable law.
            </p>
            <p className="mb-3">
            If in case NOW has infringed any copy right issues, we request you to write to us
            to support @andbeyondroads.com with the following.
            </p>
            <ul>
                <li className="mb-3">Clear identification of such copyrighted work that you claim has been infringed</li>
                <li className="mb-3">Location of the material on the Website, including but not limited to the link of
                the infringing material</li>
                <li className="mb-3">The proof that the alleged copyrighted work is owned by you</li>
            </ul>

            <h4 className="mb-3 mt-5">LIMITED LIABILITY OF NOW -</h4>
            <p className="mb-3">
            NOW only acts as a facilitator by connecting the Client with the respective
            &quot;&quot;Service Provider&quot; or &quot;NOW Partners&quot;&quot; or &quot;NOW Partners” like airlines, hotels,
            restaurants, camps, resorts, logistics partners, safari partners, guides, transfer
            &quot;&quot;Service Provider&quot; or &quot;NOW Partners&quot;&quot; or , transport operators etc. (collectively
            referred to as &quot;&quot;&quot;Service Provider&quot; or &quot;NOW Partners&quot;&quot; or &quot;NOW Partners&quot;). NOW&#39;s
            liability is limited to providing the Client with a confirmed booking if available as
            selected or chosen by the Client.
            </p>
            <p className="mb-3">
            Any concerns or issues faced by the Client at the time of booking or availing any
            such services shall be the sole responsibility of the &quot;&quot;Service Provider&quot; or &quot;NOW
            Partner&quot;&quot; or &quot;NOW Partners&quot; Including payment issues arising out of bank
            transaction. NOW will have no liability with respect to the acts, omissions, errors,
            representations, warranties, breaches or negligence on part of any &quot;&quot;Service
            Provider&quot; or &quot;NOW Partner&quot;&quot; or &quot;NOW Partners&quot; or “ Bankers”.
            </p>
            <p className="mb-3">
            Unless NOW explicitly acts as a reseller in certain scenarios, well mentioned on the
            website / PWA
            </p>
            <ul>
                <li className="mb-3">NOW is not liable for the standard / quality of services as provided by the
                respective &quot;&quot;Service Provider&quot; or &quot;NOW Partner&quot;&quot; or &quot;NOW Partners&quot;.</li>
                <li className="mb-3">NOW doesn&#39;t guarantee the availability of any services as listed by a
                &quot;&quot;Service Provider&quot; or &quot;NOW Partner&quot;&quot; or &quot;NOW Partners&quot;.</li>
            </ul>
            <p className="mb-3"></p>
            <p className="mb-3">By designing their itinerary or choosing one of the recommended itinerary, the
            Client understands NOW merely provides a technology / digital / online platform for
            booking / designing of services and products / itineraries etc. The ultimate liability

            rests on the respective &quot;&quot;Service Provider&quot; or &quot;NOW Partner&quot;&quot; or &quot;NOW Partners&quot;
            and not NOW. Thus the ultimate contract of service is between Client and &quot;&quot;Service
            Provider&quot; or &quot;NOW Partner&quot;&quot; or &quot;NOW Partners”.</p>
            <p className="mb-3">Client further understands that the information / content displayed on the Website
            / PWA with respect to any service is displayed as furnished by the &quot;&quot;Service
            Provider&quot; or &quot;NOW Partner&quot;&quot; or &quot;NOW Partners”. NOW, therefore cannot be held
            liable in case if the information provided by the &quot;Service Provider&quot; or &quot;NOW
            Partners&quot; is found to be inaccurate, inadequate or obsolete or in contravention of
            any laws, rules, regulations or directions in force.</p>
            
            <h4 className="mb-3 mt-5">SECURITY AND ACCOUNT RELATED INFORMATION -</h4>
            <p className="mb-3">
            While registering on the Website, the Client will have to choose a password of their
            choice to access that Client&#39;s account. The Client is solely responsible for
            maintaining the confidentiality of both the password and the account as well as for
            all activities on the account. On any unauthorised use of their password or account
            or any other breach of security, it is the duty of the Client to notify NOW
            immediately in writing. No verbal communication on this would be accepted or
            acted upon. NOW will not be liable for any loss that may be incurred by the Client
            as a result of unauthorised use of the password or account, either with or without
            the Client&#39;s knowledge. The Client is never authorised to use anyone else&#39;s account
            at any time.
            </p>
            <p className="mb-3">
            NOW adopts the best industry standard to secure the information as provided by
            the Client. However, NOW cannot guarantee that there will never be any security
            breach of its systems which may have an impact on Client&#39;s information too.
            </p>
            <p className="mb-3">
            NOW keeps all the data in relation to credit card, debit card, bank information etc.
            secured and in an encrypted form in compliance with the applicable laws and
            regulations. However, for cases of fraud detection, offering bookings on credit
            (finance) etc., NOW may at times verify certain information of its Clients like their
            credit score, as and when required.
            </p>
            <p className="mb-3">
            NOW may share your Personal Information in an aggregated and/ or anonymized
            form with any third party that NOW may engage to perform certain tasks on its
            behalf, including but not limited to payment processing, data hosting, data
            processing , credit score and assessing credit worthiness for offering bookings on
            credit in accordance with the applicable laws.
            </p>
            <p className="mb-5">
            The data of the Client as available with NOW may be shared with concerned law
            enforcement agencies for any lawful or investigation purpose without the consent of
            the Client.
            </p>

            <h4 className="mb-3 mt-5">PAYMENTS AND SERVICE FEES -</h4>
            <p className="mb-3">NOW reserves the right to charge a minimal service charge in addition to the
            payment done by the Client. This service charge would be altered  from time to
            time. These charges would be made visible to the Client before they make the
            payment for their bookings/ services.</p>
            <p className="mb-3">Owing to any technical error or other reason, in case of short payments, NOW has
            the right to cancel the booking in case the payment due is not cleared before the
            mentioned date.</p>
            <p className="mb-3">The Client has to bear any additional increase in price change in rate of taxes or
            imposition of new taxes, levies by Government or  partners.</p>
            <p className="mb-3">NOW is not under any obligation to provide an alternate booking in the rare
            circumstance of a booking not getting confirmed for any reason whatsoever. NOW
            would just initiate the refund deducting applicable charges and  taxes. This would
            be intimated to the Client.</p>
            <p className="mb-3">The Client shall be completely responsible for all charges, fees, duties, taxes, and
            assessments arising out of the use of the service, as per the applicable laws.</p>
            <p className="mb-3">NOW shall not be held liable for any payments made to accounts not held in theme
            of NOW or its agents. Client have to agree and understand this before making any
            transfers. No transfers should be made to any private bank account other than the
            ones mentioned on the web site and owned by NOW.</p>
            <p className="mb-3">The Client will not share his personal sensitive information like credit/debit card
            number, CVV, OTP, card expiry date, Client IDs, passwords etc. with any person
            including the agents, NOW Parter, NOW Partners, employees or representatives of
            NOW. The Client shall immediately inform NOW if such details are demanded by any
            of its agents&#39; employees or representatives. NOW shall not be liable for any loss
            that the Client incurs for sharing the aforesaid details.</p>
            <p className="mb-3">Refunds, if any, on cancelled bookings will always be processed to the respective
            account or the banking instrument (credit card, wallet etc.) from which payment
            was made for that booking.</p>
            <p className="mb-3">Booking(s) made by the Client through NOW are subject to the applicable
            cancellation policy as set out on the booking page or as communicated to the
            customers in writing.</p>
            <p className="mb-3">NOW provides various modes of making payments on the Website for transacting,
            like UPI, Credit/Debit Cards of various banks, Net Banking facility of all major
            banks, NOW and third party Wallets, Gift Cards, EMI and more.</p>
            <p className="mb-3">NOW provides an International payment gateway for its International bank
            account/s. Any cancellation through this account would be refunded as per the rules
            of the State / Country</p>

            <h4 className="mb-3 mt-5">NOW COMMUNICATION TO CLIENT VIA MOBILE PHONE AND OTHER DEVICES -</h4>
            <p className="mb-3">NOW would send SMS, WhatsApp messages, e-mails to registered Clients with
            regard to their bookings, cancellations, refund process, promotional offers etc.
            These messages are being sent only because the Client has registered themselves.
            These cannot be considered to be a part of the DND ( DO NOT DISTURB ) category
            or in any other categories of privacy intuition of any Governing Laws. The client
            unconditionally agrees that these messages are in compliance with relevant
            guidelines of TRAI and any other governing authority in India and NOWoad.</p>
            <p className="mb-3">NOW is not responsible for any wrong information or mobile number or email id
            provided by the Client. NOW would consider the details provided by the Client as
            final and communicate to the same. The Client has to indemnify NOW against all
            types of losses and damages incurred by NOW due to any action taken by TRAI,
            Access Provider, or any other governing authority due to any erroneous complaint
            raised by the Client on NOW with respect to the communications.</p>

            <h4 className="mb-3 mt-5">INSURANCE -</h4>
            <p className="mb-3">NOW is not liable for any claims raised by the Client or their friends, relatives,
            lawyers and families for any damages or death caused to them during travel.</p>
            <p className="mb-3">NOW is just a facilitator and would provide a third party insurance for the Client to
            choose from. The Client shall contact the insurance company directly for any claims
            or disputes. NOW shall not be held liable in case of partial acceptance or denial of
            the claims by the insurance company.</p>
            <h4 className="mb-3 mt-5">COMPLIANCE ON FOREIGN TRANSACTIONS INCLUDING LRS -</h4>
            <p className="mb-3">The Client is responsible to comply with all the laws pertaining to the foreign
            transactions. NOW is not to be held responsible on any grounds of non compliance
            by the Client.</p>
            <p className="mb-3">NOW has the right to pull out the details and data of the Client and provide them
            to the right authority under any circumstances. The Client has to prove themselves
            in the court and NOW is not liable or responsible for any damages or lawsuits filed
            by the Governing laws / courts, or the Client.</p>
            <h4 className="mb-3 mt-5">VISA REQUIREMENTS AND POLICES -</h4>
            <p className="mb-3">The Client is responsible for applying the VISA for the proposed country of visit.
            NOW is just a facilitator and might / would in certain circumstances just provide
            links for the Client to apply.</p>
            <p className="mb-3">NOW is not liable or responsible for any denial of VISA to the Client on any
            grounds. NOW would not refund any fees paid towards the VISA on the VISA
            getting cancelled for the Client and the refund of the other parts of the trip would
            depend on the cancellation policies of NOW partners.</p>
            <h4 className="mb-3 mt-5">FORCE MAJEURE -</h4>
            <p className="mb-3">On exceptional circumstances where NOW and / or the &quot;Service Provider&quot; or &quot;NOW
            Partners&quot;s may be unable to honour the confirmed bookings due to various reasons
            like act of God, labor unrest, insolvency, a pandemic, an epidemic, business
            exigencies, government decisions, terrorist activity, any operational and technical
            issues, route and flight cancellations etc. or any other reason beyond the control of
            NOW. If NOW has advance knowledge of any such situations where dishonour of
            bookings may happen, it will make its best efforts to provide similar alternative to
            the Client or refund the booking amount after deducting applicable service charges,
            if supported and refunded by that respective service operators. The Client agrees
            that NOW being merely a facilitator of the services and products booked, cannot be
            held responsible for any such Force Majeure circumstance. The Client has to contact
            the &quot;Service Provider&quot; or &quot;NOW Partners&quot; directly for any further resolutions and
            refunds.</p>
            <p className="mb-3">The Client agrees that in the event of non-confirmation of booking due to any
            technical reasons (like network downtime, disconnection with third party platforms
            such as payment gateways, banks etc.) or any other similar failures, NOW’s
            obligation shall be limited refunding the booking amount, if any, received from the
            customer. Such refund shall completely discharge NOW from all liabilities with
            respect to that transaction. Additional liabilities, if any, shall be borne by the Client.</p>
            <p className="mb-3">In no event shall NOW and be liable for any direct, indirect, punitive, incidental,
            special or consequential damages, and any other damages like damages for loss of
            use, data or profits, arising out of or in any way connected with the use or
            performance of the Website or any other Sales Channel.</p>

            <h4 className="mb-3 mt-5">RIGHT TO REFUSE BY NOW -</h4>

            <p className="mb-3">NOW has the right to refuse any bookings without assigning any reason thereof.</p>
            <p className="mb-3">NOW will not provide any service or share confirmed booking details till such time
            the complete consideration is received from the Client.</p>
            <p className="mb-3">NOW under this agreement or under applicable Law has the right to cancel any
            Client , suspend Client, limit access to the Client from the NOW web site.</p>
            <p className="mb-3">NOW also has the right to inform the Law and other Governing authorities if any
            Client is found providing wrong information , found tampering with the web site or
            trying to resell without previous consent from NOW.</p>
            <p className="mb-3">Any breach of this agreement, NOW holds the right to penalise the Client, and
            charge with necessary action with the consent and support of the legal authorities
            of the respective jurisdiction.</p>
            <p className="mb-3">The Client shall not write to NOW , its associates, partners , sales channels, using
            language or content which is abusive, threatening, defamatory, coercive, obscene,
            belligerent, glorifying, violence, vulgar, sexually explicit, pornographic, illicit or
            otherwise objectionable, contrary to any applicable law, violates third party
            intellectual property rights, a spam, or in breach of any other part of these terms
            and conditions of use.</p>
            <p className="mb-3">NOW would take legal action against any Client who does not comply with the
            above.</p>
            <p className="mb-3">In circumstances of the Service Providers or NOW Partners refusing to provide the
            requested services assigning any reasons or owing to reasons including but not
            limited to behavioural issues, public safety, health hazards, infectious diseases,
            government orders/notification etc, the Client has to take it with the legal
            authorities outside the purview of NOW. The Client also agrees that any claims
            refund or damages against such refusal shall lie solely against the end Service
            provider or NOW Partner and not NOW.</p>
    
            <h4 className="mb-3 mt-5">LINKED WEBSITES AND ADVERTISERS -</h4>
            <p className="mb-3">NOW website would have third party websites and advertisers linked. NOW is not
            liable or responsible for any fraudulent activities done on these sites. The Client has
            to study them well, understand and it is the responsibility of the Client to deal with
            them. Any claims or damages against such frauds lie solely against the advertiser
            and the third party websites and not on NOW.</p>
            <h4 className="mb-3 mt-5">FAKE CALLS AND FRAUDULENT ACTIVITIES -</h4>
            <p className="mb-3">NOW nor its partners would call or message or mail asking for any personal information , banking
            information, credit card pin, net banking pin etc from the Client. NOW is not liable if the Client passes
            these information to fake calls. Even if NOW partner or their employees call it is the responsibility of the
            Client not to share these information. NOW is not liable for any damages occurred on these grounds.</p>
            <p className="mb-3">NOW would not promote anything other than their website or their owned social
            media sites. NOW is not liable for any fraudulent activities done apart from the
            above mentioned places.</p>
            <h4 className="mb-3 mt-5">PANDEMIC RELATED TERMS AND CONDITIONS -</h4>
            <p className="mb-3">The Client has to oblige and follow all pandemic related precautions. NOW is not
            liable for any refund or refusal if any of its Service Provider or NOW Partners do not
            extend the services to the Client for not following the protocols.</p>
            <p className="mb-3">The Client has to follow the detailed set of guidelines or standard operating
            procedure (SOP) that a traveler will have to follow in order to undertake the travel.</p>
            <p className="mb-3">TERMS OF SERVICE FOR Airlines, Charter flights, Hotels, Bus, CABs, Train, VISA
            Services, Service Providers, Tour Operators, NOW Partners, Self Drive Cabs etc -
             As mentioned earlier NOW would act just like a facilitator for the above mentioned
            services. It would act like a link for Client to book their required services through
            NOW website, Applications.</p>
            <p className="mb-3">NOW is not liable for any losses, damages, to the Client occurred before, on and
            after the trip/ tour etc.</p>
            <p className="mb-3">NOW is not liable for any refunds other than the ones applicable by the above
            mentioned Service Providers or NOW Partners. NOW will charge a convenience fee
            for all bookings and cancellations. The Client has strictly adhere to all instructions
            provided by the above mentioned Service Providers or NOW Partners before
            booking their services.</p>
            <h4 className="mb-3 mt-5">DISPUTE RESOLUTION-</h4>

            <p className="mb-3">Any dispute or difference arising out of this Agreement shall be amicably   
            settled between the Parties.</p>
            <p className="mb-3">In case of non-settlement of dispute or difference, relating to the
            interpretation and application of the Provisions of this Agreement, such dispute or
            difference shall be settled by Arbitration/ by a Sole Arbitrator as may be agreed to
            by the Parties and shall be governed by the provisions of the Arbitration &amp;
            Conciliation Act, 1996 as amended viz. The Arbitration and Conciliation
            (Amendment) Act, 2015.</p>
            <p className="mb-3">The parties to the dispute shall share equally the Cost of Arbitration as
            intimated by the Arbitrator.</p>
            <p className="mb-3">Arbitration proceedings shall be held at Bangalore, India and the language of
            the arbitration proceedings and that of all documents and communications between
            the parties shall be English.</p>

            <h4 className="mb-3 mt-5">How we use cookies</h4>
            <p className="mb-3">A cookie is a small file which asks permission to be placed on your computer&#39;s hard
            drive. Once you agree, the file is added and the cookie helps analyze web traffic or
            lets you know when you visit a particular site. Cookies allow web applications to
            respond to you as an individual. The web application can tailor its operations to
            your needs, likes and dislikes by gathering and remembering information about
            your preferences.</p>
            <p className="mb-3">We use traffic log cookies to identify which pages are being used. This helps us
            analyse data about webpage traffic and improve our website in order to tailor it to
            customer needs. We only use this information for statistical analysis purposes and
            then the data is removed from the system.</p>
            <p className="mb-3">Overall, cookies help us provide you with a better website, by enabling us to
            monitor which pages you find useful and which you do not. A cookie in no way gives
            us access to your computer or any information about you, other than the data you
            choose to share with us.</p>
            <p className="mb-3">You can choose to accept or decline cookies. Most web browsers automatically
            accept cookies, but you can usually modify your browser setting to decline cookies
            if you prefer. This may prevent you from taking full advantage of the website.</p>
            <h4 classname='mb-3 mb-4'>Website disclaimer</h4>
            <p className="mb-3">The information contained in this website is for general information purposes only
            and while we endeavor to keep the information up to date and correct, we make no
            representations or warranties of any kind, express or implied, about the
            completeness, accuracy, reliability, suitability or availability with respect to the
            website or the information, products, services, or related graphics contained on the
            website for any purpose. Any reliance you place on such information is therefore
            strictly at your own risk.</p>
            <p className="mb-3">The information contained in this website is for general information purposes only
            and while we endeavor to keep the information up to date and correct, we make no
            representations or warranties of any kind, express or implied, about the
            completeness, accuracy, reliability, suitability or availability with respect to the
            website or the information, products, services, or related graphics contained on the
            website for any purpose. Any reliance you place on such information is therefore
            strictly at your own risk.</p>
            <p className="mb-3">In no event will we be liable for any loss or damage including without limitation,
            indirect or consequential loss or damage, or any loss or damage whatsoever arising
            from loss of data or profits arising out of, or in connection with, the use of this
            website.</p>
            <p className="mb-3">Through this website you are able to link to other websites which are not under the
            control of this business. We have no control over the nature, content and
            availability of those sites. The inclusion of any links does not necessarily imply a
            recommendation or endorse the views expressed within them.
            Every effort is made to keep the website up and running smoothly. However, we
            takes no responsibility for, and will not be liable for, the website being temporarily
            unavailable due to technical issues beyond our control.
            Cancellation &amp; Refund Policy: Cancellation and Refund policy depends on the hotels
            and resorts where the reservation has been made, and it varies from hotel to a
            hotel and factors such as the seasonality, duration between the date of cancellation
            and the check in date, etc.</p>
            <h4 className="mb-3 mt-5">WAIVER</h4>
            <p className="mb-3">The waiver by either party of breach, default, delay, or omission of any of the
            provisions of this agreement by the other party will not be construed as a waiver of
            any subsequent breach of the same or other provisions.</p>
            <p className="mb-3">I declare that I am above 18years of age and avail your services out of my own free
            will without coercion.</p>
            <p className="mb-3">I shall be held responsible for any misrepresentation.</p>
            <ul>
            <li className="mb-5">I agree and accept the terms and conditions.</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Client;
