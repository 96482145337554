import React from 'react';
import {Link} from 'react-router-dom';
import './index.css';

const MagazineSection = () => {
    const handleClick = () => {
        // Handle click event, maybe navigate to a different page or open a modal
    };

    return (
        <div className="magazine-section">
            <div className="content">
                <h1 className="heading">CLUB NEWS</h1>
                <div className='row-flex'>
                <div className='article-vertical-line'></div>
                <p className="subheading">
                    Be inspired by browsing our location recommendations, guides, food & drink as well as property highlights.
                </p>
                </div>
                <div className="article">
                    <div className="main-article-image"></div>
                    <div className="article-info">
                        <h2 className="article-title">The Sunday Times with Tim Henman</h2>
                        <p className="article-date">4th August, 2024</p>
                        <p className="article-description">
                            The Times features why Tim Henman invested with The Hideaways Club and how it’s allowed him to travel the world without the hassles of sole ...
                        </p>
                        <button className="read-more" onClick={handleClick}>Read more →</button>
                    </div>
                </div>
                <div className="article-grid">
                    <div className="small-article">
                        <div className="article-image" style={{ backgroundImage: `url('/club-hero.avif')` }}></div>
                        <div className="small-article-info">
                            <h3 className="article-title">The Journal Summer 2024</h3>
                            <p className="article-date">8th July, 2024</p>
                            <p className="article-description">We are delighted to present the summer edition of The Journal. In this ...</p>
                            <button className="read-more" onClick={handleClick}>Read more →</button>
                        </div>
                    </div>
                    <div className="small-article">
                        <div className="article-image" style={{ backgroundImage: `url('/tu-7-1.avif')` }}></div>
                        <div className="small-article-info">
                            <h3 className="article-title">From Kalkan to Canyon</h3>
                            <p className="article-date">4th July, 2024</p>
                            <p className="article-description">Just a 30-minute drive from the charming town of Kalkan lies Saklikent National ...</p>
                            <button className="read-more" onClick={handleClick}>Read more →</button>
                        </div>
                    </div>
                </div>
                <div className='portfolio-btn'>
                   <Link to='/club-news'><button onClick={handleClick}>All articles →</button></Link>
                </div>
            </div>
        </div>
    );
};

export default MagazineSection;
