import React,{useState,useEffect} from 'react';
import {Link} from 'react-router-dom';
import { IoMdHome } from "react-icons/io";
import { FaArrowRight } from "react-icons/fa";
import './index.css';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyB4BPMHKw0OXM2PhQX9NcT25lDBWpRpIPQ",
    authDomain: "phone-auth-d0a8d.firebaseapp.com",
    projectId: "phone-auth-d0a8d",
    storageBucket: "phone-auth-d0a8d.appspot.com",
    messagingSenderId: "309252587015",
    appId: "1:309252587015:web:3f9dc2505965e66269b4a2"
  };
  
  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
  }
  
  const db = firebase.firestore();

const Wilderness = () => {
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [place, setPlace] = useState('');
    const [states, setStates] = useState([]);
    const [places, setPlaces] = useState([]);
    const [cities, setCities] = useState([]);
    const [wildernessTrendingPlaces, setWildernessTrendingPlaces] = useState([]);
    const [wildernessWeekendPlaces, setWildernessWeekendPlaces] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
      const fetchWildernessTrendingPlaces = async () => {
          try {
              const snapshot = await firebase.firestore().collection('wildernessTrendingPlaces').get();
              const data = snapshot.docs.map((doc) => ({
                  id: doc.id,
                  ...doc.data()
              }));
              setWildernessTrendingPlaces(data);
              setIsLoading(false);
          } catch (error) {
              console.error('Error fetching trending places: ', error);
          }
      };

      fetchWildernessTrendingPlaces();
  }, []);

  useEffect(() => {
    const fetchWildernessWeekendPlaces = async () => {
        try {
            const snapshot = await firebase.firestore().collection('wildernessWeekendPlaces').get();
            const data = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data()
            }));
            setWildernessWeekendPlaces(data);
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching trending places: ', error);
        }
    };

    fetchWildernessWeekendPlaces();
}, []);

  useEffect(() => {
    const fetchStates = async () => {
      try {
        const statesSnapshot = await db.collection('WeekendGateways').get();
        const statesData = statesSnapshot.docs.map(doc => doc.id);
        setStates(statesData);
      } catch (error) {
        console.error('Error fetching states: ', error);
      }
    };

    fetchStates();
  }, []);


  const handleStateChange = async (e) => {
    const selectedState = e.target.value;
    setState(selectedState);

    try {
      const citiesSnapshot = await db.collection('WeekendGateways').doc(selectedState).collection(selectedState).get();
      const citiesData = citiesSnapshot.docs.map(doc => doc.id);
      setCities(citiesData);
      setPlaces([]);
    } catch (error) {
      console.error('Error fetching places: ', error);
    }
  };

  const handleCityChange = async (e) => {
    const selectedCity = e.target.value;
    setCity(selectedCity);

    try {
      const placesSnapshot = await db.collection('WeekendGateways').doc(state).collection(state).doc(selectedCity).collection(selectedCity).get();
      const placesData = placesSnapshot.docs.map(doc => doc.id);
      setPlaces(placesData);
    } catch (error) {
      console.error('Error fetching places: ', error);
    }
  };

      const handleNextClick = () => {
        if (place) {
          const formattedState = state.replace(/\s+/g, '-');
          const formattedCity = city.replace(/\s+/g, '-');
          const formattedPlace = place.replace(/\s+/g, '-');
          window.location.href = `/weekends/${formattedState}/${formattedCity}/${formattedPlace}`;
        }
    };

    if (isLoading) {
      return(
        <div className='loading-container'>
        <p className='loading'></p>
        </div>
      )
    }


    return (
        <div>
            <div className='banner-container'>
            <img src='https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/Weekedn%20Getaways%20home%20page%20image.jpg?alt=media&token=9c2d0a7f-ba6b-4cdc-a427-9f613521e65f' alt='' className='nature-img'/>
                <nav className="nature-nav">
                    <div className='logo'>
                    <Link to='/'><img src="/logo.png" alt="Logo"/></Link>
                    </div>
                    <Link to='/now'><div className='mb-2'><button className='icon-button'><IoMdHome size={15}/></button></div></Link> 
                </nav>
          <div>
            <h1 className='banner-text'>Weekend Gateways</h1>
          </div>
          <div className='container-list'>
          <div className='input-container'>
          <select value={state} onChange={handleStateChange}>
            <option value="" disabled>State</option>
            {states.map(state => (
              <option key={state} value={state}>
                {state}
              </option>
            ))}
          </select>
        </div>
          <div className='input-container'>
            <select value={city} onChange={handleCityChange}>
                <option value="">City</option>
                {cities.map(city => (
                <option key={city} value={city}>
                {city}
              </option>
              ))}
            </select>
          </div>
        <div className='input-container'>
          <select value={place} onChange={(e) => setPlace(e.target.value)}>
            <option value="" >Place</option>
            {places.map(place => (
              <option key={place} value={place}>
                {place}
              </option>
            ))}
          </select>
        </div>
          </div>
          <div className='next-button-container'>
           <button className='icon-button' onClick={handleNextClick}><FaArrowRight size={15}/></button>
          </div>
            </div>
            <div className='container'>
          <div className='row'>
            {wildernessTrendingPlaces.map((data, index) => (
              <div key={index} className='col-6 col-md-3 col-lg-3 col-xl-3 img-container'>
                <img src={data.imageUrl} alt='' className='package-img' />
                <Link to={`/weekends/${data.state.replace(/\s+/g, '-')}/${data.city.replace(/\s+/g, '-')}/${data.trendingPlace.replace(/\s+/g, '-')}`}>
                  <button type='button' className='img-button'>{data.trendingPlace}</button>
                </Link>
              </div>
            ))}
            {wildernessWeekendPlaces.map((data, index) => (
              <div key={index} className='col-6 col-md-3 col-lg-3 col-xl-3 img-container'>
                <img src={data.imageUrl} alt='' className='package-img' />
                <Link to={`/weekends/${data.state.replace(/\s+/g, '-')}/${data.city.replace(/\s+/g, '-')}/${data.trendingPlace.replace(/\s+/g, '-')}`}>
                  <button type='button' className='img-button'>{data.trendingPlace}</button>
                </Link>
              </div>
            ))}
            <button type='button' className='trending-button'>Trending</button>
            <button type='button' className='weekend-button'>Weekend Deals</button>
          </div>
        </div>
      </div>
    );
}

export default Wilderness;