import React, { useRef, useState, useEffect } from 'react';
import ClubHeader from '../ClubHeader';
import ClubFooter from '../ClubFooter';
import { FaArrowRightLong } from "react-icons/fa6";
import { Swiper, SwiperSlide } from "swiper/react";
import {NavLink, Link} from 'react-router-dom';
import { MdOutlineTravelExplore } from "react-icons/md";
import { FaWpexplorer } from "react-icons/fa6";
import { MdOutlineVilla } from "react-icons/md";
import { FaUserLock } from "react-icons/fa6";
import 'swiper/css';
import './index.css';

const cardsData = [
  {
    title: 'Travel the World with Elegance',
    icon: MdOutlineTravelExplore ,
    content: 'Enjoy a diverse range of destinations and experiences, all from the comfort of our impeccably maintained properties.',
  },
  {
    title: 'Bespoke Comfort',
    icon: FaWpexplorer ,
    content: 'Explore and enjoy some of the world’s premier holiday destinations, all without the burden of upkeep and maintenance.',
  },
  {
    title: 'Personalized Luxury',
    icon: MdOutlineVilla ,
    content: 'Each home is prepared to your personal requirements, ensuring a seamless and indulgent stay.',
  },
  {
    title: 'Fractional Ownership of Premium Properties',
    icon: FaUserLock ,
    content: 'You’re not just owning a single home; you’re co-owner of an exclusive collection of luxury holiday residences.',
  },
];

const images = [
    "/img-1.avif",
    "/img-2.avif",
    "/img-3.avif",
]

const ClubPortfolio = () => {
    const clubHomesSwiperRef = useRef(null);
    const partnerHomesSwiperRef = useRef(null);
    const [isBeginning, setIsBeginning] = useState(true);
    const [isEnd, setIsEnd] = useState(false);

    const handlePrev = (swiperRef) => {
        if (swiperRef.current) {
          swiperRef.current.swiper.slidePrev();
        }
      };
    
    const handleNext = (swiperRef) => {
        if (swiperRef.current) {
          swiperRef.current.swiper.slideNext();
        }
      };

      useEffect(() => {
        if (clubHomesSwiperRef.current) {
          const swiper = clubHomesSwiperRef.current.swiper;
    
          swiper.on('slideChange', () => {
            setIsBeginning(swiper.isBeginning);
            setIsEnd(swiper.isEnd);
          });
        }
      }, []);

      useEffect(() => {
        if (partnerHomesSwiperRef.current) {
          const swiper = partnerHomesSwiperRef.current.swiper;
    
          swiper.on('slideChange', () => {
            setIsBeginning(swiper.isBeginning);
            setIsEnd(swiper.isEnd);
          });
        }
      }, []);

      const getNavLinkClass = ({ isActive }) =>
        isActive ? 'active-link' : '';
    

    return (
        <>
        <ClubHeader/>
        <div className='club-portfolio'>
            <img src='/club-port.avif' alt='' className='club-portfolio-img'/>
        </div>
        <div className='club-portfolio-section'> 
            <h1>PORTFOLIO</h1>
            <h4>Fractional Holiday Home Ownership with The Travel Unbounded Club</h4>
            <h4>Experience the World in Luxury</h4>
            <p>Why settle for just another yearly holiday when you can enjoy a diverse portfolio of
            luxurious residences around the globe? With The Travel Unbounded Club, you gain
            access to a range of exclusive holiday villas &amp; homes, allowing you to tailor your vacations
            to every season and preference.</p>
            <p>Whether you crave a holiday in the hills, sun-drenched pristine beaches, thrilling
            wilderness adventures, or vibrant city explorations, our Club homes offer it all.</p>
        </div>
        <h1 className='text-center'>Why Choose Us?</h1>
        <div className='why-choose'>
          {cardsData.map((card, index) => (
            <div className='why-choose-card' key={index}>
              <div className='why-choose-icon'><card.icon size={50} /></div>
              <div className='why-choose-line'></div>
              <h4>{card.title}</h4>
              <p>{card.content}</p>
            </div>
          ))}
          <p className='why-choose-text mt-5'>Embark on a global journey with unparalleled peace of mind and refined sophistication,
          assured that every residence epitomises the pinnacle of luxury and meticulous
          craftsmanship.</p>
          <p className='why-choose-text'>Join The Travel Unbounded Club today and discover a new way to experience the world.</p>
          </div>
        <div className='portfolio-slider-container'>
            <div className='portfolio-text-container'>
                <h1>TU Club Homes</h1>
                <p>Private, spacious, and crafted with luxury in mind, our Club villas and chalets warmly
                welcome Owners to their idyllic destinations, starting with select locations in India.</p>
                <div className="portfolio-btn" style={{justifyContent:'flex-start'}}>
                   <NavLink to='/club-homes' className={getNavLinkClass}> <button>View TU Club Homes <FaArrowRightLong style={{ color: 'brown' }} /></button></NavLink>
                </div>
            </div>
        <div className='portfolio-card-container'>
        <Swiper
        ref={clubHomesSwiperRef}
        breakpoints={{
          1024: {
            slidesPerView: 1.1,
            spaceBetween: 10,
          },
          768: {
            slidesPerView: 1.1,
            spaceBetween: 10,
          },
          640: {
            slidesPerView: 1.1,
            spaceBetween: 5,
          },
          320: {
            slidesPerView: 1.1,
            spaceBetween: 5,
          }
        }}
      >
        {images.map((img, index) => (
          <SwiperSlide key={index}>
            <div className="portfolio-card">
              <img
                src={img}
                alt=''
                className="portfolio-image"
              />
            </div>
          </SwiperSlide>
        ))}
         <div className='swiper-buttons-container-portfolio'>
            <div className={`swiper-button-prev-portfolio ${isBeginning ? "disabled" : ""}`} onClick={() => handlePrev(clubHomesSwiperRef)}>←</div>
            <div className={`swiper-button-next-portfolio ${isEnd ? "disabled" : ""}`} onClick={() => handleNext(clubHomesSwiperRef)}>→</div>
        </div>
      </Swiper>
        </div>
        </div>
        <div className='portfolio-register'>
        <div className='club-testimonial-section'>
        <div className='club-testimonial-section-flex-2' style={{order:'1'}}>
            <img src='https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/tu-club%2FTU%20CLUB%202nd%20page%20image.jpg?alt=media&token=8ed9f570-5758-4d4b-9b9b-d4a069acc2dc' alt='' className='club-testimonial-section-img'/>
            </div>
            <div className='club-testimonial-section-flex-1' style={{order:'2'}}>
                <h1 className='mt-3'>Register to TU CLUB</h1>
                <p>If the concept of second home ownership intrigues you, why commit significant capital to a
                single holiday property in one location, along with the maintenance hassles from afar?
                Choose the smart way — join The Travel Unbounded Club.</p>
                <p>Register now to explore our membership plans and find the perfect option for your travel
                needs.</p>
                <div className='explore-btn'>
                <Link to='/club-register'><button>Register to TU-Club <FaArrowRightLong style={{color:'brown'}}/></button></Link>
                </div>
            </div>
            </div>
            </div>
        <div className='portfolio-slider-container'>
            <div className='portfolio-text-container'>
                <h1>Our prestigious associates</h1>
                <p>Thanks to our expanding network of specially negotiated affiliations, Co-owners can book
                and enjoy access to exquisitely presented resorts in exotic destinations, including locations
                where our own homes are also not present.</p>
                <div className="portfolio-btn" style={{justifyContent:'flex-start'}}>
                    <Link to='/associate-homes'><button>View Associate Homes <FaArrowRightLong style={{ color: 'brown' }} /></button></Link>
                </div>
            </div>
        <div className='portfolio-card-container'>
        <Swiper
        ref={partnerHomesSwiperRef}
        breakpoints={{
          1024: {
            slidesPerView: 1.1,
            spaceBetween: 10,
          },
          768: {
            slidesPerView: 1.1,
            spaceBetween: 10,
          },
          640: {
            slidesPerView: 1.1,
            spaceBetween: 5,
          },
          320: {
            slidesPerView: 1.1,
            spaceBetween: 5,
          }
        }}
      >
        {images.map((img, index) => (
          <SwiperSlide key={index}>
            <div className="portfolio-card">
              <img
                src={img}
                alt=''
                className="portfolio-image"
              />
            </div>
          </SwiperSlide>
        ))}
        <div className='swiper-buttons-container-portfolio'>
            <div className={`swiper-button-prev-portfolio ${isBeginning ? "disabled" : ""}`} onClick={() => handlePrev(partnerHomesSwiperRef)}>←</div>
            <div className={`swiper-button-next-portfolio ${isEnd ? "disabled" : ""}`} onClick={() => handleNext(partnerHomesSwiperRef)}>→</div>
        </div>
      </Swiper>
        </div>
        </div>
        <ClubFooter/> 
        </>
    );
}

export default ClubPortfolio;
