import React, { useRef, useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/css';
import "./index.css";
import { FaBed, FaBath, FaRulerCombined } from "react-icons/fa";
import { FaArrowRightLong } from "react-icons/fa6";
import {Link} from 'react-router-dom';

const Club4 = () => {
  const swiperRef = useRef(null);
  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(false);

  const properties = [
    {
      image: "/img-1.avif",
      title: "Casa Paradiso",
      location: "Laveno, Italy",
      beds: 5,
      baths: 3,
      size: "4000 sqft",
    },
    {
      image: "/img-2.avif",
      title: "Villa La Solana",
      location: "Ibiza, Spain",
      beds: 4,
      baths: 3,
      size: "3500 sqft",
    },
    {
      image: "/img-3.avif",
      title: "Gran Vista",
      location: "Mallorca, Spain",
      beds: 6,
      baths: 5,
      size: "5000 sqft",
    },
    {
      image: "/img-1.avif",
      title: "La Retraite",
      location: "Le Rouret, France",
      beds: 4,
      baths: 3,
      size: "4000 sqft",
    },
    {
      image: "/img-2.avif",
      title: "Kentroma House",
      location: "Corfu, Greece",
      beds: 4,
      baths: 3,
      size: "3500 sqft",
    },
    {
      image: "/img-3.avif",
      title: "Masseria L'Olivo",
      location: "Puglia, Italy",
      beds: 5,
      baths: 5,
      size: "5000 sqft",
    },
  ];

  const handlePrev = () => {
    if (swiperRef.current) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const handleNext = () => {
    if (swiperRef.current) {
      swiperRef.current.swiper.slideNext();
    }
  };

  useEffect(() => {
    if (swiperRef.current) {
      const swiper = swiperRef.current.swiper;

      swiper.on('slideChange', () => {
        setIsBeginning(swiper.isBeginning);
        setIsEnd(swiper.isEnd);
      });
    }
  }, []);

  return (
    <div className="portfolio-slider">
      <h1 className="portfolio-head">OUR PORTFOLIO</h1>
      <div className="arrows-container">
        <div className="vertical-line"></div>
        <p className="portfolio-text">
          Private, spacious and designed with luxury in mind, Club villas and chalets welcome Owners home in idyllic destinations from Tuscany to Niseko and Provence to Bali.
        </p>
        <div
          className={`swiper-button-prev ${isBeginning ? "disabled" : ""}`}
          onClick={handlePrev}
        >
          ←
        </div>
        <div
          className={`swiper-button-next ${isEnd ? "disabled" : ""}`}
          onClick={handleNext}
        >
          →
        </div>
      </div>
      <Swiper
        ref={swiperRef}
        breakpoints={{
          1024: {
            slidesPerView: 3.3,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 10,
          },
          640: {
            slidesPerView: 1.1,
            spaceBetween: 5,
          },
          320: {
            slidesPerView: 1.1,
            spaceBetween: 5,
          }
        }}
      >
        {properties.map((property, index) => (
          <SwiperSlide key={index}>
            <div className="property-card">
              <img
                src={property.image}
                alt={property.title}
                className="property-image"
              />
              <div className="property-info">
                <h3>{property.title}</h3>
                <p>{property.location}</p>
                <div className="property-details">
                  <FaBed /> {property.beds} Beds &nbsp; | &nbsp;
                  <FaBath /> {property.baths} Baths &nbsp; | &nbsp;
                  <FaRulerCombined /> {property.size}
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="portfolio-btn">
        <Link to='/club-portfolio'><button>View full portfolio <FaArrowRightLong style={{ color: 'brown' }} /></button></Link>
      </div>
    </div>
  );
};

export default Club4;
