import React, { useState } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import './index.css';

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const location = useLocation();

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const getNavLinkClass = ({ isActive }, path) => {
    const { pathname } = location;
    if ((pathname.startsWith('/club-homes') || pathname.startsWith('/associate-homes')) && path === '/club-portfolio') {
      return 'active-link';
    }
    return isActive ? 'active-link' : '';
  };

  return (
    <header className='header-2'>
      <div className="logo">
        <Link to="/"><img src="/logo.png" alt="Travel Unbounded Club" style={{filter:'brightness(100%)'}}/></Link>
      </div>
      <div className="desktop-nav-2">
        <div className="header-right-2">
          <Link to="/club-owner-login" className="owner-login-2">Owner Login</Link>
          <Link to="/club-register" className="request-brochure-2">Register to club</Link>
        </div>
        <nav className="nav-2">
          <ul>
            <li><NavLink to="/club-home" className={(navData) => getNavLinkClass(navData, '/tu-club-home')}>TU CLUB</NavLink></li>
            <li><NavLink to='/club-portfolio' className={(navData) => getNavLinkClass(navData, '/club-portfolio')}>PORTFOLIO</NavLink></li>
            <li><NavLink to='/club-service' className={(navData) => getNavLinkClass(navData, '/club-service')}>SERVICE</NavLink></li>
            <li><NavLink to="/club-testimonials" className={(navData) => getNavLinkClass(navData, '/club-testimonials')}>TESTIMONIALS</NavLink></li>
            <li><NavLink to="/club-plans" className={(navData) => getNavLinkClass(navData, '/club-plans')}>PLANS</NavLink></li>
            <li><NavLink to="/now" className={(navData) => getNavLinkClass(navData, '/now')}>NOW</NavLink></li>
            <li><NavLink to='/club-news' className={(navData) => getNavLinkClass(navData, '/club-news')}>CLUB NEWS</NavLink></li>
          </ul>
        </nav>
      </div>
      <div className="menu-toggle-2" onClick={toggleMenu}>
        <div className={`hamburger-2 ${menuOpen ? 'open' : ''}`}>
          <span className="bar"></span>
          <span className="bar"></span>
          <span className="bar"></span>
        </div>
      </div>
      <nav className={`mobile-nav-2 ${menuOpen ? 'open' : ''}`}>
        <ul>
          <li><NavLink to="/club-home" className={(navData) => getNavLinkClass(navData, '/tu-club-home')} onClick={toggleMenu}>TU CLUB</NavLink></li>
          <li><NavLink to='/club-portfolio' className={(navData) => getNavLinkClass(navData, '/club-portfolio')} onClick={toggleMenu}>PORTFOLIO</NavLink></li>
          <li><NavLink to='/club-service' className={(navData) => getNavLinkClass(navData, '/club-service')} onClick={toggleMenu}>SERVICE</NavLink></li>
          <li><NavLink to="/club-testimonials" className={(navData) => getNavLinkClass(navData, '/club-testimonials')} onClick={toggleMenu}>TESTIMONIALS</NavLink></li>
          <li><NavLink to="/club-plans" className={(navData) => getNavLinkClass(navData, '/club-plans')} onClick={toggleMenu}>PLANS</NavLink></li>
          <li><NavLink to="/now" className={(navData) => getNavLinkClass(navData, '/now')} onClick={toggleMenu}>NOW</NavLink></li>
          <li><NavLink to='/club-news' className={(navData) => getNavLinkClass(navData, '/club-news')} onClick={toggleMenu}>CLUB NEWS</NavLink></li>
        </ul>
        <div className="mobile-header-right-2">
          <Link to='/club-owner-login' className="owner-login-2" onClick={toggleMenu}>OWNER LOGIN</Link>
        </div>
      </nav>
    </header>
  );
};

export default Header;
