import React, { useState } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { FiX } from 'react-icons/fi';
import './index.css';
import axios from 'axios';
import StarRating from '../StarRating'
import { toast, Toaster } from "react-hot-toast";


const firebaseConfig = {
    apiKey: "AIzaSyB4BPMHKw0OXM2PhQX9NcT25lDBWpRpIPQ",
    authDomain: "phone-auth-d0a8d.firebaseapp.com",
    projectId: "phone-auth-d0a8d",
    storageBucket: "phone-auth-d0a8d.appspot.com",
    messagingSenderId: "309252587015",
    appId: "1:309252587015:web:3f9dc2505965e66269b4a2"
};

if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
}

const RetrieveBooking = () => {
    const [bookingId, setBookingId] = useState('');
    const [showFeedbackPopup, setShowFeedbackPopup] = useState(false);
    const [feedback, setFeedback] = useState('');
    const [starRating, setStarRating] = useState(0);
    const [currentRating, setCurrentRating] = useState(0);
    const [ratingCount, setRatingCount] = useState(0);
    const [showPopup, setShowPopup] = useState(false);
    const [showPolicyPopup, setShowPolicyPopup] = useState(false);
    const [showOtpPopup, setShowOtpPopup] = useState(false);
    const [otp, setOtp] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [confirmationResult, setConfirmationResult] = useState(null);
    const [booking, setBooking] = useState([]);
    const [hotelData, setHotelData] = useState({});

    const handleRetrieveBooking = async () => {
        try {
            const bookingSnapshot = await firebase.firestore().collection('bookings')
                .where('bookingId', '==', bookingId)
                .limit(1)
                .get();
    
            if (!bookingSnapshot.empty) {
                const bookingDoc = bookingSnapshot.docs[0];
                const bookingData = bookingDoc.data();
                const bookingId = bookingDoc.id;
                setBooking({ ...bookingData, id: bookingId });
                toast.success('Booking details retrieved successfully!');
                fetchHotelData({ ...bookingData, id: bookingId });
            } else {
                toast.error('No booking found with this ID.');
            }
        } catch (error) {
            console.error('Error retrieving booking:', error);
            toast.error('Error retrieving booking. Please try again later.');
        }
    };
    
    const fetchHotelData = async (bookingData) => {
        try {
            const { categoryPath, state, city, place, hotelName } = bookingData;
            const hotelRefPath = categoryPath === 'WeekendGateways'
                ? firebase.firestore()
                    .collection(categoryPath)
                    .doc(state)
                    .collection(state)
                    .doc(city)
                    .collection(city)
                    .doc(place)
                    .collection('hotels')
                    .doc(hotelName)
                : firebase.firestore()
                    .collection(categoryPath)
                    .doc(state)
                    .collection(state)
                    .doc(place)
                    .collection('hotels')
                    .doc(hotelName);

            const hotelSnapshot = await hotelRefPath.get();
            if (hotelSnapshot.exists) {
                setHotelData(hotelSnapshot.data());
            } else {
                console.log("No such document!");
            }
        } catch (error) {
            console.error('Error fetching hotel data:', error);
        }
    };

    const handleSubmitFeedback = async (e) => {
        e.preventDefault();

        if (booking) {
            const { categoryPath, state, city, place, hotelName } = booking;

            const newRating = ((currentRating * ratingCount) + starRating) / (ratingCount + 1);
            const newRatingCount = ratingCount + 1;

            setRatingCount(newRatingCount);
            setCurrentRating(newRating);

            setFeedback('');
            setStarRating(newRating);

            setShowFeedbackPopup(false);

            const hotelRefPath = categoryPath === 'WeekendGateways'
                ? firebase.firestore()
                    .collection(categoryPath)
                    .doc(state)
                    .collection(state)
                    .doc(city)
                    .collection(city)
                    .doc(place)
                    .collection('hotels')
                    .doc(hotelName)
                : firebase.firestore()
                    .collection(categoryPath)
                    .doc(state)
                    .collection(state)
                    .doc(place)
                    .collection('hotels')
                    .doc(hotelName);

            const hotelSnapshot = await hotelRefPath.get();
            const existingData = hotelSnapshot.data();

            const totalRating = (existingData.totalRating || 0) + starRating;
            const totalFeedback = (existingData.totalFeedback || []).concat({ rating: starRating, feedback });
            const averageRating = totalRating / newRatingCount;
            const TURating = Math.min(5, Math.max(0, averageRating)).toFixed(1);

            await hotelRefPath.update({
                totalRating,
                totalFeedback,
                TURating,
            });

            toast.success('Feedback submitted successfully');
        } else {
            toast.error('No booking selected for feedback');
        }
    };

    const handleCancel = () => {
        setShowPopup(true);
    };

    const handleConfirmCancel = () => {
        setShowPopup(false);
        setShowPolicyPopup(true);
    };

    const handleProceedToOtp = () => {
        setShowPolicyPopup(false);
        setShowOtpPopup(true);
    };

    const handleCancelBooking = () => {
        setShowPopup(false);
        setShowPolicyPopup(false);
        setShowOtpPopup(false);
    };

    const sendOtp = async (e) => {
        e.preventDefault();
        try {
            const appVerifier = new firebase.auth.RecaptchaVerifier("recaptcha-container");
            const confirmation = await firebase.auth().signInWithPhoneNumber(`+91${phoneNumber}`, appVerifier);
            setConfirmationResult(confirmation);
            toast.success("OTP sent successfully");
        } catch (error) {
            console.error("Error sending OTP:", error);
        }
    };

    const verifyOtp = async (e) => {
        e.preventDefault();
        try {
            await confirmationResult.confirm(otp);
            handleCancelConfirmed();
            toast.success("OTP Verified Successfully");
            setOtp("");
        } catch (error) {
            console.error("Error verifying OTP:", error);
            toast.error("Please Enter Correct OTP");
        }
    };

    const handleCancelConfirmed = async () => {
        if (booking) {
            try {
                await firebase.firestore().collection('bookings').doc(booking.id).update({ cancelled: true });
                setBooking((prevBooking) => ({ ...prevBooking, cancelled: true }));
                setShowOtpPopup(false);
                toast.success('Booking cancelled successfully!');
                sendEmailNotification();
            } catch (error) {
                console.error('Error updating booking:', error);
                toast.error('Failed to cancel booking. Please try again.');
            }
        }
    };

    const sendEmailNotification = async () => {
        try {
            if (booking) {
                const response = await axios.post('https://formbackend.netlify.app/cancellationEmail', {
                    email: booking.email,
                    name: booking.leadTravelerName,
                    category: booking.category,
                    hotelMail: booking.hotelMail,
                    state: booking.state,
                    hotelId: booking.hotelName,
                    contact: booking.contact,
                    bookingId: booking.bookingId,
                    place: booking.place,
                    checkin: booking.checkin,
                    checkout: booking.checkout,
                    numAdults:booking.totalAdults,
                    numKids:booking.totalKids,
                    numRooms: booking.numRooms,
                    totalPrice: booking.totalPrice,
                });
                console.log(response.data);

                if (response.status===200) {
                    await firebase.firestore().collection('cancellations').add({
                        userId: null,
                        email: booking.email,
                        name:booking.leadTravelerName,
                        state:booking.state,
                        hotelId:booking.hotelName,
                        hotelMail:booking.hotelMail,
                        category:booking.category,
                        contact:booking.contact,
                        bookingId: booking.bookingId,
                        place: booking.place,
                        checkin: booking.checkin,
                        checkout: booking.checkout,
                        numAdults:booking.totalAdults,
                        numKids:booking.totalKids,
                        numRooms: booking.numRooms,
                        totalPrice: booking.totalPrice,
                        cancelledAt: firebase.firestore.FieldValue.serverTimestamp(),
                    });
                } else {
                   console.log("Error storing cancellation data")
                }
            }
        } catch (error) {
            console.error('Error sending cancellation email:', error);
        }
    };

    

    const averageRating = hotelData?.TURating || 0;
    const checkoutDate = new Date(booking.checkout);
    const isCompleted = checkoutDate < new Date();

    return (
       <div>
            <Toaster />
            <h2 className='font text-center mt-5 mb-3'>Cancel Bookings</h2>
            <div className='text-center mb-5 mt-3'>
                <label htmlFor="bookingId" className='font'>Enter Booking ID:</label>
                <input
                    type="text"
                    id="bookingId"
                    value={bookingId}
                    onChange={(e) => setBookingId(e.target.value)}
                />
                <div className='text-center mt-3'>
                    <button onClick={handleRetrieveBooking} className='btn btn-primary font' style={{ color: 'white', paddingLeft: '10px' }}>Get Bookings</button>
                </div>
            </div>
            <hr style={{ width: '100%', color: 'brown', border: '1px solid brown' }} />
            {booking && (
                        <div className='container-guest-booking'>
                            <div className='row'>
                                <div className='col-6 col-md-12 col-lg-12 col-xl-12'>
                                    <div className='details-container'>
                                        <p>Destination</p>
                                        <p>Hotel Name</p>
                                        <p>Booking ID</p>
                                        <p>Check-in</p>
                                        <p>Check-out</p>
                                        <p>No.of Adults</p>
                                        <p>No.of Kids</p>
                                        <p>Room Type</p>
                                        <p>No.of Rooms</p>
                                        <p>Amount paid</p>
                                    </div>
                                </div>
                                <div className='col-6 col-md-12 col-lg-12 col-xl-12'>
                                    <div className='details-container'>
                                        <p>{booking.place}</p>
                                        <p>{booking.hotelName}</p>
                                        <p>{booking.bookingId}</p>
                                        <p>{booking.checkin}</p>
                                        <p>{booking.checkout}</p>
                                        <p>{booking.numAdults}</p>
                                        <p>{booking.numKids}</p>
                                        <p>{booking.roomType}</p>
                                        <p>{booking.numRooms}</p>
                                        <p>{booking.totalPrice}</p>
                                    </div>
                                </div>
                                <div className='feedback-container mt-3'>
                                {booking.cancelled ? (
                                        <button className='feedback-btn-cancel' disabled>Give a feedback</button>
                                    ) : (
                                        <button
                                            className='feedback-btn'
                                            onClick={() => {
                                                setBookingId(booking.id);
                                                setShowFeedbackPopup(true);
                                            }}
                                        >
                                            Give a feedback
                                        </button>
                                    )}
                                    {booking.cancelled ? (
                                        <button className='cancel-btn-cancel' disabled>Cancelled</button>
                                    ) : (
                                        <button
                                            className={isCompleted ? 'completed-btn' : 'cancel-btn'}
                                            type='button'
                                            onClick={() => !isCompleted && handleCancel(booking.id)}
                                            disabled={isCompleted}
                                        >
                                            {isCompleted ? 'Completed' : 'Cancel Booking'}
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                        )};
                        {showPopup && (
                        <div className="popup text-center">
                            <p style={{ fontSize: '16px', fontWeight: 'bold' }} className='mt-3'>Are you sure you want to Cancel Booking?</p>
                            <div className='mt-3 mb-3'>
                                <button onClick={handleConfirmCancel} className='confirm-btn' style={{ background: 'green', color: 'white' }}>Yes</button>
                                <button onClick={handleCancelBooking} className='confirm-btn' style={{ background: 'red', color: 'white' }}>No</button>
                            </div>
                        </div>
                    )}
                      {showPolicyPopup && (
                        <div className="popup text-center">
                            <p style={{ fontSize: '16px', fontWeight: 'bold' }} className='mt-3'>Read Cancellation Policies</p>
                            {hotelData && (
                                <>
                                    <p style={{ fontSize: '14px', marginTop: '10px' }}>{hotelData.cp1}</p>
                                    <p style={{ fontSize: '14px', marginTop: '10px' }}>{hotelData.cp2}</p>
                                    <p style={{ fontSize: '14px', marginTop: '10px' }}>{hotelData.cp3}</p>
                                </>
                            )}
                            <div className='mt-3 mb-3'>
                                <button onClick={handleProceedToOtp} className='confirm-btn' style={{ background: 'blue', color: 'white' }}>Proceed</button>
                                <button onClick={handleCancelBooking} className='confirm-btn' style={{ background: 'red', color: 'white' }}>Cancel</button>
                            </div>
                        </div>
                    )}
                    {showOtpPopup && (
                        <div className="popup text-center">
                            <p style={{ fontSize: '16px', fontWeight: 'bold' }} className='mt-3'>Verify Your Phone Number To Cancel Booking</p>
                            <input
                                type="text"
                                placeholder="Enter phone number"
                                onChange={(e) => setPhoneNumber(e.target.value)}
                                className='mb-2'
                            />
                            <button onClick={sendOtp} className='send-btn mb-2' style={{ background: 'green', color: 'white', border:'none' }}>Send OTP</button>
                            <input
                                type="text"
                                placeholder="Enter OTP"
                                value={otp}
                                onChange={(e) => setOtp(e.target.value)}
                            />
                            <div className='mt-3 mb-3'>
                                <button onClick={verifyOtp} className='confirm-btn' style={{ background: 'green', color: 'white' }}>Verify OTP</button>
                                <button onClick={handleCancelBooking} className='confirm-btn mt-2' style={{ background: 'red', color: 'white' }}>Cancel</button>
                            </div>
                            <div id="recaptcha-container"></div>
                        </div>
                    )}
                    {showFeedbackPopup && (
                        <div className="feedback-popup">
                            <div className="feedback-content">
                                <span className="close" onClick={() => setShowFeedbackPopup(false)}><FiX size={20} /></span>
                                <p className='font bold'>How was your experience?</p>
                                <div className="star-rating">
                                    <StarRating rating={starRating} onStarClick={setStarRating} />
                                </div>
                                <p className='font bold'>Write anything else..!</p>
                                <textarea
                                    className="feedback-textarea"
                                    placeholder="Write your feedback here..."
                                    value={feedback}
                                    onChange={(e) => setFeedback(e.target.value)}
                                />
                                <button className="submit-btn" onClick={handleSubmitFeedback}>Submit</button>
                                <p className='font text-center mt-2'>Average TU Rating: {averageRating}</p>
                            </div>
                        </div>
                    )}
            <hr style={{ width: '100%', color: 'brown', border: '1px solid brown' }} />
        </div>
    );
};

export default RetrieveBooking;