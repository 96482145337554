import React from 'react';
import { FaCheckCircle } from 'react-icons/fa';

const items = ['Item 1', 'Item 2', 'Item 3'];

const CustomList = () => {
  return (
    <ul style={{ listStyleType: 'none', padding: 0 }}>
      {items.map((item, index) => (
        <li key={index} style={{ display: 'flex', justifyContent:'center', alignItems: 'center', marginBottom: '8px'}}>
          <FaCheckCircle style={{ marginRight: '8px', color: 'lightgreen' }} />
          {item}
        </li>
      ))}
    </ul>
  );
};

export default CustomList;