import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import OtpInput from "otp-input-react";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { toast, Toaster } from "react-hot-toast";
import { FiX } from "react-icons/fi";
import "react-datepicker/dist/react-datepicker.css";
import { useParams } from "react-router-dom";

const firebaseConfig = {
    apiKey: "AIzaSyB4BPMHKw0OXM2PhQX9NcT25lDBWpRpIPQ",
    authDomain: "phone-auth-d0a8d.firebaseapp.com",
    projectId: "phone-auth-d0a8d",
    storageBucket: "phone-auth-d0a8d.appspot.com",
    messagingSenderId: "309252587015",
    appId: "1:309252587015:web:3f9dc2505965e66269b4a2"
};

if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
}

const BookingForm = () => {
  const {city,state,place} = useParams();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [confirmationResult, setConfirmationResult] = useState(null);
  //const [relatedOptions, setRelatedOptions] = useState([]);
  //const [nearestPlaces, setNearestPlaces] = useState([]);

  const handleSendOTP = async (e) => {
    e.preventDefault();
    try {
      const appVerifier = new firebase.auth.RecaptchaVerifier(
        "recaptcha-container"
      );
      const confirmation = await firebase
        .auth()
        .signInWithPhoneNumber(`+${phoneNumber}`, 
        appVerifier);
      setConfirmationResult(confirmation);
      toast.success("OTP sent successfully");
    } catch (error) {
      console.error("Error sending OTP:", error);
    }
  };

  const handleVerifyOTP = async (e) => {
    e.preventDefault();
    try {
      await confirmationResult.confirm(otp);
      toast.success("OTP Verified Successfully");
      setOtp("");
    } catch (error) {
      console.error("Error verifying OTP:", error);
      toast.error("Please Enter Correct OTP");
    }
  };

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    country: state,
    state:city,
    place:place,
    date: "",
    adults: "",
    childrens: "",
    duration: "",
    callbackTime: "",
    queries: "",
  });

  const minDate = new Date();
  minDate.setDate(minDate.getDate() + 30);

  const handleChange = (e) => {
    const { name, value } = e.target;
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const {
        name,
        email,
        country,
        state,
        place,
        date,
        adults,
        childrens,
        duration,
        callbackTime,
        queries,
      } = formData;
      const db = firebase.firestore();
      db.collection("formData").add({
        name: name,
        email: email,
        country: country,
        state:state,
        place:place,
        date: date,
        adults: adults,
        childrens: childrens,
        duration: duration,
        callbackTime: callbackTime,
        queries: queries,
      });
      const response = await fetch("https://formbackend.netlify.app/submit", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name,
          email,
          country,
          state,
          place,
          date,
          adults,
          childrens,
          duration,
          callbackTime,
          queries,
          phoneNumber,
        }),
      });
      const data = await response.json();
      console.log(data.message);
      toast.success("Booking Successfull");
      setFormData({
        name: "",
        email: "",
        country: "",
        state:'',
        place:'',
        date: "",
        adults: "",
        childrens: "",
        duration: "",
        callbackTime: "",
        queries: "",
      });
      setPhoneNumber("");
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const handleGoBack = () => {
    window.history.back();
  };

  return (
    <div className="app">
      <Toaster toastOptions={{ duration: 5000 }} />
      <div className="container-fluid app">
        <div
          onClick={handleGoBack}
          style={{ position: "absolute", top: "10px", right: "10px" }}
        >
          <FiX size={40} />
        </div>
        <div className="book-img">
          <img src="/home/pug.png" alt="" className="pug-mark" />
        </div>
        <h2 className="text-center input-text">Book Your Tour</h2>
        <p className="text-center input-text mb-4">
          Game drives/Safari-zones subject to availability
          <br /> <span>Recommend to plan a travel post 30 days</span>
        </p>
        <form onSubmit={handleSubmit}>
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-4 col-lg-4 col-xl-4">
                <div className="mb-3">
                  <input
                    type="text"
                    className="input-text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    placeholder="Name*"
                    required
                  />
                </div>
                <div className="mb-3">
                  <input
                    type="email"
                    className="input-text"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder="Email*"
                    required
                  />
                </div>
                <div className="mb-3">
                  <input
                    type="text"
                    className="input-text"
                    name="country"
                    value={formData.country}
                    onChange={handleChange}
                    placeholder="Country*"
                    required
                  />
                </div>
                <div className="mb-3">
                  <input
                    type="text"
                    className="input-text"
                    name="state"
                    value={formData.state}
                    onChange={handleChange}
                    placeholder="State*"
                    required
                  />
                </div>
                <div className="mb-3">
                  <input
                    type="text"
                    className="input-text"
                    name="place"
                    value={formData.place}
                    onChange={handleChange}
                    placeholder="Place*"
                    required
                  />
                </div>
              </div>
              <div className="col-12 col-md-4 col-lg-4 col-xl-4">
              <div className="mb-3">
                  <input
                    type="number"
                    className="input-text"
                    name="adults"
                    value={formData.adults}
                    onChange={handleChange}
                    placeholder="Number of adults*"
                    required
                  />
                </div>
                <div className="mb-3">
                  <input
                    type="number"
                    className="input-text"
                    name="childrens"
                    value={formData.childrens}
                    onChange={handleChange}
                    placeholder="Number of children (below 9years)*"
                    required
                  />
                </div>
                <div className="mb-3">
                  <input
                    type="text"
                    className="input-text"
                    name="duration"
                    value={formData.duration}
                    onChange={handleChange}
                    placeholder="Duration of stay/No.of nights*"
                    required
                  />
                </div>
                <div className="input-text mb-3">
                  <input
                    type="date"
                    name="date"
                    id="date"
                    value={formData.date}
                    min={minDate.toISOString().split("T")[0]}
                    onChange={handleChange}
                    placeholder="select Date*"
                    required
                  />
                </div>

                <div className="mb-3">
                  <input
                    type="text"
                    className="input-text"
                    name="callbackTime"
                    value={formData.callbackTime}
                    onChange={handleChange}
                    placeholder="Flexible time to reach out to you*"
                    required
                  />
                </div>
            
              </div>
              <div className="col-12 col-md-4 col-lg-4 col-xl-4">
              <div className="mb-3">
                  <textarea
                    className="input-text"
                    name="queries"
                    value={formData.queries}
                    onChange={handleChange}
                    placeholder="Write a Query.."
                    rows={2}
                  />
                </div>
                <div className="input-text mb-2">
                  <PhoneInput
                    defaultCountry="IN"
                    value={phoneNumber}
                    placeholder="Enter Contact Number*"
                    onChange={(value) => setPhoneNumber(value)}
                    required
                  />
                </div>
                <div className="mb-2">
                  <button
                    type="button"
                    onClick={handleSendOTP}
                    className="btn btn-primary input-text mb-3"
                  >
                    Send OTP
                  </button>
                </div>
                <div className="mb-2">
                  <div id="recaptcha-container"></div>
                </div>

                <div className="mb-2 d-flex flex-column justify-content-center">
                  <OtpInput
                    value={otp}
                    onChange={setOtp}
                    OTPLength={6}
                    otpType="number"
                    disabled={false}
                    required
                  ></OtpInput>
                  <button
                    type="button"
                    onClick={handleVerifyOTP}
                    className="btn btn-primary input-text mt-3"
                  >
                    Verify OTP
                  </button>
                </div>
              </div>
            </div>
            <div className="">
              <button type="submit" className="btn btn-primary input-text">
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default BookingForm;
