import React, { useState, useEffect } from 'react';
import './index.css';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

const Cancellations = () => {
    const [cancellations, setCancellations] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredCancellations, setFilteredCancellations] = useState([]);

    useEffect(() => {
        const fetchCancellations = async () => {
            const cancellationsSnapshot = await firebase.firestore().collection('cancellations').get();
            const cancellationsData = cancellationsSnapshot.docs.map(doc => doc.data());

            cancellationsData.forEach(cancellation => {
                cancellation.cancelledAt = cancellation.cancelledAt.toDate();
            });

            cancellationsData.sort((a, b) => b.cancelledAt - a.cancelledAt);

            setCancellations(cancellationsData);
            setFilteredCancellations(cancellationsData); 
        };

        fetchCancellations();
    }, []);

    useEffect(() => {
        const filtered = cancellations.filter(cancellation => 
            cancellation.bookingId.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredCancellations(filtered);
    }, [searchTerm, cancellations]);

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    return (
        <div className="cancellations-container">
            <div className="search-bar-container">
                <input 
                    type="text" 
                    placeholder="Search by Booking ID" 
                    value={searchTerm}
                    onChange={handleSearchChange}
                />
            </div>
            <div className="table-container">
                <table className="bookings-table">
                    <thead>
                        <tr>
                            <th>Booking ID</th>
                            <th>Cancelled At</th>
                            <th>Hotel Name</th>
                            <th>Customer Name</th>
                            <th>Category</th>
                            <th>State</th>
                            <th>Place</th>
                            <th>Check-in</th>
                            <th>Check-out</th>
                            <th>Number of Adults</th>
                            <th>Number of Kids</th>
                            <th>Number of Rooms</th>
                            <th>Room Type</th>
                            <th>Customer Contact</th>
                            <th>Customer Email</th>
                            <th>Hotel Email</th>
                            <th>Amount paid</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredCancellations.map((cancellation, index) => (
                            <tr key={index}>
                                <td>{cancellation.bookingId}</td>
                                <td>{cancellation.cancelledAt.toLocaleString()}</td>
                                <td>{cancellation.hotelId}</td>
                                <td>{cancellation.name}</td>
                                <td>{cancellation.category}</td>
                                <td>{cancellation.state}</td>
                                <td>{cancellation.place}</td>
                                <td>{cancellation.checkin}</td>
                                <td>{cancellation.checkout}</td>
                                <td>{cancellation.numAdults}</td>
                                <td>{cancellation.numKids}</td>
                                <td>{cancellation.numRooms}</td>
                                <td>{cancellation.roomType}</td>
                                <td>{cancellation.contact}</td>
                                <td>{cancellation.email}</td>
                                <td>{cancellation.hotelMail}</td>
                                <td>{cancellation.totalPrice}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default Cancellations;
