import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { IoMdHome } from "react-icons/io";
import { MdLocalAirport, MdDirectionsRailway } from "react-icons/md";
import { FaRegCalendarAlt } from "react-icons/fa";
import { GiDuration } from "react-icons/gi";
import 'react-vertical-timeline-component/style.min.css';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import './index.css';
import { HelmetProvider, Helmet } from 'react-helmet-async';

const firebaseConfig = {
    apiKey: "AIzaSyB4BPMHKw0OXM2PhQX9NcT25lDBWpRpIPQ",
    authDomain: "phone-auth-d0a8d.firebaseapp.com",
    projectId: "phone-auth-d0a8d",
    storageBucket: "phone-auth-d0a8d.appspot.com",
    messagingSenderId: "309252587015",
    appId: "1:309252587015:web:3f9dc2505965e66269b4a2"
};

if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
}

const PackageDetails = () => {
  const { state, place, city } = useParams();
  const formattedPlace = place.replace(/-/g, ' ');
  const formattedCity = city.replace(/-/g, ' ');
  const formattedState = state.replace(/-/g, ' ');
  const [packageData, setPackageData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchPackage = async () => {
      try {
        const db = firebase.firestore();
        const docRef = db
          .collection('Packages')
          .doc(formattedState)
          .collection(formattedState)
          .doc(formattedCity)
          .collection(formattedCity)
          .doc(formattedPlace);
          
        const doc = await docRef.get();
        if (doc.exists) {
          const data = doc.data();
          setPackageData(data);
        } else {
          console.warn("No document found for the given path.");
          setPackageData(null);
        }
      } catch (error) {
        console.error("Error fetching package data:", error);
        setPackageData(null);
      } finally {
        setIsLoading(false);
      }
    };

    fetchPackage();
  }, [formattedCity, formattedPlace, formattedState]);

  if (isLoading) {
    return(
      <div className='loading-container'>
      <p className='loading'></p>
      </div>
    )
  }

  return (
    <>
    <HelmetProvider>
    <Helmet>
        <title>{packageData.title}</title>
        <meta
          name="description"
          content={packageData.description}
        />
      </Helmet>
      <div className='banner-container'>
        {packageData.bannerImageUrl && (
          <img
            src={packageData.bannerImageUrl}
            alt={packageData.destinationName}
            className='nature-img'
          />
        )}
        <nav className="nature-nav">
          <div className='logo'>
            <Link to='/'><img src="/logo.png" alt="Logo" /></Link>
          </div>
          <Link to='/packages'>
            <div className='mb-2'>
              <button className='icon-button'>
                <IoMdHome size={15} />
              </button>
            </div>
          </Link>
        </nav>
        <div>
          <h1 className='banner-text'>{packageData.destinationName}</h1>
        </div>
      </div>
      <div className='container font'>
        <h3 className='mt-3 text-center' style={{color: 'orange'}}>{packageData.destinationHead}</h3>
        <p className='mt-3'>{packageData.aboutDestination}</p>
        <h3 className='mt-3 mb-5' style={{color: 'orange'}}><b>Price : {packageData.cost}</b></h3>
        <h5><strong><FaRegCalendarAlt /> Best Time to Travel: </strong> {packageData.bestTimeToTravel}</h5>
        <h5><strong><MdLocalAirport /> Nearest Airport: </strong>{packageData.nearestAirport}</h5>
        <h5><strong><MdDirectionsRailway /> Nearest Railway Station: </strong>{packageData.nearestRailwayStation}</h5>
        <h5><strong><GiDuration /> Duration: </strong>{packageData.duration}</h5>

        <h1 className='mt-4 text-center' style={{color: 'orange'}}>Short Itinerary</h1>
        <VerticalTimeline>
          {packageData.shortItinerary && packageData.shortItinerary.map((item, index) => (
            <VerticalTimelineElement
              key={index}
              className="vertical-timeline-element--work"
              contentStyle={{ background: 'rgb(134, 134, 21)', color: '#fff' }}
              contentArrowStyle={{ borderRight: '7px solid  #8B4513' }}
              iconStyle={{ background: '#8B4513', color: '#fff' }}
            >
              <h6 className="vertical-timeline-element-subtitle">{`Day ${index + 1}`}</h6>
              <p>{item}</p>
            </VerticalTimelineElement>
          ))}
        </VerticalTimeline>
        <div className='container'>
          <div className='row'>
        <div className='col-12 col-md-6 col-lg-6 col-xl-6'>
        <h3 className='mt-3' style={{color: 'green'}}>Inclusions</h3>
        <ul>
          {packageData.inclusions && packageData.inclusions.map((item, index) => <li key={index}>{item}</li>)}
        </ul>
        </div>
        <div className='col-12 col-md-6 col-lg-6 col-xl-6'>
        <h3 className='mt-3' style={{color: 'red'}}>Exclusions</h3>
        <ul>
          {packageData.exclusions && packageData.exclusions.map((item, index) => <li key={index}>{item}</li>)}
        </ul>
        </div>
        </div>
        </div>
        <div className='container cp'>
          <div className='row'>
        <div className='col-12 col-md-6 col-lg-6 col-xl-6'>
        <h3 className='mt-3' style={{color: 'orange'}}>Payment Terms</h3>
        <ul>
          {packageData.paymentTerms && packageData.paymentTerms.map((item, index) => <li key={index}>{item}</li>)}
        </ul>
        </div>
        <div className='col-12 col-md-6 col-lg-6 col-xl-6'>
          <h3 className='mt-3' style={{color: 'orange'}}>Cancellation Terms</h3>
          <ul className='mb-5'>
            {packageData.cancellationTerms && packageData.cancellationTerms.map((item, index) => <li key={index}>{item}</li>)}
          </ul>
      </div>
      </div>
        </div>
        </div>
      <footer>
        <Link to={`/packages/${state}/${city}/${place}/enquiry-form`}>
          <button className='package-book-button mt-5'>Enquire Now</button>
        </Link>
      </footer>
      </HelmetProvider>
    </>
  );
};

export default PackageDetails;
