import React from 'react';
import {Link} from 'react-router-dom';
import {IoMdHome} from 'react-icons/io';
import './index.css';

const MarketPlace = () => {
    return (
        <div>
        <nav className="nature-nav mb-5">
        <div className='logo'>
        <Link to='/'><img src="/logo.png" alt="Logo" style={{filter:'brightness(100%)'}} /></Link>
        </div>
        <Link to='/now'><button className='icon-button'><IoMdHome size={15}/></button></Link> 
    </nav>
        <h1 className='footer-text bold text-cemter mt-5 mb-4' style={{textAlign:'center', fontSize:'32px'}}>NOW - Market Place</h1>
        <div className='market-img-container-start'>
          <img src='/home/shirt.jpeg' alt='' className='market-img'/>
        </div>
        <div className='market-img-container-end'>
          <img src='/home/cap.jpeg' alt='' className='market-img'/>
        </div>
        <div className='market-img-container-start'>
          <img src='/home/cam.jpeg' alt='' className='market-img'/>
        </div>
        <div className='market-img-container-end'>
          <img src='/home/diamond.jpeg' alt='' className='market-img'/>
        </div>
        <div className='market-img-container-start'>
          <img src='/home/bracelet.jpeg' alt='' className='market-img'/>
        </div>
        <h3 className='footer-text bold text-cemter mt-3' style={{textAlign:'center', fontSize:'26px'}}>Launching Soon....</h3>
    </div>
    );
}

export default MarketPlace;
