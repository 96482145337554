import React, { useState } from 'react';
import './index.css';
import { FaBars, FaTimes } from 'react-icons/fa';
import Home from '../DashboardHome';
import Bookings from '../AllBookings';
import Users from '../Users';
import Vendors from '../Vendors';
import AddPackageForm from '../AddPackages';
import Cancellations from '../Cancellations';
import AllHotels from '../AllHotelsDashboard';
import {Link} from 'react-router-dom';
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { toast, Toaster } from "react-hot-toast";

const Dashboard = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState('Home');

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const handleSidebarItemClick = (item) => {
    setSelected(item);
    setIsOpen(false); 
  };

  const renderContent = () => {
    switch (selected) {
      case 'Home':
        return <Home />;
      case 'All Bookings':
        return <Bookings />;
      case 'Cancellations':
        return <Cancellations />;
      case 'Login Users':
        return <Users />;
      case 'Vendors':
        return <Vendors />;
      case 'Add Packages':
        return <AddPackageForm />;
      case 'All Hotels':
        return <AllHotels/>
      default:
        return <Home />;
    }
  };

  const handleConfirmLogout = () => {
    firebase.auth().signOut()
      .then(() => {
        console.log('Logged Out Successfully')
        toast.success("Logged out successfully")
      })
      .catch(error => {
        console.error('Error logging out:', error);
        toast.error("You are not loggedin")
      });
  };

  return (
    <>
    <Toaster toastOptions={{ duration: 5000 }} style={{ fontStyle: 'Canva Sans', color: 'black' }} />
    <div className="app-dashboard">
      <div className={`sidebar-dashboard ${isOpen ? 'open' : ''}`}>
        <div className="sidebar-items-dashboard">
          <div className='logo text-center' style={{ background:'#ccc'}}>
            <img src='/logo.png' alt='Logo' />
          </div>
          {['Home', 'All Bookings', 'Cancellations', 'Login Users', 'Add Packages', 'Vendors', 'All Hotels'].map(item => (
            <div
              key={item}
              className={`sidebar-item-dashboard ${selected === item ? 'selected' : ''}`}
              onClick={() => handleSidebarItemClick(item)}
            >
              {item}
            </div>
          ))}
           <div style={{listStyle:'none',padding:'20px',textAlign:'center'}}>
            <Link to='/admin-dashboard'><li><button className='login-button' onClick={handleConfirmLogout}>Logout</button></li></Link>
          </div>
        </div>
      </div>
      <div className="main-dashboard">
        <div className="navbar-dashboard">
          <div className='logo text-center'>
            <img src='/logo.png' alt='Logo' />
          </div>
          <div className="hamburger-dashboard" onClick={toggleSidebar}>
            {isOpen ? <FaTimes size={20}/> : <FaBars size={20}/>} 
          </div>
        </div>
        <div className="content-dashboard">
          {renderContent()}
        </div>
      </div>
    </div>
    </>
  );
};

export default Dashboard;
