import React, { useState, useEffect } from 'react';
import './index.css';
import { Link, useParams } from 'react-router-dom';
import { IoMdHome } from "react-icons/io";
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyB4BPMHKw0OXM2PhQX9NcT25lDBWpRpIPQ",
    authDomain: "phone-auth-d0a8d.firebaseapp.com",
    projectId: "phone-auth-d0a8d",
    storageBucket: "phone-auth-d0a8d.appspot.com",
    messagingSenderId: "309252587015",
    appId: "1:309252587015:web:3f9dc2505965e66269b4a2"
};

if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
}

const OnenessPlace = () => {
    const { state, place } = useParams(); 
    const formattedPlace = place.replace(/_/g, " ").replace(/-/g, ":");
    const formattedState = state.replace(/-/g, ' ')
    const [placeFields, setPlaceFields] = useState({});
    const [hotels, setHotels] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchPlaceFields = async () => {
            try {
                const placeFieldsSnapshot = await firebase.firestore().collection('OnenessDestinations').doc(formattedState).collection(formattedState).doc(formattedPlace).get();
                
                if (placeFieldsSnapshot.exists) {
                    const data = placeFieldsSnapshot.data();
                    setPlaceFields(data);
                } else {
                    console.log("Document does not exist");
                }
            } catch (error) {
                console.error('Error fetching place fields: ', error);
            }
        };
    
        fetchPlaceFields();
    }, [formattedState, formattedPlace]);

    useEffect(() => {
        const fetchHotels = async () => {
            try {
                const onenessHotelsSnapshot = await firebase.firestore().collection('OnenessDestinations').doc(formattedState).collection(formattedState).doc(formattedPlace).collection('hotels').get();
                const natureHotelsSnapshot = await firebase.firestore().collection('NatureDestinations').doc(formattedState).collection(formattedState).doc(formattedPlace).collection('hotels').get();
                
                const onenessHotels = onenessHotelsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                const natureHotels = natureHotelsSnapshot.docs
                    .map(doc => ({ id: doc.id, ...doc.data() }))
                    .filter(hotel => hotel.categories && hotel.categories.includes("OnenessDestinations"));
                
                const allHotels = [...onenessHotels, ...natureHotels];

                setHotels(allHotels);
                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching hotels: ', error);
            }
        };

        fetchHotels();
    }, [formattedState, formattedPlace]);

    if (isLoading) {
        return (
            <div className='loading-container'>
                <p className='loading'></p>
            </div>
        );
    }

    return (
        <>
            <div className='banner-container'>
                {placeFields.imageUrl && (
                    <img src={placeFields.imageUrl} alt="Oneness Destination" className='nature-img'/>
                )}
                <nav className="nature-nav">
                    <div className='logo'>
                        <Link to='/'><img src="/logo.png" alt="Logo" /></Link>
                    </div>
                    <Link to='/now'><button className='icon-button'><IoMdHome size={15} /></button></Link> 
                </nav>
                <div>
                    <h1 className='banner-text-place'>{place.replace(/_/g, " ")}</h1>
                </div>
            </div>
            <div className='container'>
                <div className='row scrollable-container'>
                    {hotels.map((hotel, index) => (
                        <div key={index} className='col-4 col-md-3 col-lg-3 col-xl-3 img-container'>
                            <Link to={`/oneness/${state}/${place}/${hotel.id}`}>
                                <img src={hotel.img1} alt='' className='package-img-hotel'/>
                                <div className="hotel-info">
                                    <p className='footer-text' style={{padding:'5px'}}>{hotel.id.toUpperCase()}</p>
                                    <p className='footer-text bold'>₹{hotel.price}/n</p>
                                </div>
                            </Link>
                        </div>
                    ))}
                </div>
            </div>
            <div>
                <footer className='footer'>
                    <p className='font'><span className='bold' style={{fontSize:'18px'}}>{formattedPlace}</span>: <span className='font'>{placeFields.about}</span></p>
                </footer>
            </div>
        </>
    );
}

export default OnenessPlace;
