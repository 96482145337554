import React, { useState, useEffect} from 'react';
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { FiX, FiEye, FiEyeOff } from "react-icons/fi";
import { toast, Toaster } from "react-hot-toast";
import './index.css';

const firebaseConfig = {
  apiKey: "AIzaSyB4BPMHKw0OXM2PhQX9NcT25lDBWpRpIPQ",
  authDomain: "phone-auth-d0a8d.firebaseapp.com",
  projectId: "phone-auth-d0a8d",
  storageBucket: "phone-auth-d0a8d.appspot.com",
  messagingSenderId: "309252587015",
  appId: "1:309252587015:web:3f9dc2505965e66269b4a2"
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}


const db = firebase.firestore();

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [creatingAccount, setCreatingAccount] = useState(false);
    const [name, setName] = useState('');
    const [loggedInUser, setLoggedInUser] = useState(null);
    //const [user, setUser] = useState(null);

    useEffect(() => {
        firebase.auth().onAuthStateChanged(user => {
            if (user) {
                setLoggedInUser(user);
            } else {
                setLoggedInUser(null);
            }
        });
    }, []);

    const handleEmailChange = (e) => setEmail(e.target.value);
    const handlePasswordChange = (e) => setPassword(e.target.value);
    const handleNameChange = (e) => setName(e.target.value);

    const togglePasswordVisibility = () => setShowPassword(!showPassword);

    const handleGoBack = () => setCreatingAccount(false);

    const handleLogin = async () => {
        try {
            const userCredential = await firebase.auth().signInWithEmailAndPassword(email, password);
            const user = userCredential.user;
            const userDoc = await db.collection('users').doc(user.uid).get();
            const userData = userDoc.data();
    
            if (userData.role === 'user') {
                console.log('Logged in successfully!');
                setLoggedInUser(true);
                setEmail('');
                setPassword('');
                toast.success('Logged in successfully');
            } else {
                throw new Error('Unauthorized access');
            }
        } catch (error) {
            console.error('Error signing in:', error);
            toast.error('Please enter valid Email or Password');
        }
    };
    

    const handleCreateAccount = async () => {
      try {
          const userCredential = await firebase.auth().createUserWithEmailAndPassword(email, password);
          const user = userCredential.user;
  
          await db.collection('users').doc(user.uid).set({
              name,
              email,
              role:'user'
          });
  
          console.log('Account created successfully!');
          setCreatingAccount(false);
          setEmail('');
          setPassword('');
          setName('');
          toast.success('Account created successfully!')
      } catch (error) {
          console.error('Error creating account:', error.message); 
          toast.error('Error creating account. Please try again.');
      }
  };
  
    const handleGoogleLogin = async () => {
        const provider = new firebase.auth.GoogleAuthProvider();
        try {
            await firebase.auth().signInWithPopup(provider);
            console.log('Logged in with Google successfully!');
            setLoggedInUser(true);
            toast.success('Logged in with Google Successfully!')
        } catch (error) {
            console.error('Error signing in with Google:', error);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
    }

    const handleGoback = () => {
        window.history.back();
      };

    const handleConfirmLogout = () => {
        firebase.auth().signOut()
            .then(() => {
                console.log('Logged Out Successfully');
                toast.success("Logged Out Successfully")
            })
            .catch(error => {
                console.error('Error logging out:', error);
                toast.error("Logout Failed")
            });
      };

    return (
        <>
        <div className='parent-container'>
                <Toaster toastOptions={{ duration: 5000 }} style={{ fontStyle: 'Canva Sans', color: 'black' }} />
                {loggedInUser ? (
                    <div className='welcome-container'>
                        <p className='welcome-text text-center'>Welcome {loggedInUser.displayName || loggedInUser.email}!</p>
                        <p className='welcome-text text-center' style={{color:'green'}}>You are already logged in with <br/> Travel Unbounded</p>
                        <div className='welcome-btn-container'>
                        <button type='submit' className='btn btn-primary' style={{font:'Canva Sans', color:'white',marginRight:'10px',width:'100px'}} onClick={handleGoback}>Go Back</button>
                        <button type='submit' className='btn btn-success' style={{font:'Canva Sans', color:'white',width:'100px'}} onClick={handleConfirmLogout}>Logout</button>
                    </div>
                    </div>
                ) : (
                    <form onSubmit={handleSubmit}>
                        <div className='signin-container'>
                            <div className='element-container'>
                                <div
                                    onClick={creatingAccount ? handleGoBack : handleGoback}
                                    style={{ position: "absolute", top: "10px", right: "10px", cursor: 'pointer' }}
                                >
                                    <FiX size={30} />
                                </div>
                                <h3 className='login-head mb-3 text-center'>{creatingAccount ? 'Create Account' : 'Login'}</h3>
                                {creatingAccount && (
                                    <>
                                        <label>Name</label>
                                        <input type="text" className='mb-3' placeholder="Enter your name" value={name} onChange={handleNameChange} />
                                    </>
                                )}
                                <label>Email</label>
                                <input type="email" className='mb-3' placeholder="Enter email" value={email} onChange={handleEmailChange} autoComplete='username' required />
                                <label>Password</label>
                                <div className="password-input-container">
                                    <input
                                        type={showPassword ? "text" : "password"}
                                        className='mb-3 password-input'
                                        placeholder="Enter password"
                                        value={password}
                                        onChange={handlePasswordChange}
                                        autoComplete="current-password"
                                        required
                                    />
                                    <div className="eye-icon-container" onClick={togglePasswordVisibility}>
                                        {showPassword ? <FiEye size={20} /> : <FiEyeOff size={20} />}
                                    </div>
                                </div>
                                
                                {creatingAccount ? (
                                    <button onClick={handleCreateAccount} type='submit' className='btn-submit-mail'>Create Account</button>
                                ) : (
                                    <>
                                        <button onClick={handleLogin} type='submit' className='btn-submit-mail'>Login with Email</button>
                                        <p className='create-account-link' onClick={() => setCreatingAccount(!creatingAccount)}>{creatingAccount ? 'Already have an account? Login' : 'Create an account'}</p>
                                        <button className='btn-submit-google' onClick={handleGoogleLogin}><img src='/home/google.png' alt='' className='google' style={{background:'white', padding:'5px'}}/> Signup with Google</button>
                                    </>
                                )}
                            </div>
                        </div>
                    </form>
                )}
            </div>
        </>
    );
};

export default Login;
