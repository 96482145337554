import React, { useState,useEffect } from 'react';
import {Link} from 'react-router-dom';
import { GiHamburgerMenu } from "react-icons/gi";
import { MdOutlineCancel } from "react-icons/md";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { SiCoinmarketcap } from "react-icons/si";
import { MdOutlineContactSupport } from "react-icons/md";
import { LiaBusinessTimeSolid } from "react-icons/lia";
import { MdOutlineViewInAr } from "react-icons/md";
import { CiBoxList } from "react-icons/ci";
import './index.css';
import { toast, Toaster } from "react-hot-toast";
import { MdConnectWithoutContact } from "react-icons/md";
//import { MdOutlineTravelExplore } from "react-icons/md";

const firebaseConfig = {
  apiKey: "AIzaSyB4BPMHKw0OXM2PhQX9NcT25lDBWpRpIPQ",
  authDomain: "phone-auth-d0a8d.firebaseapp.com",
  projectId: "phone-auth-d0a8d",
  storageBucket: "phone-auth-d0a8d.appspot.com",
  messagingSenderId: "309252587015",
  appId: "1:309252587015:web:3f9dc2505965e66269b4a2"
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}


function Nav() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [loggedInUser, setLoggedInUser] = useState(null);

  useEffect(() => {
    firebase.auth().onAuthStateChanged(user => {
        if (user) {
            setLoggedInUser(user);
        } else {
            setLoggedInUser(null);
        }
    });
}, []);


const handleLogout = () => {
  if (loggedInUser) {
      setShowPopup(true);
  } else {
      toast.success('You are not logged in with Travel Unbounded!');
  }
};
  const handleConfirmLogout = () => {
    firebase.auth().signOut()
      .then(() => {
        setShowPopup(false);
        console.log('Logged Out Successfully')
        toast.success("Logged out successfully")
      })
      .catch(error => {
        console.error('Error logging out:', error);
        toast.error("You are not loggedin")
      });
  };

  const handleCancelLogout = () => {
    setShowPopup(false);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <>
      <Toaster toastOptions={{ duration: 5000 }} style={{ fontStyle: 'Canva Sans', color: 'black' }} />
      <nav className="navbar">
      <div className='container'>
        <div className="logo">
        <Link to='/'><img src="/logo.png" alt="Logo" style={{filter:'brightness(80%)'}} /></Link>
          </div>
        <div className="menu-icon" onClick={toggleMenu}>
          {isMenuOpen ? (
           <MdOutlineCancel size={30} style={{color:'black'}}/>
          ) : (
           <GiHamburgerMenu size={30} style={{color:'black'}}/>
          )}
        </div>
        </div>
      </nav>
      {isMenuOpen && (
        <div className="sidebar">
          <ul>
            <li style={{textAlign:'center'}}><span style={{color:'green'}}>N</span><span style={{color:'orange'}}>O</span><span style={{color:'brown'}}>W</span></li>
            <hr style={{width:'100%'}}/>
           <Link to="/market-place"> <li><SiCoinmarketcap size={20}/> Now - Market Place</li></Link>
            <hr style={{width:'100%'}}/>
            <Link to="/contact/leisure"> <li><MdOutlineContactSupport size={20}/> Contact - Liesure Tours</li></Link>
            <hr style={{width:'100%'}}/>
            <Link to="/contact/business"> <li><LiaBusinessTimeSolid size={20}/> Contact - Business Events</li></Link>
            <hr style={{width:'100%'}}/>
            <Link to='/your-bookings'><li><MdOutlineViewInAr size={20}/> View Your Bookings</li></Link>
            <hr style={{width:'100%'}}/>
            <Link to='/vendor-login'><li><CiBoxList size={20}/> List your property</li></Link>
            <hr style={{width:'100%'}}/>
            <Link to='/contact'><li><MdConnectWithoutContact size={20}/> Contact us</li></Link>
            <hr style={{width:'100%'}}/>
            <div className='login-container'>
            <Link to='/login'><li><button className='login-button'>Login</button></li></Link>
            <li><button onClick={handleLogout} className='login-button' style={{background:'brown', color:'white'}}>Logout</button></li>
            {showPopup && (
              <div className="popup text-center">
                <p style={{fontSize:'16px', fontWeight:'bold'}} className='mt-3'>Are you sure you want to logout?</p>
                <div className='mt-3'>
                  <button onClick={handleConfirmLogout} className='confirm-btn' style={{background:'green', color:'white'}}>Yes</button>
                  <button onClick={handleCancelLogout} className='confirm-btn' style={{background:'red', color:'white'}}>No</button>
                </div>
              </div>
            )}
            </div>
          </ul>
        </div>
      )}
    </>
  );
}

export default Nav;
