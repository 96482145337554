import React from 'react';
import {Link} from 'react-router-dom';
import './index.css';

const RegisterPage = () => {
  return (
    <div className="register-container">
      <div className="image-section">
        <img src="/tu-5.avif" alt="Ocean view" />
      </div>
      <div className="content-section">
        <p className="interested-text">Interested?</p>
        <h1 className="title">Register</h1>
        <div className='row-flex'>
        <div className='club5-vertical-line'></div>
        <div className='column-flex'>
        <p className="description">
          If the idea of holiday home ownership appeals then don’t tie up significant capital in one property whilst limiting yourself to one location and taking on maintenance hassles from afar. Do it the smart way and co-own a portfolio of beautifully designed holiday homes in some of the most desired regions in the world. The Hideaways Club delivers lifetime memories time after time, all for far less than the cost of acquiring one holiday home on your own.
          <br/><br/>
          Register to receive membership plans and to discuss the best option for your travels.
        </p>
        </div>
        </div>
        <Link to='/club-register'><button className="brochure-button">Receive a brochure →</button></Link>
      </div>
    </div>
  );
}

export default RegisterPage;
