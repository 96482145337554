import React, { useState, useEffect, useRef } from 'react';
import ReactSlider from 'react-slider';
import './index.css';
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";

const ImageSlider = ({ images, showOverlayText }) => {
  const [index, setIndex] = useState(0);
  //const [isHovered, setIsHovered] = useState(false);
  const [loadedImages, setLoadedImages] = useState([]);
  const imageRefs = useRef([]);

  useEffect(() => {
    images.forEach((image) => {
      const img = new Image();
      img.src = image;
      img.onload = () => setLoadedImages((prev) => [...prev, image]);
    });
  }, [images]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const img = new Image();
          img.src = entry.target.dataset.src;
          img.onload = () => {
            setLoadedImages((prev) => [...prev, entry.target.dataset.src]);
            observer.unobserve(entry.target);
          };
        }
      });
    }, {
      root: document.querySelector('.slider-container'),
      threshold: 0.1
    });

    imageRefs.current.forEach((img) => observer.observe(img));

    return () => observer.disconnect();
  }, [images]);


  const handleSliderChange = (value) => {
    setIndex(value);
  };

  return (
    <div className="slider-container">
      <ReactSlider
        value={index}
        max={images.length - 1}
        onChange={handleSliderChange}
        renderThumb={(props, state) => <div {...props} className="thumb" aria-label={`Slide ${state.value + 1}`}/>}
        className="slider"
      />
      <div className="slides">
        {images.map((image, idx) => (
          <div
            key={idx}
            className={`slide ${idx === index ? 'active' : ''}`}
            style={{ backgroundImage: `url(${loadedImages.includes(image) ? image : ''})` }}
            data-src={image}
            ref={(el) => (imageRefs.current[idx] = el)}
          >
            {showOverlayText && idx === index && (
              <div className="overlay-text-home">
                <p>One Life, Journeys Matter</p>
              </div>
            )}
          </div>
        ))}
      </div>
      <div className="arrow-home left-arrow-home" onClick={() => setIndex((index - 1 + images.length) % images.length)}>
        <BsArrowLeft size={20}/>
      </div>
      <div className="arrow-home right-arrow-home" onClick={() => setIndex((index + 1) % images.length)}>
        <BsArrowRight size={20}/>
      </div>
    </div>
  );
};

export default ImageSlider;
